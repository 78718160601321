import React, {Component} from "react";
import "./filter-btn-color-hack.css";
import styles from "./index.module.scss";
import {SIDEBAR_TOGGLE_TOPIC} from "../Sidebar";
import {LIGHTBOX_OPEN_EVENT_TOPIC, MAP_TYPE_CHANGE_TOPIC} from "../App/App";
import {APP_GLOBAL, DISPLAY_MODES} from "../App/DataManager";
import {
    BeyBroadcaster,
    BeyonityUiUtils,
    Button,
    Dropdown,
    Icon,
    RemovableButton,
    Slider
} from '@beyonityeu/beyonity-ui-buttons';


import {
    FILTER_BOX_TOGGLE_EVENT_TOPIC,
    FILTER_SETTINGS_CHANGE_EVENT_TOPIC,
    HIGHLIGHT_SHAPES_EVENT_TOPIC
} from "../FilterBox";
import * as PropTypes from "prop-types";
import ScrollHorizontal from "../ScrollHorizontal";




Icon.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.any
};

/**
 * This menu will e displayed in the top left if the player.
 * It holds all the function toggles such as map mode, fullscreen etc. as well as
 * the currently set filters.
 *
 * @author [Willi Boelke](willi-dev@outlook.com)
 */
class FunctionsMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mapType   : true,
            showShapes: false,
            filterSettings: {}
        }

        BeyBroadcaster.instance.subscribe(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, (topic, data) => {
            this.setState({filterSettings: BeyonityUiUtils.copyObject(data)})
        });
        BeyBroadcaster.instance.subscribe(HIGHLIGHT_SHAPES_EVENT_TOPIC, (topic, data) => {
            this.setState({showShapes: data.show})
        });
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.mapType !== nextState.mapType ||
            this.state.showShapes !== nextState.showShapes ||
            this.state.filterSettings !== nextState.filterSettings;
    }


    render() {
        const {languageSwitchObject} = APP_GLOBAL.data;


        return (
            <div className={styles.functions}>
                <ScrollHorizontal className={styles.horizontalScroll}>
                    <Button size={"s"} className={styles.function} iconLeft={"fullscreen"} onClick={
                        () => {
                            BeyBroadcaster.instance.publish(SIDEBAR_TOGGLE_TOPIC, {});
                        }
                    }/>
                    {!APP_GLOBAL.data.settings.hasOnlyPois &&
                        <Button size={"s"}
                                className={styles.function}
                                iconLeft={"filter_list"}
                                label={APP_GLOBAL.language.filterSort.title}
                                onClick={() => {
                                    BeyBroadcaster.instance.publish(FILTER_BOX_TOGGLE_EVENT_TOPIC, {});
                                }
                                }/>
                    }
                    {this._renderPoiToggle()}
                    {APP_GLOBAL.displayMode === DISPLAY_MODES.canvas && this._renderShapeSwitch()}
                    {APP_GLOBAL.displayMode === DISPLAY_MODES.map && this._renderMapTypeSwitch()}
                    {this._renderFilterSettings(styles)}
                </ScrollHorizontal>
            </div>
        );
    }


    _renderMapTypeSwitch() {
        const {mapType} = this.state;
        const {map} = APP_GLOBAL.language;
        return (
            <Button size={"s"} className={styles.function} iconLeft={"map"}
                    label={!mapType ? map.controls.map : map.controls.satellite} onClick={() => {
                this.setState({mapType: !mapType}, () => {
                    BeyBroadcaster.instance.publish(MAP_TYPE_CHANGE_TOPIC, {mapType: mapType ? "roadmap" : "hybrid"});
                });
            }
            }/>

        )
    }


    _renderShapeSwitch() {
        if (APP_GLOBAL.data.settings.hasOnlyPois) return null;
        const {showShapes} = this.state;
        return (
            <Button size={"s"}
                    className={`${styles.function} ${showShapes ? styles.active : ""}`}
                    iconLeft={showShapes ? "visibility_off" : "visibility"}
                    label={APP_GLOBAL.language.canvas.highlightAll}
                    onClick={() => {
                        BeyBroadcaster.instance.publish(HIGHLIGHT_SHAPES_EVENT_TOPIC, {show: !this.state.showShapes});
                    }
                    }/>
        )
    }


    _renderPoiToggle() {
        let poiLink = APP_GLOBAL.data.poi;
        if (!poiLink) {
            return null;
        }
        const {poi} = APP_GLOBAL.language;
        return (
            <Button size={"s"}
                    className={styles.function}
                    iconLeft={"explore"}
                    label={poi.button}
                    onClick={() => {
                        BeyBroadcaster.instance.publish(LIGHTBOX_OPEN_EVENT_TOPIC, {
                            title: poi.title,
                            src           : [poiLink],
                            headerElements: []
                        })
                    }}/>
        )
    }


    _renderFilterSettings(styles) {
        const {filterSettings} = this.state;
        return (
            <>
                {( filterSettings.location && filterSettings.location.lat !== false ) &&
                    this._renderDistanceFilters()
                }
                {( filterSettings.infoFlagCategory !== ["all"] ) &&
                    this._renderInfoFlagCategoryFilter()
                }
            </>
        );
    }


    _renderInfoFlagCategoryFilter = () => {
        const {filterSettings} = this.state;
        if (!APP_GLOBAL.data.settings.hasOnlyPois) return null;
        if (!filterSettings?.infoFlagCategory) return null;

        return ( APP_GLOBAL.data.availableInfoFlagCategories.map((infoFlagCategory) => {
            let enabled = filterSettings.infoFlagCategory.includes(infoFlagCategory) || filterSettings.infoFlagCategory.includes("all");
            return <Button label={APP_GLOBAL.data.infoFlagCategories[infoFlagCategory].name}
                           className={enabled && "active-filter-btn-text"}
                           iconLeft={APP_GLOBAL.data.infoFlagCategories[infoFlagCategory].icon}
                           buttonVariant={enabled ? "custom" : "main"}
                           key={infoFlagCategory}
                           size={"s"}
                           buttonType={enabled ? "primary" : ""}
                           customVariantColors={{
                               primary  : enabled ? "255, 255 ,255" : APP_GLOBAL.data.infoFlagCategories[infoFlagCategory].colors,
                               secondary: APP_GLOBAL.data.infoFlagCategories[infoFlagCategory].color
                           }
                           }
                           onClick={() => {
                               let newFilterSettings = BeyonityUiUtils.copyObject(filterSettings);
                               let enabled = newFilterSettings.infoFlagCategory.includes(infoFlagCategory);
                               if (!enabled) {
                                   newFilterSettings.infoFlagCategory.push(infoFlagCategory);
                               } else {
                                   newFilterSettings.infoFlagCategory = newFilterSettings.infoFlagCategory.filter((t) => t !== infoFlagCategory);
                                   newFilterSettings.infoFlagCategory = newFilterSettings.infoFlagCategory.filter((t) => t !== `all`);
                               }
                               this.setState({filterSettings: newFilterSettings}, () => {
                                   BeyBroadcaster.instance.publish(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, BeyonityUiUtils.copyObject(newFilterSettings));
                               });
                           }}/>
        }) );
    }
    _renderDistanceFilters = () => {
        const {filterSort} = APP_GLOBAL.language;
        const {filterSettings} = this.state;
        return (
            <Dropdown size={"s"}
                      icon={"location_on"}
                      className={styles.function}
                      renderToggle={(props) => (
                          <RemovableButton
                              onClick={props.onClick}
                              onCloseClick={() => {
                                  let newFilterSettings = BeyonityUiUtils.copyObject(filterSettings);
                                  newFilterSettings.location = {
                                      name  : false,
                                      lat   : false,
                                      lng   : false,
                                      radius: 50
                                  };
                                  BeyBroadcaster.instance.publish(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, newFilterSettings);
                              }}
                              size={"s"}
                              buttonVariant={"main"}
                              buttonType={"secondary"}
                              iconLeft={"location_on"}
                              label={filterSettings.location.name + " - " +
                                  filterSettings.location.radius + " " +
                                  filterSort.filter.map.circumcircleUnit}
                          />
                      )}
                      label={filterSettings.location.name + " - " + filterSettings.location.radius + " " + +" " + filterSort.filter.map.circumcircleUnit}
            >
                <div className={styles.filterDropdowns}>
                    <div className={styles.filterInfo}>
                        <div className={styles.left}>
                            <Icon size={"s"}
                                  color={`var(--color-text--gray-600)`}
                                  name={"radar"}/>
                            <span>{filterSort.filter.map.circumcircle}</span>
                        </div>
                        <div className={styles.right}>
                            {filterSettings.location.radius + filterSort.filter.map.circumcircleUnit}
                        </div>
                    </div>
                    <Slider minValue={5}
                            maxValue={502}
                            value={filterSettings.location.radius}
                            steps={5}
                            onChange={(value) => {
                                let newFilterSettings = filterSettings;
                                newFilterSettings.location.radius = value;
                                this.setState({filterSettings: newFilterSettings});
                                BeyBroadcaster.instance.publish(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, BeyonityUiUtils.copyObject(this.state.filterSettings));
                                sessionStorage.setItem("filterSettings" + APP_GLOBAL.data.dsc, JSON.stringify(this.state.filterSettings));
                            }
                            }></Slider>
                </div>
            </Dropdown>
        )
    }
}

FunctionsMenu.defaultProps = {};

FunctionsMenu.propTypes = {};

export default FunctionsMenu;