import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Hammer from '../../../../libraries/hammer.js/hammer.min.js'
import './CanvasBase.css';
import {BeyonityUiUtils} from "@beyonityeu/beyonity-ui-buttons";




class CanvasBase extends Component {

    rto;

    /**
     * This will be set to true when the width of the image is smaller than the width of the canvas.
     * Then the background gradient will be calculated on every rotation.
     * By setting this we can prevent the background gradient from being calculated when its not necessary.
     * This will be often true on phones in portrait mode and thus boosts mobile performance.
     * @type {boolean}
     */
    renderBackground = false;

    /**
     * The currently rendered image
     * @type {null|HTMLImageElement}
     */
    img;
    /**
     *
     */
    canFactor;


    constructor(props) {
        super(props);
        this.img = null;
        this.params = {};
        this.canFactor = 1;
        this.offset = {x: 0, y: 0};
        this.maxOffset = 0;
        this.zoom = 0;

        this.eventManager = {};
        this.canvasRef = React.createRef();
        this.canvasWrapperRef = React.createRef();
        this.gradientRefLeftOne = React.createRef();
        this.gradientRefLeftTwo = React.createRef();
        this.gradientRefRightOne = React.createRef();
        this.gradientRefRightTwo = React.createRef();
        this.currentGradient = true;
        this.plugins = this.props.plugins;
        this.state = {
            active     : this.props.active,
            pluginState: {}
        }
    }


    componentDidMount() {
        const canvas = this.canvasRef.current;
        this.eventManager = new Hammer.Manager(canvas, {
            touchAction: 'pan-y',
            recognizers: [
                [Hammer.Tap],
                [Hammer.Pan, {direction: Hammer.DIRECTION_ALL}, [], ['tap']],
                [Hammer.Pinch, {enable: true}, [], ['tap', 'pan']],
                [Hammer.Rotate, {enable: true}, ['pinch'], ['tap', 'pan']]
            ]
        });

        this.context = canvas.getContext('2d');
        this.plugins && this.plugins.forEach(plugin => {
            plugin.init(this)
        });

        const {img, onLoad} = this.props;
        this.img = img;
        this.draw();

        onLoad();
        this.rto = {intv: 250};

        new ResizeObserver(this.resize.bind(this)).observe(this.canvasWrapperRef.current);
        window.addEventListener('scroll', e => e.preventDefault(), false);
        this.calculateBackgroundGradient();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const canvas = this.canvasRef.current;
        this.context = canvas.getContext('2d');
    }


    lastFrameTime = performance.now();
    frameDuration = 1000 / 24; // Duration of a frame in ms for 24 FPS

    redrawAnimationFrame = () => {
        const currentTime = performance.now();
        const timeSinceLastFrame = currentTime - this.lastFrameTime;

        if (timeSinceLastFrame > this.frameDuration) {
            this.redraw();
            this.lastFrameTime = currentTime;
        }

        this.raf = window.requestAnimationFrame(this.redrawAnimationFrame);
    }


    componentWillUnmount() {
        // init plugins
        this.plugins && this.plugins.forEach(plugin => plugin.destroy(this));
        window.clearTimeout(this.rto);
        window.removeEventListener('scroll', this.scroll, false);
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.state.active !== nextState.active) {
            this.plugins && this.plugins.forEach(plugin => {
                plugin.setActive(nextState.active)
            });
        }
        if (this.props.rotation !== nextProps.rotation && this['CanvasRotateRef'] !== undefined) {
            this.plugins && this.plugins.forEach(plugin => {
                plugin.setData(nextProps.data)
                plugin.onCanvasFullLoaded(this)
            });
            this.context = this.canvasRef.current.getContext('2d');
            return true;
        }
        if (!BeyonityUiUtils.compareObjects(this.state.pluginState !== nextState.pluginState)) {
            return true;
        }
        return false;
    }


    setActive = (active) => {
        this.setState({
            active: active
        });
        this.calculateBackgroundGradient();
    }


    setPluginState(pluginId, state) {
        this.setState({
            pluginState: {
                ...this.state.pluginState,
                [pluginId]: {
                    ...this.state.pluginState[pluginId],
                    ...state,
                }
            }
        })
    }


    resize = () => {
        this.rto = window.setTimeout(() => {
            this.draw();
        }, this.rto.intv);
    }

    scroll = e => e.preventDefault();

    setSize = () => {
        const canvas = this.canvasRef.current,
            player = this.canvasWrapperRef.current;
        if (!player) return;
        canvas.width = player.clientWidth;
        canvas.height = player.clientHeight;
        if (this.img.naturalWidth <= player.clientWidth) {
            this.renderBackground = true;
            this.calculateBackgroundGradient()
        } else {
            this.renderBackground = true;
        }
    };

    setRatio = () => {
        const canvas = this.canvasRef.current;
        if (!canvas) return;
        this.canFactor = canvas.height / this.img.naturalHeight;
    };

    calculateMaxOffset = () => {
        const canvas = this.canvasRef.current;
        if (!this.img || !canvas) return;
        this.maxOffset = {
            x: Math.max(0, ( ( this.img.naturalWidth - ( canvas.width / this.canFactor / ( 1 + this.zoom ) ) ) / 2 ) * this.canFactor),
            y: ( ( this.img.naturalHeight - ( this.img.naturalHeight / ( 1 + this.zoom ) ) ) / 2 ) * this.canFactor
        };
    };

    checkOffset = () => {
        const axis = ['x', 'y'];
        for (const p of axis) {
            if (this.offset[p] > this.maxOffset[p]) {
                this.offset[p] = this.maxOffset[p];
            } else if (this.offset[p] < -this.maxOffset[p]) {
                this.offset[p] = -this.maxOffset[p];
            }
        }
    };

    draw = () => {
        this.setSize();
        this.setRatio();
        this.redraw();
        // this.redrawAnimationFrame();
    };

    redraw = () => {
        this.calculateMaxOffset();
        this.checkOffset();
        this.drawImage()
    };


    calculateBackgroundGradient = () => {
        if (!this.canvasWrapperRef.current) return;
        clearTimeout(this.bgTimeout);

        if (this.renderBackground) {
            this.bgTimeout = window.setTimeout(() => {
                if (this.currentGradient) {
                    // left
                    this.currentGradient = false;
                    this.gradientRefLeftOne.current.style.background =
                        this.buildLinearGradient(this.getBatchedDominant(this.canvasRef.current, 'left'));
                    this.gradientRefLeftOne.current.style.opacity = 1;
                    this.gradientRefLeftTwo.current.style.opacity = 0;
                    this.gradientRefLeftTwo.current.style.transitionDuration = "600ms";
                    // right
                    this.gradientRefRightOne.current.style.background =
                        this.buildLinearGradient(this.getBatchedDominant(this.canvasRef.current, 'right'));
                    this.gradientRefRightOne.current.style.opacity = 1;
                    this.gradientRefRightTwo.current.style.opacity = 0;
                    this.gradientRefRightTwo.current.style.transitionDuration = "600ms";
                } else {
                    this.currentGradient = true;
                    this.gradientRefLeftTwo.current.style.background =
                        this.buildLinearGradient(this.getBatchedDominant(this.canvasRef.current, 'left'));
                    this.gradientRefLeftTwo.current.style.opacity = 1;
                    this.gradientRefLeftOne.current.style.transitionDuration = "600ms";
                    this.gradientRefRightTwo.current.style.background =
                        this.buildLinearGradient(this.getBatchedDominant(this.canvasRef.current, 'right'));
                    this.gradientRefRightTwo.current.style.opacity = 1;
                    this.gradientRefRightOne.current.style.transitionDuration = "600ms";
                }
            }, 150);
        }
    };



    buildLinearGradient = (colors) => {
        let grad = "linear-gradient(";
        colors.forEach((color, index) => {
            grad += `rgb(${color}) ${index * 100 / ( colors.length - 1 )}% `;
            if (index < colors.length - 1) {
                grad += ", ";
            }
        });
        grad += ")";
        return grad;
    }

    /**
     * This function calculates the dominant color in a given canvas.
     * It does this by sampling the image data from the canvas, and counting
     * the occurrence of each color.
     * The color that occurs the most is considered the dominant color.
     * To improve performance, the function skips a certain number of pixels each iteration.
     * This reduces the number of iterations and thus improves performance.
     * The trade-off is that the result might be less accurate, but for most images,
     * especially large ones, the dominant color will still be relatively accurate.
     *
     * @param {Object} canvas - The canvas from which to get the dominant color.
     * @returns {string} The dominant color in rgb format.
     */
    getBatchedDominant = (canvas, side, percentage = 0.1) => {
        const offScreenCanvas = document.createElement('canvas');
        offScreenCanvas.width = this.img.width;
        offScreenCanvas.height = this.img.height;
        const ctx = offScreenCanvas.getContext("2d");
        ctx.drawImage(this.img, 0, 0, this.img.width, this.img.height);

        let startX = 0;
        if (side === 'right') {
            startX = this.img.width * ( 1 - percentage );
        }

        const imageData = ctx.getImageData(startX, 0, this.img.width * percentage, this.img.height);
        const data = imageData.data;
        const width = this.img.width * percentage;
        const dominantColors = [];

        let skipPixelColumns = 25; // Number of rows in each chunk
        let skipChunkColumns = 2; // Number of pixels to skip in each chunk
        let skipPixels = 15;

        for (let y = 0; y < this.img.height; y += skipPixelColumns) {
            const colorCount = {};

            for (let row = y; row < y + skipPixelColumns && row < this.img.height; row += skipChunkColumns) {
                for (let x = 0; x < width; x += skipPixels) {
                    const i = ( row * width + x ) * 4;

                    // if its black skip
                    if (data[i] === 0 && data[i + 1] === 0 && data[i + 2] === 0) {
                        continue;
                    }
                    // not white
                    if (data[i] === 255 && data[i + 1] === 255 && data[i + 2] === 255) {
                        continue;
                    }
                    // filter most grayish tones
                    if (Math.abs(data[i] - data[i + 1]) < 10 && Math.abs(data[i + 1] - data[i + 2]) < 10 && Math.abs(data[i] - data[i + 2]) < 10) {
                        continue;
                    }

                    const color = `${data[i]},${data[i + 1]},${data[i + 2]}`;
                    if (colorCount[color] === undefined) {
                        colorCount[color] = 0;
                    }
                    colorCount[color]++;
                }
            }

            const dominantColorInChunk = Object.keys(colorCount).sort((a, b) => {
                return colorCount[b] - colorCount[a];
            })[0];

            dominantColors.push(dominantColorInChunk);
        }

        return dominantColors;
    };

    clear = () => {
        const canvas = this.canvasRef.current;
        this.context = this.canvasRef.current.getContext('2d'); // todo i dont understand why we always lose this
        this.context.save();
        this.context.setTransform(1, 0, 0, 1, 0, 0);
        this.context.clearRect(0, 0, canvas.width, canvas.height);
        this.context.restore();
    };


    drawImage = () => {
        const canvas = this.canvasRef.current;
        if (!this.img || !canvas) return;

        this.params.dheight = canvas.height;
        this.params.dy = 0;
        this.params.dwidth = Math.round(Math.min(canvas.width, this.params.dheight * ( this.img.naturalWidth / this.img.naturalHeight ) * ( 1 + this.zoom )));
        this.params.dx = Math.round(( canvas.width - this.params.dwidth ) / 2);
        this.params.sheight = Math.round(this.img.naturalHeight / ( 1 + this.zoom ));
        this.params.sy = Math.round(( ( this.img.naturalHeight - this.params.sheight ) / 2 ) + this.offset.y / this.canFactor);
        this.params.swidth = Math.round(Math.min(canvas.width / this.canFactor / ( 1 + this.zoom ), this.params.sheight * ( this.img.naturalWidth / this.img.naturalHeight ) * ( 1 + this.zoom ))); // landscape vs portrait
        this.params.sx = Math.round(( ( this.img.naturalWidth - this.params.swidth ) / 2 ) + this.offset.x / this.canFactor);

        this.clear();

        this.context.drawImage(this.img,
            this.params.sx,
            this.params.sy,
            this.params.swidth,
            this.params.sheight,
            this.params.dx,
            this.params.dy, this.params.dwidth, this.params.dheight);

        this.plugins && this.plugins.forEach(plugin => plugin.onRedraw());

    };


    render() {
        return (
            <div className={"beyonity-ui--canvas"}
                 ref={this.canvasWrapperRef}>
                <div className={"beyonity-ui--canvas__gradient-wrapper left"}>
                    <div className={"beyonity-ui--canvas__gradient left beyonity-ui--canvas__gradient_one"}
                         ref={this.gradientRefLeftOne}/>
                    <div className={"beyonity-ui--canvas__gradient left beyonity-ui--canvas__gradient_two"}
                         ref={this.gradientRefLeftTwo}/>
                </div>
                <div className={"beyonity-ui--canvas__gradient-wrapper right"}>
                    <div className={"beyonity-ui--canvas__gradient right beyonity-ui--canvas__gradient_one"}
                         ref={this.gradientRefRightOne}/>
                    <div className={"beyonity-ui--canvas__gradient right beyonity-ui--canvas__gradient_two"}
                         ref={this.gradientRefRightTwo}/>
                </div>
                <canvas
                    className={"beyonity-ui--canvas__canvas"}
                    ref={this.canvasRef}>
                </canvas>
                <div className={"beyonity-ui--canvas__plugin-ui"}>
                    <div className={"beyonity-ui--canvas__plugin-wrapper"}>
                        {this.plugins && this.plugins.map(plugin => {
                            let pluginUi = plugin.renderPlugin();
                            if (!pluginUi) return null;
                            return plugin.renderPlugin(this.state.pluginState[plugin.pluginId] && this.state.pluginState[plugin.pluginId])
                        })}
                    </div>
                </div>
            </div>
        );
    }

}

export default CanvasBase;

CanvasBase.propTypes = {
    img: PropTypes.object.isRequired
};
