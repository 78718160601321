import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import Sidebar from './../Sidebar';
import SidebarHeader from './../Sidebar/SidebarHeader';
import SidebarBody from './../Sidebar/SidebarBody';
import SidebarFooter from './../Sidebar/SidebarFooter';
import Player from './../Player';
import PlayerControls from './../Player/PlayerControls';
import Footer from './../Footer';
import ContactBox from './../ContactBox';
import {
    ApplicationMenu,
    ApplicationSelectListItem,
    BeyBroadcaster,
    BeyonityUiUtils,
    Button,
    CollapsibleSelect,
    DropdownSelect,
    GoogleMap,
    Lightbox,
    Link,
    MediaQuery,
    SelectListItem,
    Share,
    Tooltip
} from '@beyonityeu/beyonity-ui-buttons';

import styles from './App.module.scss';
import './App.css';
import ItemCardList from "../ItemCardList";
import FilterBox, {
    FILTER_BOX_TOGGLE_EVENT_TOPIC,
    FILTER_SETTINGS_CHANGE_EVENT_TOPIC,
    FILTERABLE_CATEGORIES,
    HIGHLIGHT_SHAPES_EVENT_TOPIC
} from "../FilterBox";
import FunctionsMenu from "../FunctionsMenu/FunctionsMenu";
import {APP_GLOBAL, DataManager, DISPLAY_MODES} from "./DataManager";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import PinProvider from "../../utils/PinProvider";
import OptionsHeader from "../OptionsHeader/OptionsHeader";
import Canvas from "../Canvas/Canvas";
import CanvasZoom from "../Canvas/Canvas/CanvasZoom/CanvasZoom";
import CanvasSVG from "../Canvas/Canvas/CanvasSvg/CanvasSvg";
import CanvasDrag from "../Canvas/Canvas/CanvasDrag";
import CanvasRotate from "../Canvas/Canvas/CanvasRotate/CanvasRotate";
import DetailBox from "../DetailBox/DetailsBox";




export const LIGHTBOX_OPEN_EVENT_TOPIC = 'lightbox_open_event';
export const MAP_TYPE_CHANGE_TOPIC = 'map-type-change-topic';

/**
 * This is the main entry point of the application
 * Here initial data loading, preparations and calculations are done
 * Also the rendering of the main components is done here, while other components
 * are rendered within this one.
 * Thus, this serves, while also being a view, as the main controller of the application
 *
 * Communication with other components often happen though the BeyonityBroadcaster class
 * which allows for a nice decoupling of components especially with this and the `GoogleMap` component.
 * (Those are coming from the beyonity-ui library)
 *
 * @class App
 * @author Willi Boelke
 */
class App extends Component {


    /**
     * Array of available categories b
     * @type {[string]}
     */
    categories = ["all"];
    /**
     * Object of available types per category
     * @type {{all: [string]}}
     */
    availableTypesPerCategory = {all: ["all"]};

    dataManager = null;

    canvasRotate = new CanvasRotate();

    canvasSvg = null;

    //
    // --------- Component Lifecycle -----------
    //


    context
    props



    constructor(props) {
        super(props);

        this.mediaQuerry = new MediaQuery();
        this.PlayerControlsRef = React.createRef();
        this.ContactBoxRef = React.createRef();
        this.LightboxRef = React.createRef();
        this.detailsBoxRef = React.createRef();
        this.FilterBoxRef = React.createRef();
        this.TooltipRef = React.createRef();

        BeyBroadcaster.instance.unsubscribe(LIGHTBOX_OPEN_EVENT_TOPIC, this);
        BeyBroadcaster.instance.unsubscribe(GoogleMap.BROADCAST_CLUSTER_TOPIC, this);
        BeyBroadcaster.instance.unsubscribe(GoogleMap.BROADCAST_MAP_STATE, this);
        BeyBroadcaster.instance.unsubscribe(MAP_TYPE_CHANGE_TOPIC, this);
        BeyBroadcaster.instance.unsubscribe(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, this);
        BeyBroadcaster.instance.unsubscribe(MediaQuery.SCREEN_CHANGE_EVENT, this);
        BeyBroadcaster.instance.subscribe(LIGHTBOX_OPEN_EVENT_TOPIC, this);
        BeyBroadcaster.instance.subscribe(MAP_TYPE_CHANGE_TOPIC, this);
        BeyBroadcaster.instance.subscribe(GoogleMap.BROADCAST_CLUSTER_TOPIC, this);
        BeyBroadcaster.instance.subscribe(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, this);
        BeyBroadcaster.instance.subscribe(GoogleMap.BROADCAST_MAP_STATE, this);
        BeyBroadcaster.instance.subscribe(MediaQuery.SCREEN_CHANGE_EVENT, this);
        BeyBroadcaster.instance.subscribe(HIGHLIGHT_SHAPES_EVENT_TOPIC, this);

        this.state = {
            loadingText    : false,
            error          : false,
            isLoaded       : false,
            filterSettings : false,
            tooltipContent : false,
            markers        : false,
            clickedMarkerId: false,
            linkedItemId   : false,
            loadingScreenCustomer: false,
            mapType        : "hybrid",
            isLandscape    : this.mediaQuerry.get("orientation") === "landscape"
        };
        const {
                domain,
                apiSlug,
                id,
                language
            } = this.props,

            api = domain + apiSlug;

        const apiProps = {
            domain,
            api,
            id,
            language
        };

        this.dataManager = new DataManager(
            apiProps,
            this.onLanguageLoaded,
            this.onCustomerLoaded,
            this.onDataLoaded,
            this.onCustomContentLoaded,
            this.onProcessed,
            this.onError
        );


        this.canvasSvg = new CanvasSVG(
            this.state.markers, this.canvasRotate)
    }


    componentDidMount() {
        this.dataManager.loadFromServer();
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.state.isLoaded !== nextState.isLoaded) return true;
        if (this.state.error !== nextState.error) return true;
        if (this.state.tooltipContent !== nextState.tooltipContent) return true;
        if (this.state.mapType !== nextState.mapType) return true;
        if (this.state.isLandscape !== nextState.isLandscape) return true;
        if (this.state.clickedMarkerId !== nextState.clickedMarkerId) return true;
        if (this.state.markers !== nextState.markers) return true;
        if (this.state.linkedItem !== nextState.linkedItem) return true;
        if (this.state.msg !== nextState.msg) return true;
        if (this.state.loadingScreenCustomer !== nextState.loadingScreenCustomer) return true;
        return this.state.loadingText !== nextState.loadingText;
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.linkedItem !== prevState.linkedItem) {
            this.detailsBoxRef.current.preview(this.state.linkedItem, this.state.filterSettings);
        }
    }




    //
    // --------- Rendering -----------
    //


    render() {

        const {
                error,
                isLoaded,
                tooltipContent,
                mapType,
                isLandscape,
                markers,
                loadingText,
                clickedMarkerId
            } = this.state,
            {
                map,
                title,
                share,
                contact,
            } = APP_GLOBAL.language,
            {
                domain,
                apiSlug,
                language
            } = this.props,
            {
                dsc,
                items,
                settings,
                location,
            } = this.dataManager.getData(),

            api = domain + apiSlug,

            loadingContent = error || !isLoaded


        return (
            <div className={styles.app}>
                <LoadingScreen text={loadingText}
                               app={"portfolio"}
                               customer={this.state.loadingScreenCustomer}
                               finished={isLoaded}/>
                {( !loadingContent && !error ) &&
                    <>
                        <Helmet>
                            <title>{`${dsc} ${title} ⁂ Powered by Beyonity`}</title>
                            <meta name="description" content={share.description}/>
                            <meta property="og:title" content={dsc}/>
                            <meta property="og:description" content={share.description}/>
                            <meta property="og:image" content={""/* share image has to be defined*/}/>
                            <meta name="twitter:card" content="summary_large_image"/>
                            <link rel="mask-icon" href="/media/favicons/safari-pinned-tab.svg"
                                  color={settings?.ci?.primary || 'rgb(60, 87, 225)'}/>
                        </Helmet>
                        <style>{`:root{--ci-primary:${settings?.ci?.primary};--ci-secondary:${settings?.ci?.secondary};--ci-tertiary:${settings?.ci?.tertiary}}`}</style>
                        <div className={styles.appContent}>
                            {this._renderSidebar()}
                            <Player id={"player"}>
                                {isLandscape &&
                                    <FilterBox ref={this.FilterBoxRef} secondary filterItemNumber={items?.length}/>
                                }
                                <div className={styles.playerContent} id={"player-content"}>
                                    {APP_GLOBAL.displayMode === DISPLAY_MODES.map &&
                                        <GoogleMap apiKey={`AIzaSyBd1GCAdcqLPIvQKcuU4qmEyI388DgbUm0`}
                                                   mapType={mapType}
                                                   showMapTypeSwitcher={false}
                                                   zoomScrollPrevention={false}
                                                   pinProvider={new PinProvider()}
                                                   circleColors={{
                                                       fillColor  : `rgb(${settings?.ci?.tertiary})`,
                                                       strokeColor: `rgb(${settings?.ci?.primary})`,
                                                       fillOpacity  : 0.2,
                                                       strokeOpacity: 0.8
                                                   }}
                                                   mapLocalization={this.dataManager.getData().language}
                                                   texts={{
                                                       satellite               : map.controls.satellite,
                                                       roadmap                 : map.controls.map,
                                                       accept                  : map.banner.accept,
                                                       scriptLoadingNotice     : map.banner.txt,
                                                       scriptLoadingNoticeTitle: map.banner.headline,
                                                   }}
                                                   markers={markers}
                                                   highlightedMarkerId={clickedMarkerId}
                                                   position={location}>

                                        </GoogleMap>
                                    }
                                    {APP_GLOBAL.displayMode === DISPLAY_MODES.canvas &&
                                        <Canvas data={APP_GLOBAL.data.faces}
                                                active={"f1021"}
                                                plugins={[
                                                    this.canvasRotate,
                                                    new CanvasZoom(false, false, APP_GLOBAL.data.facesSettings.zoom),
                                                    this.canvasSvg,
                                                    new CanvasDrag()]}>
                                        </Canvas>
                                    }
                                    <PlayerControls
                                        topLeft={<FunctionsMenu/>}
                                        topTop={!isLandscape && this._renderApplicationMenu()}
                                        topRight={isLandscape &&
                                            <div className={styles.playerHeader}>
                                                <div className={styles.buttons}>
                                                    {APP_GLOBAL.data?.contact?.show === 1 &&
                                                        <Button
                                                            label={contact.button.common}
                                                            className={styles.contactButton}
                                                            iconLeft="mail"
                                                            onClick={() => {
                                                                this.ContactBoxRef.current.open()
                                                            }}
                                                            size="l"
                                                            elevated
                                                            buttonType="primary"
                                                            buttonVariant={"main"}
                                                            centered
                                                            fullWidth
                                                            notBreaking
                                                        />
                                                    }

                                                </div>
                                                <div className={"beyonity-po--modal-header__logo"}
                                                     style={{marginTop: "-16px"}}>
                                                    <img src={this.dataManager.getData().logo}
                                                         alt={this.dataManager.getData().dsc}
                                                         style={{maxWidth: `250px`}}/>
                                                </div>

                                            </div>

                                        }
                                        className={styles.playerControls}
                                        ref={this.PlayerControlsRef}
                                    />
                                </div>
                                <Footer brand={this.dataManager.getData().company}>
                                    <Link className="textTruncate"
                                          href={this.dataManager.getData().imprint}>{APP_GLOBAL.language.imprint}</Link>
                                    <Link className="textTruncate"
                                          href={this.dataManager.getData().privacyPolicy}>{APP_GLOBAL.language.privacyPolicy}</Link>
                                </Footer>
                            </Player>
                            {!isLandscape &&
                                <FilterBox ref={this.FilterBoxRef}
                                           zIndex={200}
                                           filterItemNumber={this.dataManager.getData().items.length}/>
                            }
                            <ContactBox
                                title={APP_GLOBAL.language.contact.button.common}
                                asp={this.dataManager.getData().contact.asp}
                                action={api}
                                id={this.dataManager.getData().id}
                                language={language}
                                zIndex={300}
                                policyLinks={[this.dataManager.getData().privacyPolicy]}
                                ref={this.ContactBoxRef}
                            />
                            <DetailBox ref={this.detailsBoxRef} onClose={() => {
                                this.setState({clickedMarkerId: false});
                                APP_GLOBAL.displayMode === DISPLAY_MODES.canvas && this.canvasSvg.setClickedMarker(false, false);
                            }}/>
                            <Tooltip ref={this.TooltipRef}
                                     parentId={"player-content"}
                                     className={styles.tooltip}
                                     displayTooltip={tooltipContent !== false}>
                                {tooltipContent}
                            </Tooltip>
                        </div>
                        <Lightbox ref={this.LightboxRef}/>
                    </>
                }
            </div>
        );
    }


    _renderSidebar() {

        const {
            isLandscape
        } = this.state;


        return (
            <Sidebar>
                <SidebarHeader>
                    {isLandscape && this._renderApplicationMenu()}
                </SidebarHeader>
                <SidebarBody renderTools={this._renderTools}>
                    <ItemCardList items={this.dataManager.getData().items}
                                  onMarkersChanged={(markers) => {
                                      this.canvasSvg.setMarkers(markers);
                                      this.setState(
                                          {
                                              markers: markers
                                          }
                                      )
                                  }}
                                  onItemClick={(item, jumpToo) => {
                                      this.detailsBoxRef.current.preview(item, this.state.filterSettings);
                                      this.canvasSvg && this.canvasSvg.setClickedMarker(item, jumpToo);
                                      this.setState({item: item, clickedMarkerId: item.id});
                                      if (jumpToo) {
                                          BeyBroadcaster.instance.publish(GoogleMap.BROADCAST_MAP_POSITION_TOPIC,
                                              {
                                                  lat : item.location.lat,
                                                  lng : item.location.lng,
                                                  zoom: 18
                                              });
                                      }
                                  }}
                                  onItemHover={(item, showTooltip) => {
                                      this.canvasSvg && this.canvasSvg.setHoveredMarker(item)
                                      this.setState({
                                          item          : item,
                                          tooltipContent: showTooltip ? ItemCardList.renderProjectItemCard(
                                              item,
                                              1,
                                              true,
                                              false,
                                              false,
                                              this.state.filterSettings.label,
                                              true) : false,
                                      });
                                  }}

                    />
                </SidebarBody>
                <SidebarFooter renderTools={this._renderTools}>
                </SidebarFooter>
            </Sidebar>
        );
    }


    _renderTools = () => {

        const {
            isLandscape
        } = this.state;

        return (
            <>
                <div className={styles.toolSide}>
                    {
                        !isLandscape && <Button
                            label={APP_GLOBAL.language.contact.button.common}
                            iconLeft="mail"
                            onClick={() => this.ContactBoxRef.current.open()}
                            size="s"
                            elevated
                            buttonType="primary"
                            buttonVariant={"main"}
                            centered
                            fullWidth
                            notBreaking
                        />
                    }
                    {!APP_GLOBAL.data.settings.hasOnlyPois &&
                    <Button size={"s"}
                            iconLeft={"filter_list"}
                            buttonType={"borderless"}
                            buttonVariant={"main"}
                            onClick={
                                () => {
                                    BeyBroadcaster.instance.publish(FILTER_BOX_TOGGLE_EVENT_TOPIC, {});
                                }
                            }/>
                    }
                </div>
            </>

        );
    }


    /**
     * This just renders the ApplicationMenu  which then will be used either in the
     * sidebar header or in the player controls (portrait)
     * @private
     */
    _renderApplicationMenu() {
        const {
                isLandscape
            } = this.state,
            {
                dsc,
                logo,
                iconLogo,
                languageSwitchObject
            } = this.dataManager.getData();

        const functions = [];

        const hasLanguageSwitch = ( languageSwitchObject?.length > 0 );

        APP_GLOBAL.data?.availableCategories?.forEach((category, index) => {
            if (!FILTERABLE_CATEGORIES.includes(category)) return;
            functions.push({
                id   : index,
                active: this.state.filterSettings.category === category,
                label: APP_GLOBAL.language.categories[category].name,
                dsc  : APP_GLOBAL.language.categories[category].dsc, onClick: () => {
                    this.FilterBoxRef.current.setCategoryFilter(category);
                    this.FilterBoxRef.current.setCategoryFilter(category);
                }
            });
        });

        return (
            <ApplicationMenu applicationName={dsc + " Portfolio"}
                             mobile={!isLandscape}
                             openMenuHeader={
                                 <OptionsHeader bottomSeparator>
                                     <Share
                                         channels={['link', 'webshare', 'facebook', 'twitter', 'telegram', 'whatsapp', 'xing', 'linkedin']}
                                         shareLink={window.location.href}
                                         title={""}
                                         label={APP_GLOBAL.language?.share?.btn}
                                         sharedContent={{
                                             url: window.location.href,
                                         }}
                                         renderToggle={(props) => {
                                             props.iconRight = null;
                                             return (
                                                 <Button {...props} buttonType={"borderless"}/>
                                             )
                                         }}
                                         langStrings={{
                                             mail: APP_GLOBAL.language?.share?.shareMail,
                                             more: APP_GLOBAL.language?.share?.shareMore,
                                             link: APP_GLOBAL.language?.share?.shareLink,
                                         }}
                                     />
                                     {hasLanguageSwitch &&
                                         <DropdownSelect
                                             size={"s"}
                                             parentSelector={"sidebar"}
                                             icon={"language"}
                                             renderToggle={(props) => {
                                                 props.iconRight = null;
                                                 return (
                                                     <Button {...props} buttonType={"borderless"}/>
                                                 )
                                             }}
                                             className={styles.function}
                                             initialItem={APP_GLOBAL.selectedLanguage}
                                             onSelect={(item) => {
                                                 let url = new URL(window.location.href);
                                                 url.searchParams.set('lang', item.id);
                                                 window.location.href = url.href;
                                             }}
                                             options={languageSwitchObject}
                                         >
                                             {(props) => {
                                                 const {
                                                     item,
                                                 } = props;

                                                 let dispItem = BeyonityUiUtils.copyObject(item);
                                                 dispItem.label = item.itemLabel;

                                                 return <SelectListItem {...props} item={dispItem}/>
                                             }}
                                         </DropdownSelect>
                                     }
                                 </OptionsHeader>
                             }
                             mainNavFunctions={functions?.length > 1 ? functions : false}
                             applicationLogo={iconLogo || logo || "home"}>
                {functions?.length > 1 &&
                    <CollapsibleSelect
                        initialItem={1}
                        size="m"
                        onSelect={(item) => {
                            item.onClick();
                        }}
                        elevation="Neutral"
                        iconName="language"
                        options={functions}
                    >
                        {(props) => (
                            <ApplicationSelectListItem {...props}/>
                        )}
                    </CollapsibleSelect>
                }
            </ApplicationMenu>
        );
    }


    //
    // --------- Event Handling -----------
    //

    /**
     * this includes events from the `Lightbox` and the `FilterBox`
     * which either activate the lightbox or notify about filter changes respectively.
     * @param topic
     * @param event
     */
    onReceive = (topic, event) => {
        switch (topic) {
            case LIGHTBOX_OPEN_EVENT_TOPIC:
                if (!this.LightboxRef.current) return;
                console.log("Lightbox open event received", event);
                this.LightboxRef.current.openLightbox(event.src, event.title, event.headerElements);
                break;
            case GoogleMap.BROADCAST_MAP_STATE:
                this.onMapLoaded();
                break;
            case FILTER_SETTINGS_CHANGE_EVENT_TOPIC : {
                this.setState({
                    filterSettings: event
                });
                break;
            }
            case HIGHLIGHT_SHAPES_EVENT_TOPIC : {
                this.canvasSvg.setHighlightAllMarkers(APP_GLOBAL.data.settings.hasOnlyPois ? true : this.event.show);
                break;
            }
            case MAP_TYPE_CHANGE_TOPIC : {
                this.setState({mapType: event.mapType});
                break;
            }
            case MediaQuery.SCREEN_CHANGE_EVENT : {
                this.setState({isLandscape: event.orientation === "landscape"});
            }
        }
    }




    //
    // --------- load callbacks -----------
    //

    onLanguageLoaded = () => {
        this.setState({
            loadingText: APP_GLOBAL.language.loadingScreen.text
        })
    }

    onDataLoaded = () => {
        this.setState({
            msg: "Processing data..."
        })
    }

    onCustomContentLoaded = () => {
        this.setState({
            msg: "Looking for your new house..."
        })
    }

    onError = (error, msg) => {
        this.setState({
            error   : error,
        });
    }

    onCustomerLoaded = () => {
        this.setState({loadingScreenCustomer: APP_GLOBAL.customer});
    }

    onProcessed = () => {
        const loadFilters = () => {
            if (!APP_GLOBAL.filterSettings) return;
            BeyBroadcaster.instance.publish(FILTER_SETTINGS_CHANGE_EVENT_TOPIC, APP_GLOBAL.filterSettings);

            this.canvasSvg.setHighlightAllMarkers(APP_GLOBAL.data.settings.hasOnlyPois ? true : false);
        }

        // checking for an itemID in the url setting to state if found
        const urlParams = new URLSearchParams(window.location.search);
        const itemID = urlParams.get('itemID');
        if (itemID) {
            let item = this.dataManager.getData().items.find((item) => {
                if (item.id === itemID) return true;
                else if (!item.childNavigators) return false;
                else if (item.childNavigators.includes(itemID)) return true;
                return false;
            });
            if (item) {
                this.setState({
                    filterSettings: this.dataManager.getData().filterSettings,
                    linkedItem: item,
                    isLoaded  : true,
                    error     : null,
                }, loadFilters);
            }
        } else {
            this.setState({
                filterSettings: this.dataManager.getData().filterSettings,
                isLoaded: true,
                error   : null,
            }, loadFilters);
        }
    }


    /**
     * This will be called from the `onReceive` method when the GoogleMap broadcasts that it was rendered
     *
     * @private
     */
    onMapLoaded = () => {

        const {
            filterSettings,
            linkedItem
        } = this.state;

        if (filterSettings && filterSettings.location && filterSettings.location.lat && filterSettings.location.lng) {
            setTimeout(() => {
                const {
                    location
                } = filterSettings;
                BeyBroadcaster.instance.publish(GoogleMap.BROADCAST_MAP_CIRCLE_POSITION_TOPIC,
                    {
                        lat   : location.lat,
                        lng   : location.lng,
                        radius: location.radius,
                    });

            }, 0);
        }
        if (linkedItem && linkedItem.location) {
            this.state.clickedMarkerId = false;
            setTimeout(() => {
                this.setState({clickedMarkerId: linkedItem.id});
                BeyBroadcaster.instance.publish(GoogleMap.BROADCAST_MAP_POSITION_TOPIC,
                    {
                        lat : linkedItem.location.lat,
                        lng : linkedItem.location.lng,
                        zoom: 18
                    });
            }, 100);
        }
    }

}

export default App;
