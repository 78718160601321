import {BeyonityUiUtils, ZoomButton} from "@beyonityeu/beyonity-ui-buttons";
import {CanvasPlugin} from "../CanvasPlugin/CanvasPlugin";
import "./CanvasZomm.css"




class CanvasZoom extends CanvasPlugin {

    pluginId = 'CanvasZoom';

    raf;

    onZoomIn = () => {
    };
    onZoomOut = () => {
    };


    constructor(onZoomIn, onZoomOut, maxZoom = 1) {
        super();
        this.fps = 50;
        this.step = 0;
        this.wheelStep = 1 / 10;
        this.scale = 0;
        this.maxZoom = maxZoom;

        if (onZoomIn) this.onZoomIn = onZoomIn;
        if (onZoomOut) this.onZoomOut = onZoomOut;
    }


    //
    // --------- CANVAS PLUGIN -----------
    //

    init = (canvas) => {
        super.init(canvas);
        this.rto = window.setTimeout(() => {
            this.canvas.canvasRef.current.addEventListener('wheel', this.processWheel, {passive: false});
            this.canvas.eventManager.on('pinchstart pinchmove', this.processPinch);
        }, 0);
    }


    destroy = () => {
        super.destroy()
        window.clearTimeout(this.rto);
        this.canvas.canvasRef.current.removeEventListener('wheel', this.processWheel, {passive: false});
        this.canvas.eventManager.off('pinchstart pinchmove', this.processPinch);
    }

    renderPlugin = (pluginState) => {
        const zoom = pluginState?.zoom ? pluginState.zoom : 0;
        return <div className={`beyonity-ui--canvas-plugin beyonity-ui--canvas-zoom`} key={this.pluginId}>
            <ZoomButton
                zoomable={{
                    zoom_in: zoom < this.maxZoom - this.step,
                    zoom_out: zoom > 0
                }}
                onZoomIn={() => {
                    this.step = this.wheelStep;
                    this.z();
                }}
                onZoomOut={() => {
                    this.step = -this.wheelStep;
                    this.z();
                }}
                onZoomEnd={() => {
                    this.cancel();
                }}
            />
        </div>

    }


    //
    // --------- PLUGIN LOGIC -----------
    //

    requestAnimationFrame = f => window.setTimeout(f, 1000 / this.fps);

    cancelAnimationFrame = id => window.clearTimeout(id);

    zooming = (z) => {
        const ret = {
            zoom_in : true,
            zoom_out: true
        };
        this.canvas.zoom += z;
        if (this.canvas.zoom <= 0) {
            this.canvas.zoom = 0;
            ret.zoom_out = false;
        } else if (this.canvas.zoom >= this.maxZoom) {
            this.canvas.zoom -= z;
            ret.zoom_in = false;
        }
        this.canvas.zoom = +this.canvas.zoom.toFixed(2);
        this.canvas.setPluginState(this.pluginId, {zoom: this.canvas.zoom});
        this.canvas.redraw();

        this.onZoomIn && ret.zoom_in && this.onZoomIn(this.canvas.zoom);
        this.onZoomOut && !ret.zoom_out && this.onZoomOut(this.canvas.zoom);

        return ret;
    };

    z = () => {
        const zoom = this.zooming(this.step);
        if (zoom.zoom_in && zoom.zoom_out) {
            this.raf = this.requestAnimationFrame(this.z);
        } else {
            this.cancel();
        }
    };

    cancel = () => {
        this.cancelAnimationFrame(this.raf);
        this.raf = undefined;
    };


    wz = step => {
        const zoom = this.zooming(step);
    };

    processWheel = e => {
        const step = -Math.sign(e.deltaY) * this.wheelStep;
        if (BeyonityUiUtils.hasParent()) {
            if (e.ctrlKey) {
                e.preventDefault();
                this.wz(step);
                // this.canvas.hover(e);
            } else {
                // this.hintRef.show();
            }
        } else {
            this.wz(step);
        }
    };

    processPinch = e => {
        switch (e.type) {
            case 'pinchstart':
                this.scale = e.scale;
                break;
            case 'pinchmove':
                this.wz(+( e.scale - this.scale ).toFixed(4));
                this.scale = e.scale;
                break;
            default:
                console.info(e.type);
        }
    };



}

export default CanvasZoom;