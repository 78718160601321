import styles from './index.module.scss';
import {CanvasPlugin} from "../CanvasPlugin/CanvasPlugin";
import {BeyonityUiUtils} from "@beyonityeu/beyonity-ui-buttons";




class CanvasDrag extends CanvasPlugin {

    pluginId = 'CanvasDrag';


    constructor() {
        super();
        this.active = false;
        this.offset = {x: 0, y: 0};
    }


    init = (canvas) => {
        super.init(canvas);
        this.rto = window.setTimeout(() => {
            this.canvas.canvasRef.current.addEventListener('touchmove', this.touchmove, {passive: false});
            this.canvas.eventManager.on('panstart panmove panend pancancel', this.processPan);
            this.canvas.eventManager.on('pinchstart pinchmove pinchend pinchcancel', this.processPinch);
        }, 0);
    }

    destroy = () => {
        super.destroy();
        window.clearTimeout(this.rto);
        if (!this.canvas || !this.canvas.canvasRef || !this.canvas.canvasRef.current) {
            return
        }
        this.canvas.canvasRef.current.removeEventListener('touchmove', this.touchmove, {passive: false});
        this.canvas.eventManager.off('panstart panmove panend pancancel', this.processPan);
        this.canvas.eventManager.off('pinchstart pinchmove pinchend pinchcancel', this.processPinch);
    }

    touchmove = e => {
        if (this.active) {
            e.preventDefault();
        }
    };

    isDraggable = () => {
        if (!this.canvas) return;
        return !!( this.canvas.maxOffset.x || this.canvas.maxOffset.y );
    };

    onRedraw = () => {
        if (!this.canvas) {
            console.warn('drawPaths called before init');
            return;
        }
        this.isDraggable() ? this.canvas.canvasRef.current.classList.add(styles.drag) : this.canvas.canvasRef.current.classList.remove(styles.drag);
    }

    startDrag = e => {
        if (!this.isDraggable()) {
            return false;
        }
        this.active = true;
        this.offset = {
            x: e.deltaX,
            y: e.deltaY
        };
        this.canvas.canvasRef.current.classList.add(styles.dragging);
    };

    stopDrag = () => {
        this.active = false;
        this.canvas.canvasRef.current.classList.remove(styles.dragging);
    };

    drag = e => {
        const _offset = {
            x: e.deltaX,
            y: e.deltaY
        };
        this.move({
            x: _offset.x - this.offset.x,
            y: _offset.y - this.offset.y
        });
        this.offset = _offset;
    };

    move = o => {
        this.canvas.offset.x -= o.x / ( 1 + this.canvas.zoom );
        this.canvas.offset.y -= o.y / ( 1 + this.canvas.zoom );
        this.canvas.redraw();
    };

    processPan = e => {
        if (e.pointerType === 'touch' && BeyonityUiUtils.hasParent()) {
            if (e.type === 'panmove') {
                // this.hintRef.show();
            }
        } else {
            if (!e.srcEvent.ctrlKey) {
                switch (e.type) {
                    case 'panstart':
                        this.startDrag(e);
                        break;
                    case 'panmove':
                        this.drag(e);
                        break;
                    case 'pancancel':
                    case 'panend':
                        this.stopDrag(e);
                        break;
                    default:
                        console.info(e.type);
                }
            }
        }
    };

    processPinch = e => {
        switch (e.type) {
            case 'pinchstart':
                this.startDrag(e);
                break;
            case 'pinchmove':
                this.drag(e);
                break;
            case 'pinchcancel':
            case 'pinchend':
                this.stopDrag();
                break;
            default:
                console.info(e.type);
        }
    };
}

export default CanvasDrag;


CanvasDrag.propTypes = {};
