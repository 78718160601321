import React from 'react';
import ContentBox from './../ContentBox';
import DetailsBoxRenderer from "../../utils/DetailsBoxRenderer";




class DetailBox extends ContentBox {

    constructor(props) {
        super(props);

        this.state = {
            item       : null,
            data       : null,
            tooltipData: false,
            isOpen     : false,
            nextContent: null,
            displayedCategory: "all",
            isLandscape: window.innerHeight < window.innerWidth,
            ...this.state
        }
        this.detailsBoxRenderer = new DetailsBoxRenderer(this.item);

    }


    render() {
        super.render();
    }


    open(item) {
        super.open();
    }


    preview(item, filterSettings) {
        this.nextContent = item;
        this.nextFilterSettings = filterSettings;
        this.detailsBoxRenderer = new DetailsBoxRenderer(item, filterSettings);

        super.preview();
    }


    renderHeader() {
        super.renderHeader();
        return <>
            {this.detailsBoxRenderer.renderDetailsBoxHeader()}
        </>
    }


    onHidden() {
        this.detailsBoxRenderer = new DetailsBoxRenderer(this.nextContent, this.nextFilterSettings);
        this.setState({
            item: this.nextContent,
            filterSettings   : this.nextFilterSettings,
            displayedCategory: this.nextFilterSettings.category
        });
    }

    renderBody = () => {
        const {item, filterSettings} = this.state;
        if (!item) return null;
        if (!filterSettings) return null;
        return <>
            {this.detailsBoxRenderer.renderDetailsBoxBody()}
        </>
    }


    renderFooter = () => {
        super.renderFooter();
        return this.detailsBoxRenderer.renderDetailsBoxFooter()
    }


    close(callback) {
        super.close(callback);
        this.props.onClose();
    }

}


export default DetailBox;
