import {CATEGORY_COMMERCE, CATEGORY_LIVING, ITEM_TYPE_INFO_SPOT, ITEM_TYPE_NAVIGATOR} from "../components/FilterBox";
import {APP_GLOBAL} from "../components/App/DataManager";
import {
    BeyBroadcaster,
    BeyonityUiUtils,
    Button,
    ContentTileHeaded,
    Format,
    Icon,
    PriceBadge,
    Share,
    ThumbnailButton
} from "@beyonityeu/beyonity-ui-buttons";
import {LIGHTBOX_OPEN_EVENT_TOPIC} from "../components/App/App";
import React from "react";
import OptionsHeader from "../components/OptionsHeader/OptionsHeader";
import BoxArea from "../components/BoxArea/BoxArea";
import styles from "../components/DetailBox/DetailsBox.module.scss";
import UsageChart from "../components/UsageChart/UsageChart";




class DetailsBoxRenderer {
    constructor(item, filterSettings) {
        this.item = item;
        this.type = item?.type;
        this.filterSettings = filterSettings;

        // Check if the itemId is set in the URL and if so, override its value with this items id
        // IF there is no itemId set in the URL, add the parameter and its value to the URL
        this.link = window.location.href;
        if (this.link.includes("itemID=")) {
            this.link = this.link.replace(/itemId=[0-9]+/, "itemId=" + item?.id);
        } else {
            this.link = this.link + "&itemID=" + item?.id;
        }
    }


    renderDetailsBoxHeader() {
        if (!this.item) {
            return null;
        }
        switch (this.type) {
            case ITEM_TYPE_NAVIGATOR:
                return this._renderNavigatorHeader();
            case ITEM_TYPE_INFO_SPOT:
                return this._renderNavigatorHeader();
        }
    }


    renderDetailsBoxBody() {
        if (!this.item) {
            return null;
        }
        switch (this.type) {
            case ITEM_TYPE_NAVIGATOR:
                return this._renderNavigatorBody();
            case ITEM_TYPE_INFO_SPOT:
                return this._renderInfoFlagBody();
        }
    }


    renderDetailsBoxFooter() {
        const {item, filterSettings} = this;
        if (!item) return null;
        if (!filterSettings) return null;

        switch (this.type) {
            case ITEM_TYPE_NAVIGATOR:
                return this._renderNavigatorFooter();
            case ITEM_TYPE_INFO_SPOT:
                return this._renderInfoFlagFooter();
        }

    }


    _renderNavigatorHeader = () => {
        const {item} = this;
        return <div className={styles.headerTitle + " beyonity-ui--text__large-highlighted"}>
            {item && item.dsc}
        </div>
    }

    _renderNavigatorBody = () => {
        const {item, filterSettings} = this;
        // The badge Content
        let status = APP_GLOBAL.data.settings.status;

        let BadgeContent;
        let BadgeColor = status.not_available.color;

        if (filterSettings.category === CATEGORY_COMMERCE) {
            BadgeContent = item.units[filterSettings.category].freeArea;
            if (BadgeContent > 0) {
                BadgeColor = status.available.color;
            }
            BadgeContent = Format.formatArea(BadgeContent);
        }
        if (filterSettings.category === CATEGORY_LIVING) {
            BadgeContent = item.units[filterSettings.category].freeUnits;
            if (BadgeContent > 0) {
                BadgeColor = status.available.color;
            }
            BadgeContent = BadgeContent + " " + "Frei";
        }


        let properties = Object.entries(APP_GLOBAL.data.properties).map(([key, value]) => {
            if (key === "media" || value.dsc === "" || this.item.properties[key] === "") return null;
            return (
                <div className={styles.property} key={"_" + key}>
                    <Icon name={value.icon} size={"m"}
                          color={"var(--color-text--gray-700)"}></Icon>
                    <span className={"beyonity-ui--text__medium-highlighted color-text--gray-800"}>
                        {this.item.properties[key]}
                    </span>
                    <span
                        className={"beyonity-ui--text__small color-text--gray-700"}>{value.dscLong ? value.dscLong : value.dsc}</span>
                </div>
            )
        });
        properties = properties.filter((content) => content !== null);

        return <>
            {this._renderSubHeader(item.location.address, {
                content: BadgeContent,
                color  : BadgeColor
            }, this.link)}
            {this._renderMedia()}
            {
                <UsageChart item={this.item} filterSettings={APP_GLOBAL.filterSettings}/>
            }
            {<BoxArea title={APP_GLOBAL.language?.details?.properties} className={styles.properties}>
                <div className={styles.propertyList}>
                    {
                        properties
                    }
                </div>
            </BoxArea>
            }
        </>
    }



    _renderInfoFlagBody = () => {
        const {item} = this;
        const {
            badge,
        } = item;

        let properties = Object.entries(APP_GLOBAL.data.infoFlagProperties).map(([key, value]) => {
            if (key === "media" || value.dsc === "" || item.properties[key] === "") return null;
            return (
                <ContentTileHeaded title={value.dsc} key={"_" + key}>
                    <>
                        {value.dscLong &&
                            <span className={"beyonity-ui--text__medium color-text--gray-800"}>
                                {value.dscLong}
                           </span>
                        }
                        <span className={"beyonity-ui--text__medium color-text--gray-800"}>
                            {item.properties[key]}
                        </span>

                    </>
                </ContentTileHeaded>
            )
        });

        properties = properties.filter((content) => content !== null);
        return <>
            {this._renderSubHeader(item.location.address, {
                content: badge,
                color  : APP_GLOBAL.data.settings.status.available.color
            }, this.link)}
            {this._renderMedia()}
            {properties.length > 0 &&
                properties.map((content) => content)
            }
        </>
    }

    _renderSubHeader = (address, badge, link) => {
        const {item} = this;
        return <div>
            {( address !== null && address !== {} ) &&
                <div
                    className="beyonity-ui--text__medium-highlighted color-text--gray-800">{address}
                </div>
            }
            <OptionsHeader>
                {badge.content &&
                    <div>
                        <PriceBadge color={badge.color}
                                    size={"m"}
                                    content={badge.content}/>
                    </div>
                }
                {link &&
                    <div>
                        <Share
                            channels={['link', 'webshare', 'facebook', 'twitter', 'telegram', 'whatsapp', 'xing', 'linkedin']}
                            shareLink={link}
                            title={item && item.dsc}
                            label={APP_GLOBAL.language?.share?.btn}
                            sharedContent={{
                                dsc  : item && item.dsc,
                                image: item && item.thumbnail,
                                title: item && item.dsc,
                                url  : link,
                            }}
                            langStrings={{
                                mail: APP_GLOBAL.language?.share?.shareMail,
                                more: APP_GLOBAL.language?.share?.shareMore,
                                link: APP_GLOBAL.language?.share?.shareLink,
                            }}
                        /></div>}

            </OptionsHeader>
        </div>
    }


    _renderMedia = () => {
        const {item} = this;

        let media = [];
        if (item.properties.media) {
            media = item.properties.media.split(',');
            if (!item.thumbnail) {
                item.thumbnail = media[0];
            }
        } else {
            media[0] = item.thumbnail;
        }

        return <>
            {item.thumbnail &&
                <ThumbnailButton thumbnail={item?.thumbnail}
                                 label={APP_GLOBAL.language?.details?.media}
                                 iconLeft={"image"}
                                 iconRight={"arrow_right_alt"}
                                 onClick={() => {
                                     BeyBroadcaster.instance.publish(LIGHTBOX_OPEN_EVENT_TOPIC, {
                                         title         : item.dsc,
                                         src           : media,
                                         headerElements: []
                                     })
                                 }}/>
            }
            {item.content &&
                <BoxArea elevation={"E000"}>
                    {this.bbTextRenderer(item.content)}
                </BoxArea>
            }
        </>
    }




    bbTextRenderer = (text) => {
        const renderText = (text) => {
            const tagRegex = /(\[\/?b\]|\[\/?i\]|\[\/?u\]|\[\/?url\]|\[\/?btn\]|\n)/;
            const parts = text.split(tagRegex).filter((part) => part !== "");
            const stack = [];
            const result = [];
            for (let i = 0; i < parts.length; i++) {
                const current = parts[i];
                if (current === "[b]" ||
                    current === "[i]" ||
                    current === "[u]" ||
                    current === "[url]" ||
                    current === "[btn]") {
                    stack.push(current);
                } else if (current === "[/b]"
                    || current === "[/i]"
                    || current === "[/u]"
                    || current === "[/url]"
                    || current === "[/btn]") {
                    const tag = stack.pop();
                    const content = result.pop();
                    if (tag === "[b]") {
                        result.push(<span className={"beyonity-ui--text__medium-bold"} style={{
                            fontStyle: "inherit",
                        }}>{content}</span>);
                    } else if (tag === "[i]") {
                        result.push(<span className={"beyonity-ui--text__italic"} style={{
                            fontWeight: "inherit",
                        }}>{content}</span>);
                    } else if (tag === "\n") {
                        result.push(<br/>);
                    } else if (tag === "[u]") {
                        result.push(<span className={"beyonity-ui--text__underline"} style={{
                            fontWeight: "inherit",
                        }}>{content}</span>);
                    } else if (tag === "[url]") {
                        let contents = content[0].split("::");
                        result.push(<a href={contents[1]} target="_blank"
                                       className={"beyonity-ui--text__link color-text--ci-primary"} style={{
                            fontWeight: "inherit"
                        }}>{contents[0]}</a>)
                    } else if (tag === "[btn]") {
                        let contents = content[0].split("::");
                        result.push(<Button label={contents[0]} size={"s"} onClick={() => {
                            window.open(contents[1], "_blank")
                        }}/>)
                    }
                } else if (current === "\n") {
                    result.push(<br/>);
                } else {
                    if (stack.length > 0) {
                        result.push(renderText(current));
                    } else {
                        result.push(current);
                    }
                }
            }
            return result;
        };

        return renderText(text);
    };



    _renderNavigatorFooter = () => {
        const {item, filterSettings} = this;
        let open = BeyonityUiUtils.appendBacpArgumentsTo(item.webPath[filterSettings.category], {opener: "portfolio"})
        if (open === undefined || open === "") return null;
        return (
            <Button label={APP_GLOBAL.language?.details?.open?.navigator}
                    fullWidth
                    centereds
                    size="l"
                    varian
                    buttonVariant={`main`}
                    buttonType={`primary`}
                    onClick={
                        () => {
                            window.open(open, "_self");
                        }
                    }/>
        )
    }

    _renderInfoFlagFooter = () => {
        const {item} = this;
        let open = item.webPath;
        if (open === undefined || open === "") return null;
        return (
            <Button label={APP_GLOBAL.language?.details?.open?.infoFlag}
                    fullWidth
                    centered
                    size="l"
                    varian
                    buttonVariant={`main`}
                    buttonType={`primary`}
                    onClick={
                        () => {
                            window.open(open, "_blank");
                        }
                    }/>
        )
    }
}

export default DetailsBoxRenderer;