export const AppIcons = {
    portfolio: <svg height="42px" widths="42px" viewBox="0 0 448.000000 447.000000"
                    xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#fefefe" stroke="none">
            <path d="M0 2235 c0 -2235 0 -2235 2240 -2235 2240 0 2240 0 2240 2235 0 2235
0 2235 -2240 2235 -2240 0 -2240 0 -2240 -2235z m3130 1310 c0 -8 7 -15 15
-15 8 0 15 5 15 10 0 6 29 10 66 10 67 0 67 0 62 -65 -6 -65 -6 -65 128 -65
112 0 134 2 134 15 0 8 -9 15 -20 15 -11 0 -20 7 -20 15 0 8 -9 15 -19 15 -11
0 -23 5 -26 10 -3 6 -26 10 -51 10 -40 0 -44 2 -44 25 0 16 6 25 15 25 9 0 15
-9 15 -21 0 -23 57 -19 72 5 5 8 8 7 8 -2 0 -10 13 -13 44 -11 43 3 46 1 107
-67 108 -119 162 -174 232 -231 37 -30 67 -63 67 -72 0 -27 89 -45 140 -28 45
14 51 36 24 89 -9 16 -12 28 -7 25 5 -4 18 -24 28 -47 10 -22 22 -40 27 -40 4
0 8 -7 8 -15 0 -9 25 -67 56 -130 30 -63 66 -153 79 -200 13 -47 28 -92 34
-99 6 -7 8 -15 5 -18 -5 -6 11 -76 27 -113 4 -11 10 -42 12 -70 3 -27 8 -77
12 -110 34 -260 -18 -714 -105 -910 -10 -22 -21 -51 -25 -65 -4 -14 -20 -52
-36 -84 -16 -33 -29 -65 -29 -72 0 -7 -6 -19 -12 -26 -16 -17 -39 -57 -72
-125 -14 -29 -30 -53 -35 -53 -6 0 -11 -6 -11 -14 0 -8 -6 -21 -12 -28 -7 -7
-22 -30 -32 -51 -11 -21 -32 -45 -47 -54 -15 -9 -35 -33 -44 -54 -9 -20 -28
-42 -41 -50 -13 -7 -24 -16 -24 -21 0 -13 -170 -180 -239 -233 -102 -80 -135
-104 -151 -112 -8 -3 -29 -17 -46 -30 -17 -13 -36 -23 -42 -23 -7 0 -20 -8
-30 -17 -15 -16 -161 -92 -222 -116 -14 -5 -33 -13 -42 -18 -10 -5 -27 -9 -38
-9 -11 0 -20 -4 -20 -10 0 -5 -8 -10 -18 -10 -10 0 -38 -10 -63 -22 -24 -12
-49 -21 -55 -20 -15 3 -117 -24 -142 -37 -11 -6 -21 -8 -24 -6 -2 3 -47 -3
-99 -12 -119 -22 -551 -24 -612 -4 -21 7 -61 17 -90 21 -174 29 -442 118 -491
162 -11 10 -26 18 -33 18 -8 0 -48 19 -89 43 -41 23 -87 48 -102 56 -15 8 -31
20 -37 26 -5 6 -36 28 -67 49 -32 20 -58 41 -58 46 0 5 -9 12 -21 15 -11 4
-43 29 -72 56 -138 132 -237 232 -237 240 0 5 -17 27 -37 48 -40 41 -65 77
-85 123 -7 16 -16 28 -19 28 -4 0 -22 25 -39 55 -17 30 -36 55 -41 55 -5 0 -9
7 -9 15 0 8 -11 36 -24 63 -56 111 -66 135 -66 148 0 8 -4 14 -10 14 -5 0 -10
11 -10 24 0 14 -4 27 -8 30 -8 5 -48 124 -71 211 -21 81 -51 254 -51 297 0 25
-3 48 -7 51 -10 11 -5 320 7 371 6 26 8 50 6 54 -10 16 60 331 77 348 4 4 7
17 7 28 0 12 12 44 25 71 14 28 25 56 25 63 0 7 9 30 20 52 11 22 20 48 20 60
0 11 4 20 8 20 5 0 17 20 27 45 17 40 56 79 41 40 -81 -211 -123 -530 -105
-799 13 -197 3 -182 111 -190 163 -11 321 -7 342 9 11 8 39 15 63 15 24 0 43
5 43 10 0 6 7 10 15 10 23 0 19 41 -5 47 -11 3 -20 12 -20 19 0 8 -9 14 -19
14 -18 0 -19 3 -10 30 6 17 15 30 19 30 5 0 13 12 18 28 7 17 11 21 11 10 1
-25 32 -23 38 2 6 23 23 27 23 5 0 -8 -4 -15 -10 -15 -5 0 -14 -15 -19 -32 -5
-18 -10 -25 -10 -15 -1 21 -31 23 -31 2 0 -8 5 -15 10 -15 6 0 8 -11 4 -25 -8
-32 21 -35 32 -3 3 12 13 28 20 36 8 7 14 22 14 32 0 10 5 20 12 22 23 8 0 55
-31 61 -30 6 -30 6 12 92 30 61 47 85 61 85 14 0 17 5 13 21 -12 46 10 66 67
61 58 -5 71 6 95 80 19 56 48 88 82 88 31 0 41 26 14 36 -8 4 -15 17 -15 30 0
26 -50 48 -58 26 -2 -7 -12 -12 -22 -12 -10 0 -20 -10 -23 -22 -4 -12 -9 -19
-12 -16 -11 10 25 68 41 68 12 0 15 6 11 22 -4 14 3 43 18 74 25 53 72 64 285
64 43 0 48 3 76 41 30 41 93 121 212 266 83 100 34 92 550 99 422 7 422 7 422
-15 0 -12 6 -21 15 -21 8 0 15 9 15 20 0 16 7 20 35 20 19 0 35 -4 35 -10 0
-5 7 -10 15 -10 8 0 15 4 15 9 0 10 39 16 118 19 31 2 42 -2 42 -13z"/>
            <path d="M2050 3440 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
            <path d="M2140 3446 c0 -8 -9 -18 -20 -21 -11 -3 -20 -13 -20 -21 0 -15 23
-14 83 2 23 7 22 31 -2 44 -27 14 -41 13 -41 -4z"/>
            <path d="M3947 3103 c-14 -13 -6 -33 13 -33 13 0 20 7 20 20 0 19 -20 27 -33
13z"/>
            <path d="M3808 3093 c-10 -2 -18 -11 -18 -20 0 -11 6 -14 23 -9 12 3 25 6 30
6 4 0 7 7 7 15 0 15 -9 17 -42 8z"/>
            <path d="M3800 3023 c-36 -11 -60 -27 -60 -40 0 -17 12 -16 60 4 54 24 54 54
0 36z"/>
            <path d="M1310 2836 c0 -16 -6 -26 -15 -26 -8 0 -15 -7 -15 -15 0 -8 7 -15 15
-15 8 0 15 6 15 14 0 7 9 16 21 19 25 7 22 40 -4 45 -12 3 -17 -3 -17 -22z"/>
            <path d="M1550 2826 c0 -12 28 -25 53 -26 18 0 5 28 -15 34 -33 8 -38 8 -38
-8z"/>
            <path d="M1530 2745 c0 -20 5 -25 24 -25 22 0 24 -4 23 -49 -1 -49 -1 -50 -29
-44 -21 4 -28 2 -28 -9 0 -17 50 -31 75 -22 26 10 22 168 -5 161 -11 -3 -20
-1 -20 4 0 5 -9 9 -20 9 -15 0 -20 -7 -20 -25z"/>
            <path d="M1480 2535 c0 -11 11 -15 40 -15 29 0 40 4 40 15 0 11 -11 15 -40 15
-29 0 -40 -4 -40 -15z"/>
            <path d="M1030 2495 c0 -8 7 -15 15 -15 8 0 15 -7 15 -15 0 -8 7 -15 15 -15 8
0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -8 0 -15
-7 -15 -15z"/>
            <path d="M1430 2460 c0 -23 -5 -30 -19 -30 -14 0 -24 -13 -37 -45 -9 -25 -21
-45 -25 -45 -5 0 -9 -11 -9 -25 0 -14 -4 -25 -10 -25 -5 0 -10 -7 -10 -15 0
-10 10 -14 33 -13 67 2 77 6 77 38 0 20 5 30 15 30 8 0 15 7 15 15 0 8 -7 15
-15 15 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 4 10 9
10 5 0 12 14 16 30 4 17 13 30 21 30 8 0 14 7 14 15 0 8 7 15 15 15 8 0 15 9
15 20 0 16 -7 20 -30 20 -27 0 -30 -3 -30 -30z"/>
            <path d="M1530 2415 c0 -28 4 -35 20 -35 21 0 24 10 14 48 -10 36 -34 27 -34
-13z"/>
            <path d="M1560 2319 c0 -16 5 -20 27 -17 41 5 44 38 4 38 -25 0 -31 -4 -31
-21z"/>
            <path d="M1558 2269 c-2 -6 -57 -13 -136 -16 -133 -6 -133 -6 -148 -34 -18
-36 -18 -59 1 -59 8 0 15 -4 15 -8 0 -5 35 -7 78 -5 78 5 212 39 212 54 0 5 7
9 15 9 8 0 15 7 15 15 0 8 -4 15 -10 15 -5 0 -10 9 -10 20 0 21 -26 28 -32 9z"/>
            <path d="M1530 2145 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
            <path d="M592 2053 c5 -26 38 -29 38 -4 0 15 -6 21 -21 21 -14 0 -19 -5 -17
-17z"/>
            <path d="M680 2050 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
            <path d="M950 2038 c0 -31 13 -36 36 -14 7 8 23 12 34 9 15 -4 20 0 20 15 0
17 -6 20 -45 20 -43 -1 -45 -3 -45 -30z"/>
            <path d="M1460 2055 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
            <path d="M1770 1865 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
            <path d="M1633 1803 c-15 -5 -18 -33 -4 -33 21 0 41 13 41 26 0 14 -14 17 -37
7z"/>
            <path d="M1770 1795 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
            <path d="M1645 1740 c-3 -5 -15 -10 -26 -10 -10 0 -19 -7 -19 -15 0 -8 6 -15
14 -15 29 0 66 20 66 35 0 17 -25 21 -35 5z"/>
            <path d="M893 1413 c-15 -5 -18 -33 -4 -33 21 0 41 13 41 26 0 14 -14 17 -37
7z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#f5f5f4" stroke="none">
            <path d="M2904 3563 c-152 -4 -144 2 -144 -113 0 -118 5 -130 77 -185 36 -27
85 -72 109 -99 39 -43 143 -116 165 -116 4 0 23 -14 41 -30 18 -17 43 -33 56
-36 12 -4 22 -10 22 -15 0 -5 7 -9 15 -9 13 0 15 23 15 149 0 164 6 152 -137
249 -19 12 -32 26 -29 31 13 20 467 19 518 -2 29 -12 280 -206 292 -225 11
-19 -198 -113 -236 -106 -42 7 -40 10 -42 -88 -1 -14 -4 -30 -8 -34 -14 -14
-8 -34 10 -34 16 0 228 104 411 202 30 15 58 25 62 21 11 -12 56 -166 78 -270
11 -51 25 -102 31 -114 7 -12 18 -58 25 -103 8 -44 22 -115 31 -156 17 -76 24
-427 11 -545 -14 -124 -45 -272 -68 -317 -5 -9 -12 -35 -15 -58 -4 -22 -11
-43 -15 -46 -5 -3 -9 -14 -9 -25 0 -11 -11 -41 -25 -67 -14 -26 -25 -53 -25
-59 0 -7 -4 -13 -10 -13 -5 0 -10 -7 -10 -17 0 -20 -52 -123 -62 -123 -4 0 -8
-7 -8 -15 0 -17 -48 -98 -61 -103 -5 -2 -9 -9 -9 -16 0 -7 -11 -25 -25 -40
-14 -15 -25 -31 -25 -36 0 -6 -9 -15 -20 -22 -11 -7 -20 -16 -20 -21 0 -9 -16
-29 -47 -62 -13 -12 -23 -25 -23 -28 0 -11 -107 -117 -118 -117 -6 0 -14 -9
-17 -20 -3 -11 -10 -20 -14 -20 -5 0 -26 -17 -47 -38 -53 -51 -130 -112 -142
-112 -6 0 -12 -3 -14 -8 -4 -10 -99 -72 -110 -72 -5 0 -18 -9 -30 -20 -11 -10
-37 -26 -57 -36 -20 -9 -49 -22 -65 -30 -16 -8 -35 -14 -43 -14 -7 0 -13 -4
-13 -8 0 -11 -72 -42 -98 -42 -11 0 -27 -6 -34 -13 -18 -17 -90 -42 -165 -57
-35 -7 -63 -16 -63 -20 0 -8 -63 -19 -135 -25 -22 -2 -69 -8 -105 -14 -74 -12
-253 -16 -380 -8 -114 7 -371 57 -395 77 -6 5 -20 8 -31 8 -11 -1 -40 7 -64
19 -25 11 -74 29 -109 39 -35 10 -66 25 -68 32 -3 6 -14 12 -24 12 -10 0 -19
5 -19 10 0 6 -6 10 -12 10 -19 0 -158 72 -158 81 0 5 -7 9 -15 9 -8 0 -15 4
-15 8 0 5 -19 19 -42 32 -42 24 -61 37 -106 78 -14 12 -29 22 -33 22 -12 0
-279 266 -279 277 0 5 -20 32 -45 59 -25 27 -45 56 -45 65 0 10 -3 19 -8 21
-18 8 -82 93 -82 110 0 10 -4 18 -10 18 -5 0 -10 9 -10 19 0 10 -7 21 -15 25
-8 3 -15 14 -15 25 0 10 -7 21 -15 25 -8 3 -15 12 -15 21 0 8 -14 44 -30 78
-17 35 -30 71 -30 80 0 9 -4 18 -10 22 -5 3 -19 38 -30 78 -12 40 -28 95 -37
122 -16 49 -27 123 -38 240 -3 33 -10 76 -14 97 -14 61 -4 363 14 453 33 159
33 157 34 92 2 -87 21 -60 21 29 0 49 -4 74 -12 74 -6 0 -4 7 5 18 10 10 17
33 17 50 0 116 131 561 181 617 5 5 9 13 9 19 0 5 -9 0 -20 -12 -11 -12 -20
-26 -20 -32 0 -5 -3 -10 -8 -10 -7 0 -36 -58 -37 -73 0 -5 -8 -14 -17 -21 -10
-7 -14 -16 -10 -20 4 -4 0 -13 -8 -19 -9 -7 -24 -30 -34 -52 -11 -22 -23 -43
-28 -46 -5 -3 -10 -16 -11 -27 -1 -12 -5 -22 -9 -22 -4 0 -7 -3 -6 -7 3 -13
-13 -53 -21 -53 -4 0 -7 -10 -6 -22 1 -13 -7 -35 -17 -50 -11 -16 -17 -28 -13
-28 3 0 -1 -13 -9 -29 -9 -16 -12 -31 -9 -34 3 -4 1 -12 -5 -20 -6 -7 -14 -28
-17 -47 -3 -19 -10 -40 -16 -46 -5 -7 -9 -16 -8 -20 1 -5 0 -11 -1 -14 -4 -11
-10 -47 -16 -90 -3 -24 -9 -51 -13 -60 -5 -8 -6 -20 -4 -27 2 -7 0 -20 -6 -31
-7 -13 -7 -23 2 -32 9 -10 9 -11 0 -6 -8 4 -12 -4 -12 -26 0 -18 -2 -112 -4
-208 -3 -96 -2 -179 1 -185 4 -5 7 -28 8 -50 1 -23 5 -56 9 -75 4 -19 8 -50 9
-67 0 -18 4 -33 7 -33 4 0 10 -21 14 -47 3 -27 10 -61 15 -78 25 -85 34 -121
35 -130 0 -5 3 -13 7 -16 5 -3 9 -17 10 -32 1 -15 8 -29 15 -31 7 -3 13 -17
13 -31 0 -14 4 -25 9 -25 6 0 8 -9 6 -20 -2 -11 1 -20 6 -20 5 0 14 -12 20
-27 6 -16 9 -28 8 -28 -1 0 12 -26 29 -57 18 -31 32 -64 32 -72 0 -9 4 -16 9
-16 5 0 24 -25 41 -55 17 -30 35 -55 39 -55 3 0 12 -12 19 -28 21 -48 46 -86
70 -105 12 -10 22 -25 22 -33 0 -8 4 -13 9 -9 5 3 12 -3 16 -14 6 -18 192
-209 260 -266 19 -16 42 -36 50 -43 8 -7 15 -11 15 -8 0 3 12 -6 28 -19 51
-46 69 -60 83 -63 8 -2 22 -12 32 -23 10 -10 22 -19 28 -19 11 0 70 -40 81
-55 4 -5 8 -6 8 -2 0 4 20 -4 43 -18 24 -14 50 -25 58 -25 9 0 24 -8 35 -18
17 -16 104 -54 179 -80 17 -6 39 -14 50 -18 11 -4 23 -8 28 -9 4 -2 9 -4 12
-5 3 -1 8 -3 13 -4 4 -1 21 -6 37 -10 17 -5 37 -8 47 -7 9 1 19 -3 22 -8 4 -6
13 -8 21 -4 8 3 15 1 15 -4 0 -5 6 -10 13 -11 6 0 29 -2 50 -3 21 -1 40 -6 43
-11 3 -4 10 -6 15 -3 5 4 15 3 22 -2 17 -11 33 -13 110 -15 37 0 69 -4 71 -7
6 -9 401 0 401 9 0 3 44 10 90 15 22 2 40 5 40 5 0 1 14 3 30 6 17 2 36 4 43
5 6 1 12 5 12 9 0 4 13 6 30 5 16 -1 27 2 24 7 -5 8 16 12 49 10 10 -1 29 5
42 14 14 9 25 13 25 9 0 -5 9 0 19 9 12 11 20 13 23 6 2 -6 12 -3 25 10 12 11
29 20 38 20 8 0 23 4 33 9 26 14 56 24 65 22 5 -1 20 7 35 17 15 10 54 31 87
47 33 16 65 35 70 43 6 7 18 14 27 15 8 2 31 14 49 28 19 14 41 29 49 32 16 8
49 32 151 112 69 53 239 220 239 233 0 5 11 14 24 22 14 7 30 26 37 41 6 16
16 29 21 29 5 0 7 4 4 8 -3 5 8 16 23 25 15 9 36 33 47 54 10 21 25 44 32 51
6 7 12 20 12 28 0 8 5 14 11 14 5 0 21 24 35 52 33 67 57 112 66 118 7 6 29
57 34 79 1 8 9 18 16 22 7 5 9 9 5 9 -5 0 0 15 11 33 12 17 21 35 21 40 1 4 1
8 2 10 0 1 2 13 5 25 2 13 11 33 19 45 8 12 15 34 15 50 0 15 3 26 8 24 4 -3
8 5 8 16 2 32 12 70 21 76 5 3 9 15 10 26 1 11 4 31 7 45 3 14 7 41 11 60 7
46 13 75 15 75 13 0 28 381 16 415 -2 8 -4 29 -4 45 0 17 -2 55 -6 85 -3 30
-8 80 -11 110 -3 30 -8 62 -11 70 -9 20 -14 41 -17 67 -1 13 -5 23 -9 23 -4 0
-5 7 -2 15 4 8 1 21 -5 29 -6 7 -8 16 -4 19 3 4 1 7 -6 7 -7 0 -9 5 -6 10 4 6
2 16 -3 23 -6 7 -16 35 -23 62 -6 28 -30 88 -52 135 -23 47 -41 87 -42 90 0 3
-9 19 -18 37 -9 18 -17 39 -17 47 0 8 -4 16 -9 18 -5 2 -11 10 -13 18 -2 8 -5
17 -8 20 -14 14 -60 87 -60 96 0 5 -9 17 -20 27 -12 10 -18 23 -14 29 4 7 3 8
-4 4 -13 -7 -10 -15 37 -119 43 -94 31 -117 -58 -108 -38 4 -236 185 -394 361
-41 46 -67 68 -67 58 0 -13 -8 -17 -30 -15 -16 1 -30 5 -30 10 0 4 -7 7 -15 7
-8 0 -15 -4 -15 -10 0 -5 -4 -10 -10 -10 -5 0 -10 7 -10 15 0 11 -11 15 -42
15 -52 0 -67 -61 -17 -68 13 -2 28 -8 34 -13 5 -5 14 -9 20 -9 5 0 3 5 -5 11
-12 8 -12 10 5 7 11 -2 25 -14 32 -28 9 -18 13 -20 13 -7 0 9 4 17 8 17 4 0 8
-8 8 -18 0 -17 -11 -19 -101 -21 -101 -1 -101 -1 -95 59 3 34 3 63 0 65 -10 6
-268 12 -396 8z"/>
            <path d="M2165 3550 c-4 -7 70 -10 214 -10 141 0 221 4 221 10 0 6 -78 10
-214 10 -133 0 -217 -4 -221 -10z"/>
            <path d="M1859 3458 c-41 -49 -75 -93 -77 -98 -6 -18 18 -10 25 8 4 9 17 25
30 35 13 10 23 23 23 28 0 6 14 22 30 36 17 13 30 31 30 39 0 8 4 14 9 14 14
0 24 -94 16 -150 -4 -30 -3 -50 3 -50 18 0 77 24 89 36 7 6 35 17 63 24 45 11
69 30 38 30 -7 0 -2 6 12 14 31 16 47 9 23 -10 -17 -14 35 -12 60 3 11 6 9 33
-3 33 -6 0 -45 23 -87 50 -129 84 -187 75 -284 -42z"/>
            <path d="M2270 3445 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
            <path d="M2540 3415 c0 -8 8 -15 18 -15 9 0 27 -3 40 -6 16 -5 22 -2 22 9 0
14 -28 27 -62 27 -10 0 -18 -7 -18 -15z"/>
            <path d="M1712 3279 c-39 -48 -50 -69 -36 -69 5 0 49 49 72 82 32 45 3 34 -36
-13z"/>
            <path d="M1445 3140 c-4 -6 8 -10 29 -10 20 0 36 5 36 10 0 6 -13 10 -29 10
-17 0 -33 -4 -36 -10z"/>
            <path d="M1284 3083 c-58 -126 -89 -188 -95 -193 -9 -6 -40 -82 -36 -86 3 -3
81 81 89 94 1 4 7 6 11 4 5 -1 6 7 2 18 -5 17 -2 20 25 20 36 0 36 -41 -1 -88
-10 -13 -23 -40 -30 -60 -25 -77 -63 -97 -129 -68 -12 5 -19 -3 -29 -31 -7
-21 -19 -45 -26 -53 -7 -8 -41 -73 -76 -145 -35 -71 -66 -132 -70 -135 -6 -5
-49 -98 -49 -106 0 -1 21 -4 47 -6 42 -3 77 -28 39 -28 -9 0 -16 -4 -16 -10 0
-5 -19 -10 -43 -10 -24 0 -52 -7 -63 -15 -34 -26 -414 -16 -414 11 0 7 -5 16
-11 20 -13 8 -9 -22 22 -158 21 -88 21 -88 285 -88 264 0 264 0 326 33 84 44
275 42 308 -3 34 -46 281 -38 513 17 68 17 92 44 49 57 -23 8 -131 114 -163
160 -22 33 -25 34 -76 29 -60 -7 -60 -6 -33 47 21 41 26 100 11 116 -6 5 -19
52 -30 104 -11 52 -20 91 -21 85 -2 -5 -5 3 -9 19 -8 38 -1 117 9 111 10 -6
25 98 17 118 -3 7 -25 19 -48 26 -24 7 -48 16 -54 20 -5 5 -44 18 -85 31 -41
13 -76 24 -78 25 -1 1 9 26 23 56 32 70 31 85 -5 104 -47 24 -58 19 -86 -42z
m54 -433 c62 -21 117 -42 123 -45 8 -6 -51 -127 -113 -230 -9 -16 -24 -46 -33
-65 -22 -49 -25 -50 -177 -50 -138 0 -138 0 -66 138 40 75 90 172 112 215 21
42 39 77 40 77 0 0 52 -18 114 -40z m78 -246 c-3 -9 -6 -20 -6 -25 0 -6 -4 -7
-10 -4 -5 3 -10 -3 -10 -14 0 -12 -5 -21 -10 -21 -6 0 -4 15 5 36 8 20 15 38
15 40 0 2 5 4 11 4 6 0 8 -7 5 -16z m4 -98 c0 -27 -18 -37 -49 -29 -39 10 -28
53 14 53 30 0 35 -3 35 -24z m155 -66 c12 -19 -10 -39 -58 -55 -55 -19 -217
-35 -217 -23 0 5 -7 8 -15 8 -19 0 -19 4 0 40 15 29 16 29 113 32 53 2 114 4
135 4 21 1 40 -2 42 -6z"/>
            <path d="M3535 2885 c-5 -2 -49 -15 -97 -30 -91 -28 -99 -35 -72 -73 20 -29
34 -28 75 9 19 16 55 40 82 52 56 26 66 60 12 42z"/>
            <path d="M380 2416 c0 -31 5 -56 10 -56 6 0 10 22 10 49 0 28 -4 53 -10 56 -6
4 -10 -15 -10 -49z"/>
            <path d="M1815 1904 c-88 -45 -181 -93 -207 -105 -46 -21 -48 -23 -48 -63 0
-77 6 -78 137 -11 340 172 336 170 317 243 -9 33 -23 28 -199 -64z"/>
            <path d="M833 1397 c-69 -35 -77 -54 -44 -94 25 -30 41 -29 118 8 77 37 85 51
47 90 -34 36 -43 35 -121 -4z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#e8e9e7" stroke="none">
            <path d="M2321 3550 c-274 -5 -274 -5 -192 -57 45 -29 86 -53 92 -53 7 0 6 -6
-2 -17 -13 -15 -11 -16 21 -9 85 17 167 18 237 2 85 -19 86 -19 78 -6 -3 6 1
10 9 10 8 0 13 -6 11 -12 -3 -8 14 -19 48 -30 55 -18 144 -65 188 -99 15 -12
32 -20 38 -18 7 3 -6 18 -28 34 -46 35 -58 86 -48 201 6 64 6 64 -86 62 -51
-1 -215 -5 -366 -8z"/>
            <path d="M1832 3418 c-100 -119 -112 -138 -92 -138 6 0 10 5 10 11 0 6 11 20
25 31 14 11 25 24 25 30 0 5 11 19 25 30 14 11 25 25 25 33 0 7 7 16 15 19 22
8 4 -112 -21 -149 -41 -58 -2 -58 78 0 34 25 68 45 75 45 7 0 13 5 13 10 0 12
-3 12 -33 1 -24 -9 -24 -8 -20 31 6 48 -6 168 -16 168 -4 0 -53 -55 -109 -122z"/>
            <path d="M3335 3410 c-4 -6 27 -10 85 -10 58 0 89 4 85 10 -3 6 -42 10 -85 10
-43 0 -82 -4 -85 -10z"/>
            <path d="M3086 3403 c-14 -14 -4 -34 27 -55 143 -97 137 -86 137 -241 -1 -103
-3 -137 -11 -124 -6 9 -14 15 -20 12 -5 -4 -9 -1 -9 5 0 6 -4 9 -8 6 -5 -3
-23 8 -41 24 -18 17 -36 30 -40 30 -24 0 -125 73 -174 125 -30 33 -59 56 -63
52 -5 -5 15 -31 44 -58 28 -28 52 -53 52 -57 0 -4 16 -27 35 -51 19 -24 35
-47 35 -50 0 -4 25 -24 56 -46 223 -159 223 -159 196 -206 -32 -55 -22 -109
20 -109 13 0 98 90 98 104 0 20 66 59 227 132 65 29 55 45 -12 18 -16 -6 -18
-5 -9 7 6 8 12 38 12 68 1 62 6 70 33 52 23 -13 38 -7 192 75 81 43 87 30 -75
161 -136 109 -177 133 -230 133 -39 0 -52 -18 -19 -27 48 -12 95 -232 58 -269
-4 -4 -7 -30 -7 -58 0 -130 -26 -156 -155 -156 -155 0 -163 14 -167 274 -2
161 0 210 10 213 6 3 12 9 12 14 0 10 -194 11 -204 2z m418 -553 c-23 -12 -56
-34 -75 -51 -34 -30 -34 -30 -52 -8 -22 27 -26 24 64 53 91 31 118 33 63 6z"/>
            <path d="M2048 3378 c-16 -5 -28 -14 -28 -20 0 -6 8 -7 18 -3 9 4 25 10 35 12
9 3 17 9 17 14 0 11 -7 10 -42 -3z"/>
            <path d="M1696 3251 c-11 -12 -16 -24 -12 -28 9 -9 39 22 34 37 -2 7 -10 4
-22 -9z"/>
            <path d="M1637 3175 c-20 -26 -24 -35 -13 -35 17 0 50 44 44 60 -1 5 -16 -6
-31 -25z"/>
            <path d="M1721 3156 c-7 -8 -30 -17 -52 -21 -21 -4 -41 -11 -45 -16 -10 -16
-184 -11 -190 6 -4 10 -20 15 -50 15 -49 0 -57 -11 -18 -28 29 -14 27 -25 -12
-104 -28 -56 -29 -58 -9 -58 18 0 127 -35 170 -55 17 -7 43 -17 59 -21 16 -4
31 -15 33 -25 7 -25 23 -24 23 1 0 26 69 197 86 215 8 7 14 17 14 22 0 4 9 19
20 33 33 42 5 77 -29 36z"/>
            <path d="M1495 3140 c-4 -6 13 -10 44 -10 28 0 51 5 51 10 0 6 -20 10 -44 10
-25 0 -48 -4 -51 -10z"/>
            <path d="M4102 3124 c3 -10 18 -64 34 -119 30 -109 31 -111 40 -102 8 8 -56
226 -69 234 -6 4 -8 -1 -5 -13z"/>
            <path d="M3991 3092 c-36 -19 -59 -38 -53 -40 14 -4 132 57 132 69 0 14 -11
10 -79 -29z"/>
            <path d="M3877 3034 c-16 -9 -26 -18 -23 -21 7 -8 66 17 66 28 0 12 -10 11
-43 -7z"/>
            <path d="M3770 2978 c-32 -18 -49 -33 -41 -36 7 -2 16 0 20 5 3 5 25 17 49 27
23 11 42 23 42 28 0 13 -12 9 -70 -24z"/>
            <path d="M4190 2793 c0 -5 5 -37 10 -73 20 -130 10 -695 -14 -840 -24 -142
-70 -314 -96 -362 -6 -10 -10 -23 -10 -29 0 -46 -211 -416 -277 -488 -10 -10
-25 -28 -34 -40 -50 -67 -235 -244 -327 -313 -72 -55 -285 -182 -322 -193 -14
-4 -32 -13 -40 -20 -8 -7 -31 -18 -50 -23 -19 -6 -60 -20 -90 -32 -256 -100
-649 -133 -950 -81 -108 19 -215 43 -220 50 -3 4 -25 12 -50 18 -128 31 -473
205 -520 262 -3 4 -29 23 -58 44 -73 52 -233 208 -304 297 -33 41 -67 84 -76
95 -105 126 -231 395 -292 620 -48 179 -83 461 -59 476 7 4 9 14 4 25 -13 32
-20 160 -20 357 1 186 -3 201 -27 102 -5 -22 -10 -50 -10 -62 0 -13 -4 -23 -8
-23 -5 0 -8 -8 -8 -17 0 -10 -2 -31 -5 -48 -22 -119 -27 -192 -26 -320 1 -44
2 -106 2 -137 1 -32 4 -58 7 -58 4 0 7 -15 8 -32 1 -18 3 -49 6 -68 2 -19 7
-60 11 -90 4 -30 15 -79 26 -109 10 -30 17 -56 15 -59 -5 -4 38 -135 51 -154
4 -7 9 -22 10 -32 2 -11 8 -25 14 -33 6 -7 8 -13 3 -13 -5 0 -4 -4 1 -8 13 -9
45 -79 45 -98 0 -8 7 -17 15 -20 8 -4 12 -10 10 -15 -3 -4 3 -17 13 -27 10
-10 16 -24 14 -30 -2 -7 3 -15 12 -18 9 -3 16 -14 16 -23 0 -10 16 -36 35 -58
19 -23 35 -46 35 -53 0 -6 5 -8 12 -4 7 4 8 3 4 -4 -14 -22 339 -412 373 -412
4 0 19 -10 33 -22 45 -41 64 -54 106 -78 23 -13 42 -27 42 -32 0 -4 7 -8 15
-8 8 0 15 -4 15 -9 0 -9 139 -81 158 -81 6 0 12 -4 12 -10 0 -5 9 -10 19 -10
10 0 21 -7 25 -15 3 -8 13 -15 21 -15 9 0 26 -5 38 -12 21 -12 34 -16 67 -22
8 -2 37 -13 64 -25 26 -12 53 -21 60 -20 6 1 27 -4 46 -12 19 -7 39 -12 44
-11 5 2 16 0 25 -4 9 -4 23 -9 31 -10 8 -2 35 -8 60 -14 154 -37 675 -45 675
-10 0 0 20 3 45 5 25 2 54 6 65 8 11 3 33 6 48 6 19 1 26 5 21 13 -5 7 -2 9 8
5 9 -3 19 -3 22 1 7 6 22 10 63 17 36 5 117 38 133 53 8 9 16 10 17 5 4 -11
103 33 115 51 4 6 8 8 8 4 0 -6 74 26 125 54 11 6 30 17 42 23 13 7 20 15 16
18 -3 4 0 7 9 7 15 0 111 59 116 72 2 5 8 8 14 8 12 0 89 61 142 112 21 21 42
38 47 38 4 0 10 9 14 20 3 11 10 19 15 17 8 -2 120 109 120 120 0 3 10 16 23
28 31 33 47 53 47 62 0 5 9 14 20 21 11 7 20 16 20 22 0 5 11 21 25 36 14 15
25 33 25 40 0 8 4 14 8 14 9 0 64 91 62 104 -1 5 3 12 8 15 14 9 62 104 62
123 0 10 5 18 10 18 6 0 10 6 10 13 0 6 11 33 25 59 14 26 25 56 25 67 0 11 5
23 10 26 6 3 10 17 10 30 0 13 5 27 10 30 6 4 10 12 9 18 -1 7 0 19 3 27 17
49 38 130 43 160 2 19 7 46 10 60 9 41 16 102 17 148 0 23 5 45 10 48 5 3 4
11 -2 19 -7 9 -8 15 -1 19 6 4 7 15 2 29 -5 12 -5 31 -2 42 6 16 7 100 2 100
-1 0 -3 41 -6 90 -2 50 -7 108 -11 130 -4 22 -8 49 -10 60 -1 11 -3 22 -4 25
-1 3 -2 9 -1 14 1 4 -3 13 -8 20 -6 7 -10 21 -9 31 1 31 -19 116 -36 158 -15
34 -26 47 -26 30z"/>
            <path d="M1176 2628 c-46 -95 -163 -317 -182 -345 -8 -12 -14 -25 -14 -28 0
-8 304 -6 317 3 7 4 19 23 28 42 9 19 24 49 34 65 85 142 131 243 114 250 -54
22 -240 85 -250 85 -6 0 -27 -33 -47 -72z"/>
            <path d="M1650 2375 c0 -9 -11 -38 -25 -65 -33 -65 -34 -62 34 -58 59 3 59 3
88 -39 27 -41 43 -53 43 -33 0 6 -9 19 -20 30 -11 11 -20 23 -20 28 0 4 -14
24 -30 46 -16 21 -34 54 -40 72 -12 35 -30 46 -30 19z"/>
            <path d="M1840 2112 c0 -4 18 -19 40 -32 22 -14 40 -28 40 -31 1 -25 -492 -90
-506 -67 -7 11 -24 10 -24 -2 0 -5 5 -10 11 -10 14 0 99 -91 99 -106 0 -7 7
-14 15 -18 8 -3 20 -26 26 -51 11 -42 23 -57 31 -36 2 5 26 20 53 34 28 13
122 61 210 106 186 95 161 86 168 59 9 -34 18 -15 11 22 -5 22 -17 38 -40 52
-19 11 -34 23 -34 27 0 4 -18 16 -40 27 -22 10 -40 22 -40 27 0 4 -4 7 -10 7
-5 0 -10 -4 -10 -8z"/>
            <path d="M424 2064 c19 -103 87 -281 113 -295 32 -17 158 -159 168 -188 4 -12
0 -34 -9 -53 -24 -46 -20 -63 27 -134 41 -62 57 -77 57 -51 0 6 32 27 70 46
78 37 78 38 51 73 -99 125 -82 344 35 459 24 24 44 47 44 51 0 4 -117 8 -259
8 -260 0 -260 0 -272 55 -14 65 -36 89 -25 29z"/>
            <path d="M1054 2026 c-32 -14 -54 -36 -36 -36 20 0 82 31 82 40 0 13 -8 12
-46 -4z"/>
            <path d="M1280 2033 c0 -8 55 -33 73 -33 18 0 4 19 -17 24 -13 3 -31 9 -40 12
-9 3 -16 2 -16 -3z"/>
            <path d="M1962 1879 c-18 -12 -30 -24 -27 -27 7 -8 75 28 75 40 0 13 -12 9
-48 -13z"/>
            <path d="M1857 1824 c-16 -9 -26 -18 -23 -21 7 -8 66 17 66 28 0 12 -10 11
-43 -7z"/>
            <path d="M1660 1722 c-46 -25 -79 -47 -72 -50 14 -4 172 77 172 89 0 14 -9 10
-100 -39z"/>
            <path d="M959 1376 c10 -12 8 -17 -9 -26 -26 -14 -20 -24 9 -15 26 8 30 55 5
55 -13 0 -14 -3 -5 -14z"/>
            <path d="M790 1312 c0 -30 29 -45 60 -32 17 7 30 16 30 21 0 12 -4 11 -35 -3
-22 -10 -27 -9 -40 8 -9 12 -14 14 -15 6z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#2a4680" stroke="none">
            <path d="M2910 3170 c0 -6 4 -18 9 -28 35 -66 54 -124 55 -167 1 -27 5 -56 9
-63 5 -7 2 -14 -7 -18 -18 -7 -22 -34 -5 -34 7 0 0 -11 -15 -25 -14 -13 -26
-32 -26 -42 0 -22 -42 -63 -63 -63 -9 0 -22 -9 -29 -19 -8 -11 -26 -23 -41
-26 -15 -4 -27 -13 -27 -21 0 -8 -9 -14 -20 -14 -14 0 -20 -7 -20 -24 0 -19
-7 -26 -30 -31 -17 -4 -30 -13 -30 -22 0 -12 -7 -14 -35 -9 -28 6 -35 4 -35
-9 0 -8 8 -15 18 -15 14 0 15 -2 2 -10 -10 -6 -16 -6 -18 0 -8 21 -30 8 -35
-20 -5 -21 -12 -30 -27 -30 -11 0 -20 -7 -20 -15 0 -9 -9 -15 -25 -15 -15 0
-25 -6 -25 -14 0 -8 -9 -18 -20 -21 -11 -3 -20 -10 -20 -15 0 -4 -11 -11 -25
-14 -14 -4 -25 -13 -25 -21 0 -8 -7 -15 -16 -15 -9 0 -30 -13 -45 -29 -15 -17
-44 -33 -63 -36 -19 -4 -37 -13 -40 -21 -3 -8 -12 -14 -20 -14 -7 0 -16 -7
-20 -15 -3 -8 -15 -15 -26 -15 -11 0 -20 -4 -20 -9 0 -12 -39 -26 -62 -22 -10
1 -18 -4 -18 -13 0 -8 8 -17 18 -19 18 -5 -20 -11 -47 -7 -8 1 -17 -3 -20 -8
-12 -19 -51 -24 -51 -8 0 9 -7 16 -15 16 -8 0 -15 -4 -15 -9 0 -4 -11 -6 -25
-3 -54 10 -21 -49 55 -98 27 -18 50 -33 50 -34 0 -14 59 -276 64 -284 3 -5 15
-59 26 -118 88 -462 144 -573 288 -574 40 0 52 4 52 15 0 8 -7 15 -16 15 -10
0 -14 6 -10 18 3 9 10 49 16 87 6 39 20 111 31 160 10 50 32 167 49 260 77
450 79 454 142 485 134 65 293 201 333 286 9 19 20 34 25 34 6 0 10 5 10 11 0
6 14 41 31 77 38 82 59 175 59 265 0 53 3 67 15 67 8 0 15 9 15 20 0 12 -7 20
-17 20 -14 0 -15 2 -5 9 17 11 5 61 -15 61 -11 0 -13 7 -9 24 13 51 -116 236
-165 236 -5 0 -9 -5 -9 -10z"/>
            <path d="M3110 2780 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
            <path d="M3120 2675 c0 -33 4 -45 15 -45 11 0 15 12 15 45 0 33 -4 45 -15 45
-11 0 -15 -12 -15 -45z"/>
            <path d="M3110 2575 c0 -16 6 -25 15 -25 9 0 15 9 15 25 0 16 -6 25 -15 25 -9
0 -15 -9 -15 -25z"/>
            <path d="M3086 2468 c-9 -34 -8 -38 9 -38 8 0 15 3 15 8 0 4 3 17 6 30 5 16 2
22 -9 22 -8 0 -17 -10 -21 -22z"/>
            <path d="M3046 2364 c-9 -24 -7 -34 8 -34 13 0 26 20 26 41 0 15 -27 10 -34
-7z"/>
            <path d="M2966 2238 c-19 -26 -21 -48 -5 -48 14 0 48 41 49 58 0 20 -28 14
-44 -10z"/>
            <path d="M2903 2165 c-3 -9 -3 -18 0 -22 10 -10 37 6 37 22 0 20 -29 20 -37 0z"/>
            <path d="M2830 2100 c-18 -16 -36 -30 -41 -30 -5 0 -9 -7 -9 -15 0 -8 7 -15
15 -15 8 0 15 5 15 10 0 6 5 10 10 10 18 0 70 43 70 57 0 22 -26 15 -60 -17z"/>
            <path d="M2708 2029 c-21 -12 -24 -39 -5 -39 17 1 47 25 47 39 0 14 -19 14
-42 0z"/>
            <path d="M2670 1910 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
            <path d="M2646 1789 c-9 -44 -8 -49 8 -49 11 0 26 40 26 71 0 24 -28 5 -34
-22z"/>
            <path d="M2556 1349 c-9 -44 -8 -49 8 -49 11 0 26 40 26 71 0 24 -28 5 -34
-22z"/>
            <path d="M2506 1154 c-9 -24 -7 -34 8 -34 13 0 26 20 26 41 0 15 -27 10 -34
-7z"/>
            <path d="M2452 1088 c-16 -16 -15 -38 1 -38 17 0 37 20 37 37 0 16 -22 17 -38
1z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#c6c6c5" stroke="none">
            <path d="M1789 3358 c-122 -146 -136 -168 -104 -168 27 0 112 88 139 144 45
92 30 102 -35 24z"/>
            <path d="M3510 3401 c0 -5 4 -11 10 -13 32 -11 36 -405 4 -438 -11 -11 -39
-15 -119 -15 -104 1 -123 -5 -86 -25 28 -14 201 -12 231 3 51 26 53 421 3 471
-26 27 -43 33 -43 17z"/>
            <path d="M2058 3378 c-27 -9 -39 -28 -18 -28 16 0 60 23 60 32 0 10 -7 9 -42
-4z"/>
            <path d="M2630 3383 c0 -10 43 -33 60 -33 17 0 11 17 -7 23 -10 2 -26 8 -35
12 -10 4 -18 3 -18 -2z"/>
            <path d="M1920 3300 c-24 -20 -30 -30 -19 -30 22 0 63 33 58 48 -2 6 -19 -1
-39 -18z"/>
            <path d="M2750 3324 c0 -11 45 -43 60 -43 10 0 5 8 -13 24 -27 24 -47 32 -47
19z"/>
            <path d="M3075 2963 c2 -10 11 -36 20 -58 21 -47 57 -277 46 -285 -11 -7 -35
-169 -27 -177 18 -19 95 45 184 152 25 31 29 55 7 55 -8 0 -15 -4 -15 -8 0 -4
-16 -25 -35 -45 -48 -51 -58 -24 -15 40 60 88 48 122 -73 217 -20 16 -37 35
-37 42 0 24 -33 84 -46 84 -7 0 -11 -8 -9 -17z"/>
            <path d="M1625 2889 c-6 -28 -1 -48 10 -37 3 2 8 19 11 36 8 42 -12 43 -21 1z"/>
            <path d="M1625 2473 c8 -31 13 -39 20 -31 9 9 -6 58 -17 58 -6 0 -7 -11 -3
-27z"/>
            <path d="M1690 2305 c0 -14 65 -94 69 -84 2 7 -1 15 -7 17 -7 2 -12 9 -12 16
0 7 -8 23 -19 34 -17 21 -31 28 -31 17z"/>
            <path d="M4170 2110 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
            <path d="M1930 2050 c0 -12 40 -33 48 -25 4 4 -2 13 -12 21 -23 16 -36 18 -36
4z"/>
            <path d="M4126 1938 c-9 -34 -8 -38 9 -38 8 0 15 3 15 8 0 4 3 17 6 30 5 16 2
22 -9 22 -8 0 -17 -10 -21 -22z"/>
            <path d="M2725 1941 c-32 -6 -49 -61 -27 -83 24 -24 82 30 82 77 0 16 -6 17
-55 6z"/>
            <path d="M1460 1921 c0 -17 28 -43 41 -39 5 2 -1 14 -15 27 -15 14 -26 19 -26
12z"/>
            <path d="M4056 1744 c-9 -24 -7 -34 8 -34 13 0 26 20 26 41 0 15 -27 10 -34
-7z"/>
            <path d="M550 1691 c0 -5 15 -58 34 -118 31 -98 48 -140 94 -227 8 -16 27 -52
41 -80 124 -242 398 -522 651 -665 285 -161 522 -230 850 -249 238 -14 466 18
695 98 50 17 105 36 123 42 17 6 32 15 32 20 0 4 9 8 19 8 11 0 21 3 23 8 2 4
39 25 82 47 44 22 103 57 130 77 28 20 75 54 105 75 128 90 380 369 428 473 3
8 18 33 32 55 28 42 30 45 84 165 106 232 113 369 9 167 -125 -242 -472 -636
-480 -546 -2 19 -8 38 -13 44 -5 5 -9 17 -9 27 0 10 -8 22 -17 25 -10 4 -21
11 -24 16 -10 14 -39 1 -39 -18 0 -8 5 -15 11 -15 7 0 8 -11 4 -30 -4 -18 -3
-30 4 -30 5 0 13 -10 17 -22 4 -13 11 -26 17 -29 36 -24 -185 -174 -375 -255
-46 -19 -101 -44 -123 -54 -181 -86 -629 -136 -885 -100 -485 68 -848 262
-1192 637 -26 28 -49 49 -51 46 -6 -5 -99 123 -126 172 -15 28 -18 48 -14 95
5 50 3 60 -10 60 -10 0 -17 -11 -19 -32 -3 -31 -5 -29 -43 45 -34 66 -65 99
-65 68z"/>
            <path d="M1753 1546 c-26 -12 -31 -36 -7 -36 24 0 54 19 54 35 0 17 -15 17
-47 1z"/>
            <path d="M3093 875 c-3 -9 -3 -18 0 -22 10 -10 37 6 37 22 0 20 -29 20 -37 0z"/>
            <path d="M3023 785 c-3 -9 -3 -18 0 -22 10 -10 37 6 37 22 0 20 -29 20 -37 0z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#d7d7d6" stroke="none">
            <path d="M1864 3443 c-40 -37 -84 -92 -50 -63 16 14 27 21 25 15 -36 -77 -60
-113 -101 -151 -47 -44 -75 -54 -48 -17 7 10 2 6 -11 -9 -13 -15 -31 -37 -39
-49 -14 -19 -27 -22 -110 -26 -118 -6 -129 -8 -109 -29 19 -18 190 -24 211 -7
7 6 30 14 50 18 21 4 42 13 49 21 22 27 30 14 10 -15 -23 -32 -26 -41 -11 -41
6 0 10 7 10 15 0 8 3 15 8 15 16 0 116 124 104 128 -10 3 -10 9 2 27 16 24 41
156 34 176 -2 6 -12 3 -24 -8z"/>
            <path d="M2300 3430 c0 -6 32 -10 75 -10 43 0 75 4 75 10 0 6 -32 10 -75 10
-43 0 -75 -4 -75 -10z"/>
            <path d="M3265 3391 c3 -5 1 -12 -5 -16 -13 -8 -13 -132 0 -140 6 -4 10 20 10
64 0 38 3 76 6 85 3 9 1 16 -6 16 -6 0 -9 -4 -5 -9z"/>
            <path d="M3523 3389 c2 -8 10 -13 17 -11 8 2 10 -3 7 -12 -4 -9 -1 -15 5 -13
42 8 30 -409 -12 -430 -29 -15 -204 -18 -196 -4 4 5 -4 7 -16 3 -14 -3 -19 -2
-14 6 4 7 3 12 -3 12 -5 0 -17 7 -26 16 -9 9 -19 14 -22 10 -4 -3 11 -22 33
-41 39 -35 39 -35 139 -35 140 0 165 24 165 155 0 28 4 55 8 60 19 22 1 228
-22 256 -24 30 -72 50 -63 28z"/>
            <path d="M2685 3360 c-7 -11 51 -43 67 -38 6 2 -5 14 -25 26 -20 11 -39 17
-42 12z"/>
            <path d="M1692 3052 c-23 -47 -24 -52 -13 -52 6 0 13 11 17 25 3 14 10 25 15
25 5 0 9 7 9 15 0 24 -14 17 -28 -13z"/>
            <path d="M3045 3020 c14 -27 30 -50 36 -50 5 0 8 -4 4 -9 -8 -13 16 -62 27
-55 5 3 6 -5 3 -19 -4 -15 -2 -28 6 -32 8 -6 10 -4 4 6 -7 11 -5 11 11 -1 125
-95 152 -141 110 -190 -19 -21 -68 -113 -58 -106 5 3 16 -4 25 -15 18 -21 40
-18 31 5 -3 7 3 19 12 27 11 9 15 9 10 2 -5 -9 -2 -11 9 -7 9 4 14 13 11 20
-3 7 0 16 6 20 7 5 8 2 3 -6 -6 -9 -4 -11 4 -6 7 5 10 15 6 24 -3 9 -3 13 1 9
9 -9 54 32 54 50 0 7 5 13 10 13 6 0 10 8 10 18 0 12 -7 8 -26 -15 -50 -64
-82 -12 -38 62 27 47 27 47 7 64 -41 33 -153 117 -206 155 -30 22 -60 50 -66
63 -6 12 -15 23 -21 23 -6 0 1 -22 15 -50z"/>
            <path d="M1659 2975 c-1 -3 -2 -8 -3 -12 -1 -5 -7 -18 -14 -30 -11 -20 0 -45
11 -26 8 14 19 73 13 73 -3 0 -7 -2 -7 -5z"/>
            <path d="M1670 2344 c0 -3 8 -19 17 -35 17 -29 33 -38 33 -19 0 6 -4 10 -9 10
-5 0 -12 11 -15 25 -5 21 -26 36 -26 19z"/>
            <path d="M4196 2255 c-4 -42 -4 -81 0 -87 5 -7 3 -8 -6 -3 -10 6 -12 0 -7 -29
4 -25 2 -34 -5 -30 -7 5 -9 -2 -6 -19 3 -16 1 -24 -5 -20 -6 4 -8 -4 -5 -20 3
-16 1 -24 -5 -20 -6 4 -8 -4 -5 -20 3 -16 1 -24 -5 -20 -6 4 -8 -4 -5 -20 3
-16 1 -24 -5 -20 -6 4 -8 -2 -5 -15 4 -14 2 -20 -4 -16 -7 5 -9 -2 -6 -19 3
-15 2 -24 -3 -22 -11 7 -32 -62 -23 -76 4 -7 3 -9 -4 -5 -13 8 -26 -29 -16
-45 5 -8 3 -9 -6 -4 -10 6 -12 4 -7 -9 4 -9 3 -14 -2 -11 -12 7 -24 -31 -15
-46 4 -8 3 -9 -4 -5 -7 4 -12 2 -12 -3 0 -6 4 -11 9 -11 16 0 -43 -160 -122
-330 -6 -14 -23 -43 -38 -65 -14 -22 -29 -47 -32 -55 -48 -104 -300 -383 -428
-473 -30 -21 -77 -55 -105 -75 -27 -20 -86 -55 -130 -77 -43 -22 -80 -43 -82
-47 -2 -5 -12 -8 -23 -8 -10 0 -19 -4 -19 -8 0 -5 -15 -14 -32 -20 -18 -6 -73
-25 -123 -42 -142 -50 -258 -75 -440 -96 -66 -7 -297 -2 -390 10 -92 11 -255
45 -270 56 -5 4 -17 7 -25 8 -42 1 -269 100 -400 173 -253 143 -527 423 -651
665 -14 28 -33 64 -41 80 -44 83 -62 127 -89 210 -16 52 -26 99 -22 110 5 13
3 15 -7 9 -9 -6 -11 -4 -5 4 8 14 -22 101 -35 101 -13 0 -88 239 -107 343 -31
169 -42 113 -23 -118 28 -338 180 -752 352 -960 9 -11 43 -54 76 -95 71 -89
231 -245 304 -297 29 -21 55 -40 58 -44 47 -57 392 -231 520 -262 25 -6 47
-14 50 -18 5 -7 178 -47 230 -54 14 -2 45 -6 70 -10 259 -37 663 7 890 95 30
12 71 26 90 32 19 5 42 16 50 23 8 7 26 16 40 20 37 11 250 138 322 193 92 69
277 246 327 313 9 12 24 30 34 40 66 72 277 442 277 488 0 6 4 19 10 29 26 46
75 232 96 362 22 132 32 444 15 454 -4 3 -11 -28 -15 -69z"/>
            <path d="M1830 2132 c0 -14 39 -46 47 -39 3 4 -2 16 -12 27 -19 21 -35 26 -35
12z"/>
            <path d="M968 1970 c-25 -22 -29 -29 -15 -30 9 0 17 6 17 14 0 8 11 17 25 20
14 4 25 11 25 16 0 18 -19 11 -52 -20z"/>
            <path d="M1430 1950 c0 -12 24 -40 34 -40 15 0 4 30 -14 40 -14 8 -20 8 -20 0z"/>
            <path d="M887 1890 c-19 -28 -21 -40 -7 -40 12 0 32 38 28 52 -3 7 -12 1 -21
-12z"/>
            <path d="M1510 1854 c0 -15 22 -44 31 -39 11 7 -7 45 -21 45 -6 0 -10 -3 -10
-6z"/>
            <path d="M827 1713 c-11 -10 -8 -91 3 -98 6 -4 10 15 10 49 0 57 -1 61 -13 49z"/>
            <path d="M620 1704 c0 -20 44 -113 52 -108 4 3 8 -11 8 -31 0 -22 -4 -34 -10
-30 -13 8 -13 -7 1 -42 10 -28 10 -28 29 12 22 46 24 72 7 101 -18 31 -87 109
-87 98z"/>
            <path d="M860 1503 c0 -15 55 -84 58 -74 4 13 -38 81 -50 81 -5 0 -8 -3 -8 -7z"/>
            <path d="M3153 793 c-29 -14 -44 -33 -28 -33 10 0 85 40 85 45 0 9 -25 4 -57
-12z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#375b8c" stroke="none">
            <path d="M2704 3308 c-4 -13 -2 -24 4 -26 5 -1 8 -11 6 -20 -3 -9 8 -30 24
-47 56 -59 66 -105 22 -105 -12 0 -20 -7 -20 -17 -1 -22 -23 -53 -39 -53 -7 0
-22 -9 -34 -20 -12 -12 -32 -23 -44 -26 -12 -3 -25 -15 -28 -25 -4 -10 -12
-19 -18 -19 -7 0 -23 -11 -37 -24 -65 -63 -87 -82 -113 -95 -15 -8 -27 -18
-27 -23 0 -4 -5 -8 -11 -8 -6 0 -28 -12 -48 -27 -48 -37 -77 -53 -96 -53 -8 0
-15 -7 -15 -15 0 -8 -7 -15 -15 -15 -9 0 -25 -9 -37 -20 -12 -11 -30 -20 -40
-20 -10 0 -18 -4 -18 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -5 -15 -11 0 -13 -41
-48 -56 -49 -6 0 -17 -6 -23 -14 -6 -7 -22 -11 -36 -8 -21 4 -25 1 -25 -21 0
-18 -7 -28 -22 -33 -13 -3 -30 -12 -38 -19 -8 -7 -40 -21 -70 -31 -30 -10 -58
-23 -62 -28 -3 -5 -27 -9 -52 -9 -51 1 -50 2 -37 -45 27 -92 146 -232 264
-309 45 -29 67 -50 67 -63 0 -11 5 -20 10 -20 30 0 -2 48 -54 83 -65 43 -93
82 -50 70 16 -4 41 -8 56 -8 14 0 24 -3 21 -7 -2 -5 3 -8 11 -8 15 0 21 12 17
33 -1 5 3 6 8 3 15 -10 71 6 71 20 0 7 9 18 20 24 14 7 20 7 20 0 0 -5 5 -10
11 -10 6 0 9 9 6 20 -4 17 -2 20 13 15 13 -4 20 0 25 15 3 11 13 20 21 20 8 0
17 7 21 16 4 11 14 13 37 9 23 -5 27 -4 16 4 -13 9 -13 11 2 11 10 0 31 13 46
30 16 17 39 30 50 30 14 0 20 5 16 14 -3 9 3 17 17 21 13 4 29 14 37 22 8 8
21 12 29 9 11 -5 13 -1 8 15 -5 14 -3 19 3 15 15 -9 42 4 42 21 0 9 6 13 14
10 18 -7 47 29 40 50 -5 14 -4 15 4 4 12 -16 52 -6 52 15 0 8 9 14 20 14 11 0
20 6 20 14 0 8 12 17 26 20 27 7 81 63 76 79 -2 5 6 7 18 4 11 -3 18 -1 14 4
-3 5 0 9 6 9 7 0 19 9 28 21 11 12 31 21 54 22 20 1 34 5 32 9 -2 4 0 9 4 12
5 3 8 17 6 32 -2 19 6 34 28 54 27 25 28 29 12 38 -16 9 -16 10 4 16 19 6 20
10 10 29 -6 12 -12 44 -13 72 -2 43 -21 102 -56 167 -7 13 -5 16 6 12 51 -20
-37 70 -126 129 -65 43 -95 47 -105 15z"/>
            <path d="M3120 2732 c0 -19 5 -29 15 -29 10 0 14 6 10 14 -3 8 -5 21 -5 29 0
8 -4 14 -10 14 -5 0 -10 -13 -10 -28z"/>
            <path d="M3120 2623 c0 -30 24 -26 28 4 2 12 -3 20 -13 20 -9 0 -15 -9 -15
-24z"/>
            <path d="M3113 2544 c2 -13 0 -21 -4 -18 -5 3 -9 -2 -9 -10 0 -9 7 -16 15 -16
8 0 15 9 15 19 0 11 2 26 5 34 4 8 0 14 -10 14 -12 0 -15 -7 -12 -23z"/>
            <path d="M3026 2324 c-9 -24 -7 -34 8 -34 13 0 26 20 26 41 0 15 -27 10 -34
-7z"/>
            <path d="M2753 2053 c-15 -5 -18 -33 -4 -33 21 0 41 13 41 26 0 14 -14 17 -37
7z"/>
            <path d="M2050 1746 c0 -8 5 -18 10 -21 6 -3 10 -18 10 -33 0 -37 11 -72 21
-72 15 0 -14 124 -32 135 -5 4 -9 -1 -9 -9z"/>
            <path d="M2630 1698 c0 -10 7 -18 15 -18 8 0 15 8 15 18 0 10 -7 19 -15 19 -8
0 -15 -9 -15 -19z"/>
            <path d="M2523 1170 c3 -13 1 -18 -6 -13 -7 4 -8 0 -4 -11 4 -9 3 -15 -1 -12
-14 8 -45 -35 -34 -46 13 -13 47 17 61 55 15 38 14 47 -5 47 -11 0 -15 -6 -11
-20z"/>
            <path d="M2190 1171 c0 -14 34 -71 43 -71 12 0 8 13 -13 48 -20 32 -30 40 -30
23z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#72b99b" stroke="none">
            <path d="M3270 3389 c-10 -18 -9 -20 7 -17 10 2 19 11 21 21 5 24 -15 21 -28
-4z"/>
            <path d="M3500 3396 c0 -8 5 -18 10 -21 6 -3 10 -35 10 -71 0 -50 3 -64 15
-64 19 0 21 103 2 139 -14 29 -37 39 -37 17z"/>
            <path d="M3515 2959 c-4 -11 -4 -23 -1 -26 8 -9 36 17 36 33 0 22 -28 16 -35
-7z"/>
            <path d="M3320 2941 c0 -15 28 -21 96 -21 50 0 64 3 64 15 0 12 -16 15 -80 15
-44 0 -80 -4 -80 -9z"/>
            <path d="M4110 2851 c0 -5 -11 -12 -25 -15 -14 -4 -25 -13 -25 -21 0 -19 24
-19 44 0 8 8 19 15 25 15 6 0 11 7 11 15 0 8 -7 15 -15 15 -8 0 -15 -4 -15 -9z"/>
            <path d="M3898 2744 c-73 -37 -114 -74 -81 -74 19 0 193 97 193 108 0 20 -20
14 -112 -34z"/>
            <path d="M3693 2643 c-15 -5 -18 -33 -4 -33 21 0 41 13 41 26 0 14 -14 17 -37
7z"/>
            <path d="M4165 2489 c-22 -12 -67 -38 -100 -60 -79 -50 -123 -65 -224 -73
-105 -9 -105 -9 -126 -57 -10 -21 -21 -39 -26 -39 -4 0 -11 -15 -14 -32 -10
-43 -47 -128 -56 -128 -4 0 -10 -14 -14 -30 -8 -36 -34 -90 -52 -108 -35 -35
111 -252 169 -252 27 0 22 25 -8 45 -19 12 -23 19 -14 25 7 4 9 16 6 27 -4 12
-1 25 9 33 24 20 45 71 39 95 -4 17 0 24 15 28 13 3 21 13 21 26 0 12 4 21 9
21 6 0 13 20 17 45 3 25 10 45 15 45 5 0 9 9 9 20 0 11 6 20 13 20 6 0 20 27
30 60 11 39 23 60 32 60 9 0 18 13 22 30 3 17 11 30 18 30 8 0 15 4 17 8 2 4
19 17 39 27 93 51 209 129 209 142 0 17 -7 16 -55 -8z"/>
            <path d="M3472 2430 c-16 -26 -15 -40 3 -40 14 0 35 29 35 49 0 18 -24 12 -38
-9z"/>
            <path d="M3960 1883 c-54 -19 -110 -59 -110 -79 0 -22 24 -17 59 15 31 28 101
51 151 51 11 0 20 7 20 15 0 20 -59 19 -120 -2z"/>
            <path d="M4096 1854 c-6 -24 -2 -43 8 -33 11 10 17 59 8 59 -5 0 -13 -12 -16
-26z"/>
            <path d="M3793 1753 c-24 -16 -31 -43 -13 -43 19 0 60 32 60 46 0 18 -19 17
-47 -3z"/>
            <path d="M1980 1630 c-52 -27 -124 -63 -159 -81 -93 -45 -214 -169 -166 -169
8 0 15 5 15 10 0 18 30 11 30 -7 1 -44 62 -103 105 -103 11 0 89 35 173 78
163 82 168 88 140 172 -3 8 -11 40 -18 70 -20 86 -15 85 -120 30z"/>
            <path d="M3956 1554 c-15 -40 4 -48 22 -9 9 20 9 25 -2 25 -8 0 -17 -7 -20
-16z"/>
            <path d="M3042 1514 c4 -20 34 -41 45 -31 10 11 -11 41 -31 45 -12 3 -17 -2
-14 -14z"/>
            <path d="M3920 1485 c0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 -4 -15
-10 -15 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -24
13 -17 36 18 11 17 29 44 38 60 16 24 17 27 2 27 -9 0 -16 -7 -16 -15z"/>
            <path d="M3152 1424 c2 -11 11 -20 22 -22 13 -3 17 1 14 14 -2 11 -11 20 -22
22 -13 3 -17 -1 -14 -14z"/>
            <path d="M1538 1359 c-21 -12 -24 -39 -5 -39 17 1 47 25 47 39 0 14 -19 14
-42 0z"/>
            <path d="M2882 1304 c2 -11 11 -20 22 -22 13 -3 17 1 14 14 -2 11 -11 20 -22
22 -13 3 -17 -1 -14 -14z"/>
            <path d="M1393 1283 c-15 -5 -18 -33 -4 -33 21 0 41 13 41 26 0 14 -14 17 -37
7z"/>
            <path d="M3380 1259 c0 -7 8 -19 18 -28 19 -18 51 -67 59 -93 9 -30 36 -22 29
8 -8 32 -35 74 -47 74 -5 0 -9 5 -9 11 0 14 -25 39 -39 39 -6 0 -11 -5 -11
-11z"/>
            <path d="M1313 1243 c-31 -15 -45 -43 -23 -43 21 0 80 35 80 47 0 17 -17 16
-57 -4z"/>
            <path d="M3682 1188 c-13 -13 -16 -38 -4 -38 14 1 44 50 29 50 -7 0 -18 -5
-25 -12z"/>
            <path d="M3100 1138 c1 -18 25 -48 39 -48 14 0 14 15 1 41 -12 21 -40 26 -40
7z"/>
            <path d="M1093 1128 c-23 -11 -32 -38 -13 -38 20 0 60 24 60 37 0 15 -16 16
-47 1z"/>
            <path d="M3591 1096 c-13 -14 -21 -30 -19 -36 2 -6 19 5 38 25 22 23 29 35 19
35 -9 0 -26 -11 -38 -24z"/>
            <path d="M2210 1085 c0 -20 -5 -25 -25 -25 -13 0 -29 -8 -34 -17 -6 -10 -11
-12 -11 -5 0 20 -27 14 -33 -8 -3 -13 -10 -18 -21 -14 -15 6 -46 -16 -46 -33
0 -5 -22 -16 -50 -26 -27 -10 -50 -24 -50 -31 0 -8 -16 -17 -35 -20 -19 -4
-35 -11 -35 -15 0 -5 -11 -12 -25 -15 -14 -4 -25 -11 -25 -16 0 -5 -16 -16
-36 -25 -19 -8 -47 -28 -62 -45 -15 -16 -30 -30 -35 -30 -5 0 -16 -7 -24 -16
-16 -16 -49 -15 -100 1 -43 14 -23 -12 25 -33 261 -111 779 -179 824 -108 6 8
8 8 8 0 0 -26 228 -13 308 17 29 11 27 52 -3 57 -56 8 -105 26 -105 38 0 8 -6
14 -14 14 -8 0 -17 9 -21 19 -3 11 -12 22 -20 25 -8 3 -20 26 -26 51 -6 25
-15 45 -19 45 -4 0 -15 28 -25 63 -10 34 -24 67 -31 73 -7 6 -11 20 -7 32 4
16 1 22 -11 22 -9 0 -16 -4 -16 -9 0 -35 -117 -12 -165 33 -35 33 -55 33 -55
1z"/>
            <path d="M1190 935 c0 -2 27 -23 60 -45 43 -29 60 -36 60 -25 0 8 -7 15 -15
15 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 6 -7 10 -15 10 -8
0 -15 5 -15 10 0 6 -7 10 -15 10 -8 0 -15 -2 -15 -5z"/>
            <path d="M3103 905 c-13 -19 -23 -39 -23 -45 0 -14 27 -13 36 2 5 7 3 8 -7 3
-8 -5 -2 3 14 18 28 27 36 57 15 57 -7 0 -23 -16 -35 -35z"/>
            <path d="M1350 832 c0 -5 11 -14 25 -20 20 -9 25 -9 25 2 0 8 -7 17 -16 20
-22 8 -34 8 -34 -2z"/>
            <path d="M1450 782 c0 -5 16 -16 35 -25 27 -13 35 -14 35 -3 0 7 -11 18 -25
24 -30 14 -45 15 -45 4z"/>
            <path d="M3010 776 c0 -8 -7 -16 -15 -20 -8 -3 -15 -12 -15 -21 0 -22 31 -18
37 5 3 11 9 20 12 20 4 0 8 7 8 15 0 8 -6 15 -14 15 -7 0 -13 -6 -13 -14z"/>
            <path d="M2848 673 c-10 -2 -18 -9 -18 -15 0 -6 10 -8 23 -4 12 3 25 6 30 6 4
0 7 5 7 10 0 10 -12 11 -42 3z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#fbda98" stroke="none">
            <path d="M3975 3080 c-61 -32 -207 -107 -325 -167 -214 -108 -214 -108 -282
-197 -68 -89 -77 -106 -58 -106 6 0 10 4 10 9 0 5 18 28 40 52 22 24 40 48 41
54 1 20 70 68 81 57 14 -14 47 -165 47 -219 1 -46 24 -61 36 -24 8 28 67 62
350 206 138 69 254 130 258 134 15 15 -57 261 -76 260 -7 0 -62 -27 -122 -59z"/>
            <path d="M1230 2031 c0 -9 71 -23 79 -15 9 9 -23 24 -51 24 -16 0 -28 -4 -28
-9z"/>
            <path d="M1017 1999 c-22 -11 -36 -23 -33 -26 8 -8 86 27 86 39 0 12 -11 9
-53 -13z"/>
            <path d="M920 1925 c-13 -14 -21 -28 -18 -31 7 -6 58 36 58 48 0 15 -16 8 -40
-17z"/>
            <path d="M1865 1824 c-77 -41 -175 -92 -217 -112 -52 -25 -79 -44 -82 -57 -2
-11 -9 -45 -16 -76 -19 -98 -138 -228 -228 -251 -23 -6 -45 -14 -48 -19 -6
-11 -144 -12 -144 -1 0 4 -28 16 -61 26 -33 10 -63 25 -66 32 -7 18 -33 18
-33 1 0 -7 -31 -28 -70 -47 -87 -42 -88 -36 20 -149 99 -104 45 -119 619 176
159 82 227 139 205 175 -4 6 1 8 14 4 12 -4 23 -2 28 5 5 8 3 9 -6 4 -8 -5
-11 -4 -7 2 4 6 19 8 33 5 17 -3 25 -1 20 6 -3 6 1 9 11 6 37 -8 238 109 241
140 3 20 0 26 -9 21 -7 -5 -10 -4 -6 1 5 9 -14 119 -29 167 -8 25 -16 22 -169
-59z"/>
            <path d="M563 1724 c-12 -5 55 -153 91 -199 14 -19 15 -17 16 36 0 98 -56 184
-107 163z"/>
            <path d="M2963 1553 c-11 -16 -25 -45 -32 -65 -7 -21 -17 -38 -22 -38 -5 0 -9
-8 -9 -17 0 -10 -7 -36 -15 -58 -18 -51 -18 -65 -1 -65 7 0 21 -11 30 -25 9
-14 21 -23 25 -20 5 3 11 -2 15 -10 3 -8 12 -15 21 -15 8 0 15 -4 15 -9 0 -11
62 -51 79 -51 17 0 13 28 -5 38 -11 6 -13 12 -6 16 15 9 52 93 52 117 0 10 9
24 21 32 21 15 24 66 4 69 -5 1 -18 12 -28 25 -10 13 -22 21 -27 18 -5 -3 -23
15 -41 40 -38 52 -48 55 -76 18z"/>
            <path d="M928 1408 c12 -12 24 -19 28 -15 10 10 -16 37 -34 37 -13 0 -12 -4 6
-22z"/>
            <path d="M3234 1359 c-7 -12 12 -29 34 -29 6 0 12 7 12 16 0 8 -4 12 -10 9 -5
-3 -10 -1 -10 4 0 14 -17 14 -26 0z"/>
            <path d="M3330 1283 c0 -5 16 -39 35 -77 19 -37 35 -79 35 -92 0 -15 6 -24 15
-24 9 0 15 10 15 26 0 14 -4 23 -9 20 -5 -4 -7 3 -4 14 6 22 -21 96 -44 123
-16 17 -43 23 -43 10z"/>
            <path d="M3400 970 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#5fac90" stroke="none">
            <path d="M3274 3398 c-5 -7 -2 -9 7 -4 8 4 6 1 -3 -7 -24 -19 -27 -327 -4
-327 8 0 16 5 18 12 3 8 11 6 29 -5 25 -17 109 -13 109 4 0 5 7 9 15 9 8 0 15
6 15 13 1 18 39 61 48 53 4 -4 7 -52 7 -105 0 -67 4 -98 12 -98 6 0 9 7 5 15
-3 10 -1 13 6 8 9 -5 12 31 12 144 0 107 -3 149 -11 144 -8 -4 -10 14 -8 62 2
38 0 75 -4 82 -11 16 -243 17 -253 0z"/>
            <path d="M3695 2630 c3 -6 -1 -7 -9 -4 -11 4 -16 0 -16 -10 0 -18 17 -21 39
-7 14 9 7 31 -10 31 -6 0 -7 -4 -4 -10z"/>
            <path d="M4200 2581 c0 -17 5 -31 10 -31 6 0 10 11 10 24 0 14 -4 28 -10 31
-6 4 -10 -7 -10 -24z"/>
            <path d="M4134 2481 c-29 -16 -67 -40 -85 -54 -36 -26 -116 -57 -149 -57 -11
0 -20 -4 -20 -10 0 -15 41 -12 90 5 23 8 47 12 53 8 6 -4 7 -1 2 7 -6 10 -4
12 8 7 9 -3 19 -1 23 5 5 7 2 8 -6 3 -9 -6 -11 -4 -6 4 5 7 14 10 22 7 7 -3
16 0 20 6 5 7 2 8 -6 3 -9 -6 -11 -4 -6 4 5 7 14 10 22 7 7 -3 16 0 20 6 5 7
2 8 -6 3 -9 -6 -11 -4 -6 4 5 7 15 10 23 7 8 -3 12 -2 9 3 -3 5 1 12 10 15 9
3 12 2 9 -4 -4 -6 9 -3 28 6 35 17 46 44 18 43 -9 0 -39 -13 -67 -28z"/>
            <path d="M3677 2351 c-40 -5 -51 -11 -65 -36 -9 -16 -19 -32 -22 -35 -10 -10
-40 -72 -40 -82 0 -6 -16 -42 -35 -79 -44 -86 -44 -95 2 -177 90 -163 163
-237 233 -237 54 0 82 17 62 37 -9 9 -15 9 -24 -1 -11 -11 -10 -12 2 -6 8 5
12 5 8 0 -11 -12 -88 -12 -89 0 -1 16 -20 35 -29 30 -26 -16 -139 165 -117
187 18 18 44 72 52 108 4 16 10 30 14 30 9 0 46 85 56 128 3 17 10 32 15 32 4
0 13 14 20 30 7 17 17 30 22 30 5 0 7 4 4 9 -8 12 18 21 59 21 19 0 37 5 40
10 7 11 -84 11 -168 1z"/>
            <path d="M4063 1884 c0 -11 9 -14 30 -12 44 4 47 28 5 28 -25 0 -35 -5 -35
-16z"/>
            <path d="M3954 1871 c4 -5 -3 -7 -14 -4 -14 4 -20 1 -18 -8 2 -7 -6 -13 -17
-14 -14 0 -19 -6 -17 -19 3 -13 -1 -17 -11 -13 -22 8 -64 -40 -48 -56 9 -9 21
-2 56 35 24 26 55 50 69 54 28 7 36 34 10 34 -8 0 -13 -4 -10 -9z"/>
            <path d="M4086 1834 c-7 -26 -1 -43 10 -32 3 4 6 12 8 20 1 7 5 19 9 26 5 7 3
12 -6 12 -8 0 -18 -12 -21 -26z"/>
            <path d="M4005 1650 c-31 -64 -43 -113 -21 -89 9 10 76 150 76 159 0 27 -20 3
-55 -70z"/>
            <path d="M2080 1636 c0 -40 36 -133 47 -122 4 4 3 10 -3 14 -6 4 -14 28 -18
54 -6 37 -26 80 -26 54z"/>
            <path d="M3935 1519 c-4 -11 -4 -23 -1 -27 8 -7 36 30 30 40 -9 14 -23 8 -29
-13z"/>
            <path d="M2095 1440 c2 -3 -62 -40 -143 -81 -130 -65 -143 -74 -115 -77 31 -3
212 80 250 115 7 6 22 15 34 18 25 8 20 28 -9 30 -11 1 -19 -1 -17 -5z"/>
            <path d="M3850 1386 c0 -8 -7 -16 -15 -20 -8 -3 -15 -12 -15 -20 0 -7 -7 -16
-15 -20 -15 -6 -21 -36 -7 -36 12 0 82 97 75 104 -11 11 -23 6 -23 -8z"/>
            <path d="M3223 1375 c0 -8 3 -13 5 -10 3 3 11 0 18 -6 8 -7 14 -6 19 2 4 6 1
15 -6 20 -21 13 -36 11 -36 -6z"/>
            <path d="M1740 1310 c0 -11 33 -30 52 -30 7 0 2 9 -12 20 -28 22 -40 25 -40
10z"/>
            <path d="M3350 1286 c0 -22 29 -41 42 -28 8 8 8 15 -2 27 -16 19 -40 20 -40 1z"/>
            <path d="M3743 1255 c-3 -9 -3 -19 1 -22 8 -9 37 18 30 28 -8 14 -24 11 -31
-6z"/>
            <path d="M2960 1245 c0 -8 7 -15 15 -15 8 0 15 -4 15 -10 0 -5 7 -10 16 -10
14 0 14 3 4 16 -22 26 -50 37 -50 19z"/>
            <path d="M3030 1195 c0 -8 5 -15 10 -15 6 0 22 -12 35 -27 19 -23 26 -26 36
-15 16 15 -8 41 -53 60 -23 10 -28 9 -28 -3z"/>
            <path d="M2065 1140 c-117 -32 -273 -82 -330 -105 -80 -33 -251 -119 -292
-147 -23 -15 -46 -28 -50 -28 -5 0 -14 -5 -20 -11 -13 -13 -6 -42 8 -33 5 3 9
1 9 -4 0 -10 60 -38 73 -34 4 1 7 -3 7 -9 0 -5 5 -7 10 -4 6 3 10 1 10 -6 0
-7 3 -10 6 -6 4 3 13 -1 21 -9 9 -8 22 -13 29 -10 8 3 14 1 14 -5 0 -6 5 -7
10 -4 6 3 10 1 10 -5 0 -7 7 -9 16 -6 8 3 12 2 9 -4 -3 -6 -2 -10 4 -10 5 0
11 5 13 11 2 6 20 11 40 12 29 1 35 4 26 14 -8 10 -7 13 4 13 7 0 25 13 38 28
31 35 67 55 86 48 7 -3 13 -2 13 2 -3 20 2 33 11 27 6 -3 10 -1 10 4 0 6 7 11
15 11 8 0 15 4 15 10 0 5 17 12 38 16 47 8 57 14 42 24 -8 5 7 15 41 28 30 11
53 25 52 31 -2 6 4 8 12 5 11 -5 14 -1 9 12 -6 15 -4 17 6 7 14 -13 50 3 46
21 -2 6 -1 8 1 4 8 -13 31 -9 37 7 3 8 17 15 31 15 24 0 30 9 25 39 -1 9 8 6
29 -8 17 -13 31 -18 31 -12 0 5 -10 17 -21 25 -12 8 -30 28 -40 45 -22 37 -24
37 -154 1z"/>
            <path d="M3642 1147 c-25 -27 -28 -37 -9 -37 17 0 59 44 50 53 -11 11 -18 8
-41 -16z"/>
            <path d="M2530 1151 c0 -15 -72 -91 -81 -86 -4 3 -13 -1 -21 -8 -8 -8 -34 -13
-65 -13 -49 1 -51 0 -26 -12 15 -9 38 -11 59 -7 30 5 35 3 44 -20 5 -14 12
-25 15 -25 4 0 3 6 -1 13 -5 9 -4 9 5 1 7 -7 14 -31 15 -55 1 -23 8 -45 14
-47 7 -2 12 -10 12 -18 0 -8 4 -14 10 -14 5 0 7 -7 4 -15 -4 -8 -1 -15 5 -15
6 0 11 -9 11 -19 0 -11 11 -32 25 -47 13 -14 22 -30 19 -35 -3 -5 4 -9 15 -9
12 0 21 -6 21 -14 0 -8 14 -17 30 -21 17 -4 30 -10 30 -14 0 -4 13 -6 29 -3
34 5 48 -12 29 -35 -12 -15 -10 -16 12 -10 14 4 29 7 33 7 5 0 7 6 4 13 -2 6
32 53 76 102 120 135 137 161 137 209 0 76 -40 107 -75 59 -118 -164 -275
-199 -275 -62 0 10 -4 20 -9 23 -5 3 -12 22 -15 41 -4 19 -15 43 -25 53 -10 9
-24 34 -32 55 -12 34 -29 50 -29 28z"/>
            <path d="M3110 916 c0 -9 -4 -16 -10 -16 -5 0 -10 -7 -10 -15 0 -8 6 -12 15
-9 8 3 13 11 12 17 -1 7 4 11 11 9 17 -3 11 20 -6 26 -7 2 -12 -3 -12 -12z"/>
            <path d="M3037 824 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
15z"/>
            <path d="M2967 734 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
15z"/>
            <path d="M2908 693 c-10 -2 -18 -11 -18 -18 0 -11 6 -12 30 -4 48 17 38 35
-12 22z"/>
            <path d="M2808 663 c-10 -2 -18 -9 -18 -14 0 -15 36 -10 50 6 13 16 5 18 -32
8z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#4d6f98" stroke="none">
            <path d="M2283 3423 c-12 -2 -25 -13 -28 -24 -4 -10 -14 -19 -24 -19 -10 0
-24 -7 -31 -15 -7 -8 -30 -20 -51 -27 -22 -6 -39 -17 -39 -25 0 -16 14 -16 60
-3 109 32 281 29 363 -7 36 -16 51 -43 23 -43 -8 0 -18 -9 -21 -20 -3 -11 -12
-20 -18 -20 -7 0 -25 -14 -41 -30 -16 -17 -34 -30 -40 -30 -6 0 -17 -9 -24
-20 -6 -11 -37 -34 -67 -51 -30 -17 -55 -34 -55 -37 0 -4 -21 -18 -47 -32 -27
-13 -58 -35 -70 -47 -12 -13 -27 -23 -32 -23 -6 0 -23 -13 -38 -28 -16 -15
-35 -26 -45 -24 -10 2 -19 -5 -23 -17 -3 -12 -13 -21 -21 -21 -8 0 -14 -4 -14
-9 0 -5 -13 -12 -30 -16 -21 -5 -30 -13 -30 -26 0 -10 -7 -19 -15 -19 -8 0
-15 -4 -15 -10 0 -5 -8 -10 -18 -10 -10 0 -25 -9 -35 -20 -9 -11 -30 -23 -46
-26 -16 -4 -31 -14 -34 -24 -2 -9 -17 -19 -31 -22 -37 -7 -49 26 -40 109 6 61
-9 88 -31 53 -3 -5 -14 -10 -23 -10 -56 0 -64 -223 -14 -382 32 -100 60 -163
69 -155 2 3 -1 15 -7 28 -17 30 -30 79 -22 79 4 0 14 -4 22 -10 9 -5 19 -5 27
1 6 6 20 10 30 9 35 -4 54 0 48 10 -4 6 11 17 32 25 21 9 45 20 53 25 8 6 27
15 42 21 27 11 42 34 32 50 -3 5 -1 9 5 9 5 0 11 -6 14 -12 3 -9 12 -6 29 10
13 12 28 22 32 22 16 0 65 44 59 53 -3 6 -1 7 5 3 6 -3 15 2 21 11 6 10 11 14
11 10 0 -10 60 24 60 34 0 4 14 9 32 13 17 3 37 15 45 26 8 11 19 20 25 20 5
0 27 14 48 30 21 17 43 30 49 30 6 0 11 4 11 8 0 5 12 15 27 23 26 13 48 32
113 95 14 13 30 24 36 24 6 0 16 10 22 23 6 12 11 16 11 10 1 -9 7 -7 19 5 35
32 62 52 72 52 33 0 106 112 88 135 -4 6 -23 28 -42 50 -19 21 -31 45 -28 53
2 8 0 17 -6 21 -32 19 7 35 44 17 26 -12 34 -13 26 -3 -62 74 -370 147 -509
120z"/>
            <path d="M2835 3264 c69 -61 85 -74 92 -74 11 1 -77 79 -102 91 -12 6 -7 -1
10 -17z"/>
            <path d="M3035 3030 c-9 -14 10 -43 29 -46 14 -2 14 1 1 27 -17 32 -21 34 -30
19z"/>
            <path d="M3080 2914 c0 -8 4 -13 9 -10 5 4 7 -2 4 -12 -3 -12 1 -19 11 -19 19
0 19 -1 6 31 -10 29 -30 35 -30 10z"/>
            <path d="M3115 2530 c5 -15 4 -20 -4 -16 -6 4 -11 0 -11 -8 0 -9 7 -16 15 -16
18 0 21 45 4 56 -8 4 -9 -1 -4 -16z"/>
            <path d="M3093 2458 c3 -10 1 -16 -4 -12 -5 3 -9 -2 -9 -10 0 -16 30 -24 30
-8 0 4 3 16 6 28 4 15 1 21 -11 21 -11 0 -15 -6 -12 -19z"/>
            <path d="M2964 2229 c-8 -14 11 -33 25 -25 6 4 11 14 11 23 0 13 -2 14 -17 2
-11 -10 -14 -10 -9 -1 4 6 5 12 2 12 -3 0 -8 -5 -12 -11z"/>
            <path d="M2903 2155 c-3 -9 -3 -18 0 -22 11 -10 37 6 37 23 0 14 -2 15 -17 3
-11 -10 -14 -10 -9 -1 4 6 5 12 1 12 -3 0 -8 -7 -12 -15z"/>
            <path d="M1978 2023 c12 -8 22 -24 22 -34 0 -10 5 -21 11 -25 8 -4 9 1 4 17
-9 29 -34 59 -49 58 -6 0 -1 -7 12 -16z"/>
            <path d="M2722 2031 c-8 -4 -14 -12 -15 -17 -1 -5 -5 -8 -9 -6 -5 1 -8 -5 -8
-13 0 -8 7 -15 15 -15 8 0 15 4 15 9 0 5 9 13 21 16 11 4 18 12 16 18 -5 15
-19 18 -35 8z"/>
            <path d="M2625 1683 c-9 -25 -6 -43 8 -40 12 2 25 23 26 43 1 16 -27 13 -34
-3z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#3da67f" stroke="none">
            <path d="M3475 3143 c-14 -15 -25 -33 -25 -40 0 -7 -9 -13 -21 -13 -11 0 -18
-4 -15 -9 9 -14 -64 -18 -82 -4 -44 33 -65 6 -59 -73 4 -63 16 -69 139 -69
123 -1 118 -6 118 106 0 127 -11 148 -55 102z"/>
            <path d="M4190 2691 c0 -14 -7 -25 -20 -28 -11 -3 -20 -14 -20 -24 0 -11 -4
-19 -8 -19 -5 0 -15 -11 -22 -25 -8 -16 -22 -25 -37 -25 -16 0 -23 -6 -23 -20
0 -11 -4 -20 -10 -20 -5 0 -20 -16 -34 -36 -14 -21 -27 -31 -31 -25 -13 22
-34 10 -51 -29 -10 -22 -23 -40 -30 -40 -7 0 -14 -5 -16 -12 -2 -6 -30 -13
-61 -16 -31 -2 -57 -9 -57 -15 0 -39 227 15 310 75 32 22 62 37 72 34 10 -2
15 0 12 5 -3 6 8 15 24 21 31 12 31 12 28 106 -3 80 -26 164 -26 93z"/>
            <path d="M3490 1975 c0 -8 5 -15 10 -15 6 0 10 -6 10 -14 0 -8 3 -16 8 -18 11
-5 52 -91 52 -110 0 -10 6 -18 13 -18 6 -1 3 -8 -9 -18 -12 -10 -24 -36 -27
-59 -8 -46 -45 -112 -64 -113 -7 0 -13 -6 -13 -12 -1 -7 -9 -31 -20 -53 -22
-46 -25 -77 -9 -108 9 -16 9 -22 0 -25 -15 -5 -14 -21 4 -38 8 -9 15 -24 15
-34 0 -11 6 -23 13 -27 30 -20 99 -144 112 -201 7 -34 7 -34 29 -13 12 12 25
19 28 16 2 -3 15 7 29 23 24 27 24 27 -2 7 -14 -11 7 11 45 49 39 38 62 63 51
56 -11 -7 -2 4 20 24 22 21 33 34 25 31 -8 -4 -1 5 15 20 17 14 23 23 15 20
-8 -4 -1 5 15 20 17 14 23 23 15 20 -8 -4 -4 3 10 15 14 12 18 19 10 15 -8 -4
-4 3 10 15 14 12 18 19 10 15 -8 -4 -4 3 10 15 14 12 18 19 10 15 -8 -4 -6 1
4 9 11 9 16 16 11 16 -5 0 0 7 11 16 10 8 12 13 4 9 -10 -4 -9 -2 2 8 10 8 15
17 11 20 -3 4 -1 7 6 7 7 0 9 5 6 10 -3 6 -1 10 5 10 6 0 8 5 5 10 -3 6 -1 10
5 10 6 0 8 5 5 10 -3 6 -1 10 5 10 6 0 8 5 5 10 -3 6 -1 10 5 10 6 0 8 5 5 10
-3 6 -1 10 5 10 6 0 8 5 5 10 -3 6 -1 10 5 10 6 0 8 5 5 10 -3 6 -1 10 6 10 7
0 9 3 6 6 -3 4 -1 16 5 28 7 11 11 24 10 29 -1 4 3 7 9 7 5 0 8 4 4 9 -3 5 -1
12 5 16 6 4 8 11 5 16 -4 5 -2 9 4 9 6 0 8 7 5 15 -4 8 -1 15 7 15 7 0 9 3 6
7 -4 3 -2 11 3 18 7 9 -5 10 -53 3 -34 -5 -73 -6 -85 -3 -16 4 -21 2 -17 -6 5
-8 1 -9 -15 -4 -16 5 -20 4 -15 -5 4 -7 2 -10 -6 -8 -8 1 -14 0 -14 -4 0 -11
-48 -58 -55 -53 -4 2 -20 -10 -36 -28 -29 -32 -29 -32 6 -2 35 30 35 30 1 -8
-26 -27 -40 -35 -54 -31 -13 4 -17 2 -13 -5 5 -7 -1 -11 -14 -11 -12 0 -18 4
-15 10 3 6 -1 7 -10 4 -29 -12 -129 100 -190 214 -28 52 -45 66 -45 37z"/>
            <path d="M2140 1261 c0 -37 -108 -103 -190 -116 -24 -4 -54 -13 -65 -20 -11
-7 -44 -18 -73 -25 -28 -7 -55 -16 -58 -21 -3 -5 -18 -9 -33 -9 -15 0 -31 -4
-37 -8 -15 -15 -148 -56 -171 -54 -13 1 -23 -2 -23 -7 0 -10 -37 -25 -97 -41
-24 -6 -43 -15 -43 -20 0 -6 -9 -10 -19 -10 -11 0 -23 -4 -26 -10 -3 -5 -19
-10 -36 -10 -16 0 -29 5 -29 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 6 -7 10
-15 10 -8 0 -15 -4 -15 -10 0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -5 7 -10 15
-10 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 8
0 15 -5 15 -12 0 -6 3 -9 6 -5 3 3 15 -3 26 -13 22 -20 54 -9 138 50 27 18
209 108 275 135 110 45 440 139 455 130 5 -3 5 9 0 26 -5 17 -9 34 -9 38 3 21
-16 51 -32 51 -11 0 -19 -4 -19 -9z"/>
            <path d="M2930 1050 c0 -5 11 -20 25 -34 55 -55 38 -92 -129 -280 -83 -95
-117 -144 -73 -108 8 7 17 9 20 5 9 -8 38 17 31 28 -3 5 32 51 77 101 127 144
144 190 98 264 -19 32 -49 46 -49 24z"/>
            <path d="M3535 1050 c-11 -17 5 -32 21 -19 8 6 14 15 14 20 0 12 -27 11 -35
-1z"/>
            <path d="M3088 902 c-21 -31 -47 -69 -59 -83 -12 -15 -19 -33 -17 -40 6 -19
77 50 83 80 3 14 10 29 16 35 9 8 10 7 4 -3 -5 -10 -4 -12 4 -7 6 4 8 12 5 18
-4 6 1 17 10 24 16 12 15 35 -2 34 -4 -1 -24 -27 -44 -58z"/>
            <path d="M2959 731 c-13 -16 -43 -34 -66 -41 -27 -8 -43 -19 -43 -29 0 -11 3
-12 11 -4 6 6 18 9 25 6 8 -3 14 0 14 6 0 6 7 8 15 5 8 -4 15 -1 15 5 0 6 9
11 20 11 11 0 20 6 20 14 0 7 8 20 18 27 16 13 16 12 2 -6 -11 -16 -11 -17 0
-8 18 17 22 43 6 43 -7 0 -24 -13 -37 -29z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#329871" stroke="none">
            <path d="M4134 2724 c-27 -15 -71 -39 -99 -54 -92 -49 -189 -115 -246 -169
-12 -11 -26 -21 -31 -21 -4 0 -10 -11 -14 -25 -3 -14 -10 -25 -15 -25 -5 0 -9
-6 -9 -13 0 -20 -33 -47 -58 -47 -14 0 -22 -6 -22 -16 0 -14 8 -16 58 -11 130
14 197 26 200 36 2 6 8 11 13 11 5 0 18 9 29 20 11 11 15 20 10 20 -6 0 -5 8
2 19 14 23 30 18 21 -5 -10 -26 31 4 50 36 9 14 30 37 47 50 43 33 82 70 75
70 -4 0 1 7 9 16 9 8 14 19 11 24 -3 4 4 10 15 13 14 4 20 14 20 35 0 17 3 37
6 46 9 24 -16 20 -72 -10z"/>
            <path d="M3463 2325 c-9 -19 -20 -35 -25 -35 -4 0 -8 -7 -8 -15 0 -8 -4 -15
-10 -15 -5 0 -10 -9 -10 -20 0 -11 -7 -23 -15 -26 -17 -7 -21 -34 -4 -34 23 0
49 22 49 40 0 11 4 20 9 20 5 0 19 20 31 45 12 25 28 45 36 45 8 0 14 7 14 15
0 32 -49 17 -67 -20z"/>
            <path d="M3322 2108 c-16 -16 -15 -38 1 -38 17 0 37 20 37 37 0 16 -22 17 -38
1z"/>
            <path d="M3330 2034 c-22 -20 -40 -40 -40 -46 0 -5 -9 -18 -20 -30 -11 -12
-20 -16 -20 -10 0 34 -39 8 -65 -43 -16 -30 -35 -55 -42 -55 -7 0 -13 -7 -13
-15 0 -8 7 -15 16 -15 23 0 84 30 84 42 0 5 13 20 29 34 17 14 33 34 36 45 10
29 87 98 111 99 36 0 75 -51 68 -88 -4 -18 -11 -32 -15 -32 -5 0 -14 -11 -19
-25 -5 -14 -14 -25 -20 -25 -5 0 -10 -9 -10 -20 0 -11 -9 -28 -20 -37 -11 -10
-20 -21 -20 -25 0 -4 -18 -36 -40 -70 -22 -35 -40 -71 -40 -80 0 -9 -4 -19 -9
-22 -5 -3 -11 -23 -14 -43 -3 -21 -8 -47 -11 -58 -6 -16 8 -36 62 -93 52 -53
69 -77 65 -92 -4 -14 0 -20 11 -20 9 0 16 -7 16 -16 0 -14 -1 -14 -16 0 -8 9
-20 16 -25 16 -5 0 -22 11 -37 25 -30 28 -52 32 -52 10 0 -8 7 -15 16 -15 8 0
13 -4 9 -9 -3 -5 4 -13 16 -16 12 -4 31 -17 43 -29 18 -19 19 -19 6 -1 -15 20
-15 20 8 1 12 -11 22 -24 22 -29 0 -6 16 -25 35 -43 23 -21 33 -37 29 -48 -3
-9 -1 -16 5 -16 6 0 8 -7 5 -15 -4 -8 -2 -15 2 -15 5 0 10 -23 12 -52 4 -69 8
-73 44 -35 17 18 25 23 18 12 -7 -11 2 -4 19 15 47 51 -8 223 -86 273 -7 4
-13 16 -13 27 0 10 -7 26 -16 34 -13 14 -13 16 -1 16 18 0 17 30 0 48 -25 26
5 116 42 127 20 6 20 6 0 15 -19 8 -19 9 3 9 13 1 21 5 18 10 -3 5 3 20 14 34
11 14 20 32 20 41 0 9 5 16 11 16 6 0 9 6 6 14 -3 8 -1 17 6 20 9 5 9 8 -1 12
-10 5 -8 10 7 21 22 15 29 43 10 43 -5 0 -8 4 -4 9 6 10 -43 110 -60 123 -7 5
-7 8 0 8 6 0 1 7 -9 15 -11 8 -17 15 -13 15 13 0 -22 58 -45 74 -39 27 -83 20
-128 -20z"/>
            <path d="M3261 2026 c-24 -38 -27 -56 -8 -56 12 0 57 62 57 80 0 22 -30 8 -49
-24z"/>
            <path d="M1988 1358 c-53 -28 -73 -48 -48 -48 14 0 60 22 60 30 0 3 16 12 35
19 19 7 35 17 35 22 0 16 -23 10 -82 -23z"/>
            <path d="M1740 1305 c0 -17 56 -45 89 -45 30 0 71 21 71 37 0 10 -5 11 -19 3
-29 -15 -93 -12 -119 6 -20 14 -22 14 -22 -1z"/>
            <path d="M2052 1266 c-129 -74 -192 -94 -235 -77 -71 29 -98 25 -199 -30 -10
-5 -35 -9 -56 -9 -40 0 -83 -11 -139 -36 -17 -8 -36 -14 -41 -14 -23 0 -252
-109 -252 -120 0 -11 30 -34 30 -24 0 5 5 1 11 -9 6 -9 14 -15 20 -12 5 4 12
-1 15 -9 3 -9 10 -14 14 -11 5 3 12 -1 17 -8 10 -17 65 -16 76 1 4 6 17 12 27
12 11 0 20 4 20 10 0 5 19 14 43 20 61 16 97 31 97 42 0 5 5 6 10 3 11 -7 168
42 184 57 6 4 22 8 37 8 15 0 30 4 34 10 3 5 12 7 20 3 8 -3 15 -1 15 5 0 5
17 13 38 17 20 3 46 12 57 19 11 8 41 17 65 21 87 14 152 53 190 114 7 13 12
12 27 -5 10 -11 14 -15 10 -8 -4 7 -7 16 -6 20 17 70 -18 73 -129 10z"/>
            <path d="M2546 1224 c-9 -35 -9 -35 78 -44 99 -12 207 -50 259 -93 148 -124
148 -133 -22 -326 -85 -97 -106 -131 -81 -131 6 0 10 5 10 11 0 6 9 8 20 4 12
-4 20 -2 20 5 0 7 7 10 15 6 8 -3 15 -1 15 5 0 6 9 8 21 4 16 -5 19 -4 14 5
-5 9 -1 10 15 5 16 -5 20 -4 15 5 -5 9 -1 10 15 5 18 -6 22 -4 17 8 -4 11 -2
14 8 11 8 -3 12 1 12 12 -1 10 8 23 21 29 12 7 26 16 30 20 4 5 1 5 -5 1 -9
-5 -11 -2 -7 8 3 9 11 13 17 9 6 -4 8 0 4 9 -3 10 5 25 20 39 15 13 23 18 18
10 -6 -10 -4 -12 4 -7 7 4 10 13 8 19 -4 13 27 63 53 85 13 11 13 12 0 6 -13
-6 -12 -4 1 12 20 24 26 114 8 114 -7 0 -9 3 -6 7 4 3 2 12 -4 20 -6 7 -8 13
-3 13 5 0 2 5 -6 10 -8 5 -15 15 -15 22 -1 7 -6 12 -12 11 -6 -1 -17 5 -25 14
-11 14 -11 16 1 8 10 -5 12 -4 7 3 -10 16 -36 15 -36 -1 0 -7 13 -24 28 -37
39 -35 47 -58 17 -53 -15 4 -32 -5 -54 -26 -39 -39 -35 -39 -86 12 -66 64
-142 105 -226 123 -112 23 -149 34 -149 44 0 22 -28 8 -34 -16z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#298562" stroke="none">
            <path d="M4135 2852 c-16 -9 -32 -20 -33 -25 -2 -4 -8 -5 -13 -1 -5 3 -9 0 -9
-7 0 -7 -3 -10 -6 -6 -7 7 -61 -19 -82 -39 -7 -8 -18 -12 -23 -9 -5 4 -9 1 -9
-5 0 -6 -4 -8 -10 -5 -5 3 -10 1 -10 -5 0 -6 -4 -8 -10 -5 -5 3 -10 1 -10 -6
0 -7 -3 -10 -6 -6 -3 3 -11 1 -18 -4 -6 -5 -20 -16 -31 -24 -64 -44 15 -18
136 44 141 73 192 90 102 34 -26 -17 -71 -42 -99 -56 -107 -54 -247 -137 -252
-149 -2 -4 -9 -8 -17 -8 -14 0 -115 -93 -115 -106 0 -5 -16 -26 -36 -48 -38
-41 -104 -67 -104 -40 0 8 -7 14 -15 14 -8 0 -15 -5 -15 -10 0 -16 -150 -160
-165 -160 -16 0 -47 -41 -41 -55 7 -18 -20 -76 -40 -88 -8 -4 -14 -13 -14 -18
0 -6 -13 -29 -29 -52 -112 -166 -113 -209 -2 -194 48 6 69 24 138 114 30 40
60 76 66 80 7 3 4 -3 -5 -14 -10 -11 -18 -24 -18 -29 0 -9 -52 -80 -84 -116
-9 -10 -16 -22 -16 -28 0 -5 -4 -10 -9 -10 -5 0 -24 -26 -42 -57 -18 -32 -37
-61 -41 -64 -17 -11 -11 -95 10 -147 31 -80 21 -97 -33 -56 -44 33 -64 28 -35
-9 12 -16 25 -26 28 -23 3 3 12 -3 19 -14 8 -11 18 -18 23 -15 4 3 15 -6 24
-20 10 -15 26 -25 41 -25 13 0 31 -9 39 -21 8 -12 19 -18 24 -15 12 7 34 -18
24 -29 -4 -5 1 -5 10 -2 11 4 17 2 15 -5 -1 -7 8 -13 20 -14 26 -2 97 -69 88
-83 -3 -7 0 -8 9 -5 23 9 20 29 -8 59 -13 14 -26 38 -29 53 -3 16 -37 61 -81
106 -62 65 -74 82 -66 97 6 10 10 27 10 38 0 12 5 21 10 21 6 0 10 5 10 11 0
5 -6 7 -12 3 -10 -6 -10 -4 0 7 6 7 12 20 12 29 0 8 18 43 40 78 22 34 40 66
40 70 0 4 9 15 20 25 11 9 20 26 20 36 0 11 9 23 20 26 11 4 19 13 18 20 -2 8
1 13 6 12 5 -1 15 23 22 53 13 55 13 55 -21 88 -32 30 -85 46 -69 21 4 -7 0
-9 -13 -5 -14 4 -28 -4 -54 -31 -34 -38 -34 -38 6 -3 40 35 40 35 6 -2 -19
-20 -39 -36 -45 -35 -6 1 -10 -6 -10 -15 1 -10 -3 -15 -9 -11 -6 4 -8 0 -4 -9
3 -10 -3 -24 -14 -34 -22 -20 -49 -27 -49 -13 0 16 5 24 14 21 5 -2 6 5 2 14
-4 12 -2 15 9 10 14 -5 21 11 16 37 -1 5 4 5 10 1 9 -5 10 -2 6 9 -3 9 -1 19
5 23 7 5 8 2 3 -6 -5 -9 -4 -11 4 -6 6 4 10 15 9 24 -2 9 2 17 7 17 19 1 36
17 31 30 -3 8 3 19 12 27 15 11 16 11 7 -2 -5 -9 0 -4 13 10 12 14 20 31 17
37 -2 7 1 12 8 10 7 -1 12 8 11 20 0 12 3 20 8 17 4 -3 11 8 14 25 4 19 11 28
20 24 9 -3 12 2 9 17 -3 16 0 20 10 16 10 -3 12 0 8 11 -4 11 -2 14 5 9 8 -5
10 -1 5 10 -4 11 -2 15 4 11 6 -4 14 0 17 9 4 10 2 13 -6 8 -6 -4 -8 -3 -5 3
4 5 42 8 93 5 63 -3 85 -2 80 7 -5 7 1 11 17 11 13 0 34 11 47 25 13 14 19 25
14 25 -5 0 -3 4 5 9 8 5 16 17 17 27 2 10 10 21 18 26 8 5 22 15 30 23 42 43
152 117 241 165 28 15 72 39 99 53 26 15 53 24 59 20 7 -4 8 0 3 13 -5 10 -10
38 -12 62 -5 67 -11 72 -59 44z"/>
            <path d="M3780 2670 c0 -5 -6 -10 -14 -10 -20 0 -62 -37 -53 -46 11 -12 97 33
97 51 0 8 -7 15 -15 15 -8 0 -15 -4 -15 -10z"/>
            <path d="M3588 2561 c-30 -29 -36 -51 -15 -51 15 0 67 54 67 69 0 19 -24 11
-52 -18z"/>
            <path d="M3510 2455 c0 -9 -6 -18 -13 -21 -18 -7 -17 -34 1 -34 7 0 16 9 19
20 3 11 9 20 14 20 5 0 9 7 9 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15 -15z"/>
            <path d="M3022 1544 c2 -11 11 -20 22 -22 13 -3 17 1 14 14 -2 11 -11 20 -22
22 -13 3 -17 -1 -14 -14z"/>
            <path d="M2584 1423 c-4 -27 -6 -52 -5 -57 1 -4 -3 -14 -9 -20 -5 -7 -7 -15
-4 -18 3 -4 -2 -21 -11 -38 -9 -18 -15 -41 -12 -51 3 -11 1 -19 -4 -19 -5 0
-9 -7 -9 -15 0 -8 7 -15 16 -15 8 0 12 4 9 10 -3 5 -1 18 4 27 6 10 11 13 11
6 0 -14 38 -29 81 -32 11 0 17 -6 14 -11 -4 -7 2 -9 18 -4 30 7 123 -18 152
-41 11 -9 14 -14 8 -10 -24 12 -13 -3 15 -21 26 -17 27 -17 12 1 -12 16 -9 15
18 -4 17 -12 32 -25 32 -28 0 -3 -8 1 -17 8 -16 13 -17 12 -5 -3 8 -10 20 -14
29 -11 13 5 15 2 10 -11 -4 -10 -3 -15 3 -11 5 3 21 -8 36 -24 25 -29 28 -30
41 -13 8 9 12 11 8 4 -5 -10 -1 -10 19 0 14 6 26 18 26 27 0 11 -3 11 -16 -5
-15 -18 -15 -18 -5 2 12 26 32 35 59 28 12 -4 22 0 25 10 4 9 2 16 -4 16 -6 0
-8 4 -4 9 3 6 -4 15 -15 21 -12 6 -19 17 -17 25 3 7 -1 12 -11 11 -9 0 -17 5
-17 12 0 6 -4 11 -8 10 -5 -2 -13 5 -18 15 -6 9 -17 17 -24 17 -8 0 -15 5 -15
11 0 6 -6 9 -14 6 -9 -4 -16 3 -20 16 -3 12 -8 18 -12 15 -3 -4 -17 6 -31 23
-14 16 -31 29 -39 29 -23 0 -16 -27 13 -49 32 -26 28 -30 -25 -35 -31 -3 -42
-1 -42 10 0 8 -7 14 -15 14 -8 0 -15 -5 -15 -12 0 -6 -9 -2 -20 10 -11 12 -20
23 -20 26 0 2 -12 18 -27 34 -16 16 -47 50 -70 75 -24 25 -43 52 -43 61 0 38
-28 13 -36 -31z"/>
            <path d="M2772 1434 c2 -11 11 -20 22 -22 13 -3 17 1 14 14 -2 11 -11 20 -22
22 -13 3 -17 -1 -14 -14z"/>
            <path d="M2105 1423 c-16 -9 -34 -22 -38 -30 -5 -7 -12 -11 -18 -8 -5 4 -9 1
-9 -5 0 -6 -4 -8 -10 -5 -5 3 -10 1 -10 -4 0 -6 -5 -10 -11 -8 -6 1 -14 -4
-16 -10 -8 -21 21 -15 86 19 41 21 61 37 61 50 0 21 3 21 -35 1z"/>
            <path d="M1675 1410 c-6 -10 16 -40 30 -40 4 0 5 10 3 22 -3 24 -23 35 -33 18z"/>
            <path d="M2048 1336 c-107 -54 -139 -66 -120 -43 7 8 24 18 37 21 27 7 32 20
13 32 -6 4 -17 -1 -25 -12 -8 -10 -15 -17 -16 -15 -4 8 -47 -11 -47 -20 0 -6
-4 -7 -10 -4 -5 3 -10 2 -10 -4 0 -6 -7 -7 -17 -4 -11 4 -14 3 -9 -5 5 -8 0
-12 -14 -12 -13 0 -19 4 -14 12 5 8 0 9 -14 6 -12 -4 -22 -2 -22 4 0 5 -5 6
-10 3 -6 -3 -13 1 -16 9 -8 20 -24 21 -24 2 0 -7 10 -21 23 -31 21 -16 21 -16
-5 -1 -42 25 -43 24 -337 -121 -152 -75 -286 -136 -298 -135 -28 2 -30 -18 -3
-25 11 -3 20 -9 20 -13 0 -5 30 6 68 25 92 46 182 85 196 85 6 0 23 6 37 14
34 18 76 28 127 31 40 3 75 15 149 53 29 14 37 15 65 3 83 -34 142 -21 298 69
85 48 85 48 94 24 6 -13 13 -24 18 -24 10 0 10 13 0 26 -5 5 -14 28 -21 52
-14 51 -7 51 -113 -2z"/>
            <path d="M1400 1268 c0 -5 -4 -6 -10 -3 -5 3 -10 -1 -10 -9 0 -17 9 -20 34
-10 20 8 21 31 1 31 -8 0 -15 -4 -15 -9z"/>
            <path d="M3474 1193 c4 -8 2 -13 -6 -13 -10 0 -10 -2 -1 -8 7 -4 11 -18 9 -29
-3 -15 -1 -20 9 -17 7 3 16 -3 19 -13 3 -10 3 0 1 21 -2 22 -12 47 -21 55 -9
9 -14 10 -10 4z"/>
            <path d="M1035 1084 c-15 -15 -15 -18 1 -35 22 -25 38 -24 31 1 -3 11 -1 20 4
20 5 0 9 7 9 15 0 20 -24 19 -45 -1z"/>
            <path d="M3496 1074 c-8 -22 -8 -26 4 -19 6 3 10 -4 10 -17 0 -17 3 -19 9 -9
9 14 4 48 -9 56 -4 3 -11 -2 -14 -11z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#6481a3" stroke="none">
            <path d="M2338 3423 c23 -2 59 -2 80 0 20 2 1 4 -43 4 -44 0 -61 -2 -37 -4z"/>
            <path d="M2173 3410 c-53 -11 -209 -78 -224 -97 -7 -8 -29 -23 -50 -33 -48
-25 -151 -139 -195 -217 -59 -105 -133 -392 -95 -369 6 4 11 25 11 47 0 27 5
42 16 46 8 3 13 12 10 20 -4 9 -1 13 6 10 7 -2 20 2 29 10 15 12 16 6 15 -66
-1 -78 26 -145 41 -103 3 6 13 12 22 12 17 0 74 38 112 75 9 8 23 15 32 15 10
0 17 5 17 10 0 6 7 10 15 10 8 0 15 9 15 19 0 15 6 19 20 16 11 -2 18 0 15 6
-4 5 1 9 9 9 9 0 16 5 16 10 0 6 6 10 14 10 8 0 17 9 21 19 4 12 12 18 21 14
8 -3 26 7 43 23 15 16 33 30 40 32 7 1 23 14 37 29 13 14 24 23 24 20 0 -8 69
37 93 60 11 11 17 12 17 4 0 -7 5 -9 11 -6 6 4 8 9 5 12 -3 3 15 19 40 34 24
16 47 36 50 44 4 8 12 15 18 15 7 0 26 13 42 30 16 16 34 30 41 30 6 0 15 9
18 20 3 11 13 20 21 20 8 0 14 4 14 9 0 5 2 15 5 23 20 51 -242 79 -410 43
-30 -6 -30 -6 1 9 18 7 35 19 38 25 4 6 16 11 27 11 10 0 19 5 19 10 0 6 7 10
16 10 14 0 14 2 0 15 -16 16 -38 17 -103 5z"/>
            <path d="M2510 3410 c8 -5 24 -10 35 -10 11 0 45 -9 75 -20 71 -24 78 -18 8 8
-60 22 -142 37 -118 22z"/>
            <path d="M3097 2906 c-9 -24 -9 -28 3 -21 7 4 8 -3 4 -21 -5 -18 -3 -25 4 -20
6 3 13 0 15 -6 12 -28 5 18 -7 51 -10 28 -14 31 -19 17z"/>
            <path d="M1600 2618 c0 -13 5 -30 10 -38 7 -11 10 -7 10 17 0 17 -4 35 -10 38
-6 3 -10 -4 -10 -17z"/>
            <path d="M3105 2482 c4 -16 2 -22 -6 -17 -8 5 -9 1 -4 -15 5 -15 4 -20 -4 -16
-6 4 -11 0 -11 -8 0 -26 29 -18 35 9 3 14 7 36 10 48 3 17 0 24 -11 24 -11 0
-14 -7 -9 -25z"/>
            <path d="M2755 2044 c-11 -8 -14 -12 -6 -8 10 5 12 3 7 -5 -5 -7 -15 -10 -23
-7 -9 4 -11 2 -7 -6 5 -7 2 -9 -9 -5 -12 4 -14 2 -10 -10 5 -12 3 -14 -7 -8
-10 6 -11 2 -6 -19 4 -18 3 -25 -4 -21 -5 3 -10 -3 -10 -14 0 -11 4 -22 9 -26
12 -7 31 26 31 54 0 14 13 27 41 41 22 12 39 26 37 32 -7 19 -22 19 -43 2z"/>
            <path d="M2006 1975 c3 -16 8 -45 9 -64 2 -19 9 -39 15 -45 12 -12 4 36 -16
99 -9 25 -11 29 -8 10z"/>
            <path d="M2670 1878 c0 -10 7 -18 15 -18 8 0 15 8 15 18 0 10 -7 19 -15 19 -8
0 -15 -9 -15 -19z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#227053" stroke="none">
            <path d="M4128 2844 c-16 -8 -28 -19 -28 -25 0 -6 -4 -7 -10 -4 -5 3 -10 1
-10 -5 0 -6 -4 -8 -10 -5 -9 6 -70 -21 -70 -31 0 -3 5 -2 12 2 7 4 8 3 4 -4
-4 -6 -13 -9 -21 -5 -8 3 -15 0 -15 -7 0 -6 -4 -8 -10 -5 -5 3 -10 1 -10 -5 0
-6 -4 -8 -10 -5 -5 3 -10 1 -10 -6 0 -7 -3 -9 -7 -6 -3 4 -12 -1 -20 -11 -8
-9 -12 -11 -8 -4 5 10 1 10 -19 1 -14 -7 -23 -16 -19 -22 3 -6 1 -7 -5 -3 -7
4 -18 4 -24 -1 -7 -4 -19 -8 -27 -10 -7 -1 -19 -7 -25 -14 -8 -9 -8 -10 3 -4
10 5 12 4 7 -4 -4 -6 -12 -9 -17 -5 -5 3 -30 -6 -54 -21 -25 -14 -45 -23 -45
-20 0 3 -13 -1 -30 -10 -16 -8 -29 -21 -27 -28 1 -7 -6 -11 -17 -9 -13 3 -17
-1 -13 -12 3 -8 -2 -20 -11 -28 -15 -12 -16 -11 -3 7 8 11 -3 2 -23 -20 -20
-22 -34 -46 -31 -52 2 -7 -1 -12 -8 -10 -7 1 -11 -6 -10 -16 0 -11 -9 -23 -23
-29 -15 -7 -23 -17 -20 -25 3 -8 1 -11 -4 -8 -6 3 -10 2 -10 -4 0 -39 92 -18
141 32 21 23 39 45 39 50 0 14 100 107 115 107 8 0 15 4 17 8 5 13 138 91 281
165 73 37 147 95 147 115 0 13 -33 11 -62 -4z"/>
            <path d="M3297 2229 c-9 -6 -15 -14 -12 -20 3 -5 -1 -6 -10 -3 -12 5 -16 2
-13 -12 2 -13 -3 -19 -17 -19 -23 0 -127 -133 -120 -153 3 -7 1 -11 -4 -8 -13
8 -44 -31 -35 -45 4 -7 3 -10 -4 -5 -13 8 -56 -60 -47 -74 4 -6 1 -9 -6 -8
-17 4 -40 -42 -36 -69 2 -12 0 -20 -4 -17 -10 6 -12 -139 -3 -173 4 -13 10
-21 14 -18 5 3 6 -3 3 -13 -2 -10 5 -29 17 -43 12 -13 18 -18 14 -10 -4 9 -3
12 4 7 6 -4 9 -13 6 -20 -3 -7 3 -19 13 -27 17 -13 17 -13 3 5 -15 19 -14 19
13 2 24 -17 41 -36 17 -21 -5 3 -10 4 -10 1 0 -3 10 -10 21 -17 17 -9 20 -8
13 3 -6 11 -5 11 9 -1 9 -7 15 -17 12 -21 -3 -4 2 -11 11 -14 8 -3 12 -2 9 4
-3 6 1 7 9 4 9 -3 13 -11 9 -18 -3 -6 4 -2 16 9 19 17 20 21 7 23 -13 3 -14 7
-5 24 8 14 8 23 1 27 -33 20 -56 149 -31 171 3 3 20 31 38 63 18 31 37 57 42
57 5 0 9 5 9 10 0 6 7 18 16 28 30 34 84 107 84 115 0 4 10 21 21 36 40 55 14
59 -36 6 -26 -27 -51 -49 -56 -48 -5 2 -6 -3 -3 -11 11 -28 -80 -108 -128
-113 -82 -8 -78 24 22 172 15 22 29 48 33 58 4 9 10 17 15 17 5 0 15 14 22 30
7 17 17 30 22 30 5 0 7 4 4 9 -4 5 -3 11 2 13 10 4 62 73 62 81 0 10 -12 8
-33 -4z"/>
            <path d="M2601 1531 c-12 -64 -25 -126 -28 -138 -3 -13 0 -23 5 -23 6 0 12 10
14 22 2 12 8 23 15 25 12 4 95 -76 88 -86 -2 -4 10 -16 26 -26 16 -11 29 -26
29 -32 0 -7 4 -13 9 -13 5 0 13 -9 16 -21 4 -12 12 -18 21 -15 8 3 14 1 14 -4
0 -5 13 -5 30 0 19 5 30 5 30 -1 0 -6 7 -6 18 -1 9 5 23 7 30 5 6 -3 12 2 12
11 0 9 6 13 14 10 8 -3 17 0 21 6 4 7 0 10 -11 8 -11 -2 -18 2 -17 9 1 6 -4
14 -13 17 -10 4 -13 2 -8 -7 4 -6 -2 -4 -14 7 -12 10 -20 22 -17 26 7 11 -50
61 -60 53 -4 -5 -5 -3 -1 4 6 12 -18 73 -30 73 -3 0 -2 -6 2 -12 4 -7 4 -10
-1 -6 -4 4 -9 22 -10 40 -5 80 -78 177 -139 183 -22 3 -24 -3 -45 -114z"/>
            <path d="M2121 1427 c-6 -8 -7 -17 -3 -22 4 -4 -3 -4 -16 -1 -16 5 -24 3 -28
-9 -4 -9 -13 -14 -21 -11 -8 3 -11 2 -8 -4 8 -12 -30 -31 -48 -24 -8 3 -18 0
-23 -7 -5 -8 -3 -10 7 -5 8 4 5 0 -6 -8 -11 -9 -25 -14 -32 -12 -7 3 -13 1
-13 -5 0 -6 -5 -7 -12 -3 -7 4 -8 3 -4 -4 8 -14 -30 -35 -50 -28 -9 3 -12 1
-9 -4 3 -6 1 -10 -4 -10 -6 0 -11 -4 -11 -8 0 -18 130 20 217 63 89 45 112 68
78 81 -12 5 -13 10 -4 20 6 8 9 14 6 14 -2 0 -10 -6 -16 -13z"/>
            <path d="M1656 1393 c-3 -8 -10 -11 -16 -8 -12 8 -91 -35 -84 -46 2 -4 -5 -6
-16 -4 -11 2 -27 -4 -37 -13 -9 -9 -19 -17 -22 -17 -3 0 -5 -3 -4 -6 1 -4 -6
-5 -16 -2 -12 3 -24 -3 -33 -16 -8 -12 -18 -18 -21 -14 -4 3 -7 2 -7 -4 0 -6
-9 -9 -20 -6 -14 4 -20 0 -20 -11 0 -9 -3 -15 -7 -14 -13 4 -103 -34 -103 -43
0 -5 -3 -8 -7 -7 -13 4 -113 -45 -113 -54 0 -5 -4 -6 -10 -3 -5 3 -10 1 -10
-5 0 -6 -4 -8 -10 -5 -5 3 -10 1 -10 -4 0 -6 -6 -10 -12 -9 -7 2 -12 -2 -11
-9 2 -7 -3 -10 -11 -7 -8 3 -17 0 -22 -7 -5 -8 -3 -10 7 -4 12 7 12 6 1 -7
-12 -14 -9 -19 15 -35 15 -9 32 -23 36 -30 10 -17 16 -14 336 144 247 122 276
134 295 123 25 -16 109 -24 101 -9 -4 5 -13 9 -21 9 -8 0 -12 4 -9 10 4 6 -1
7 -11 3 -11 -4 -15 -2 -11 4 4 6 -1 10 -10 9 -10 0 -18 4 -18 9 -1 6 -10 25
-22 44 -12 18 -19 39 -17 45 2 6 -7 12 -20 12 -14 1 -27 -5 -30 -13z"/>
            <path d="M2546 1289 c-8 -41 0 -49 15 -15 9 18 9 30 2 37 -7 7 -12 0 -17 -22z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#83c0a9" stroke="none">
            <path d="M3277 3403 c-3 -5 49 -7 116 -5 106 3 123 1 134 -14 7 -10 13 -13 13
-8 0 27 -28 34 -141 34 -65 0 -120 -3 -122 -7z"/>
            <path d="M3263 3305 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z"/>
            <path d="M3261 3048 c-1 -53 3 -88 9 -88 12 0 12 79 1 135 -7 34 -9 26 -10
-47z"/>
            <path d="M4148 2876 c-31 -23 -21 -47 12 -26 9 6 17 5 23 -3 6 -8 7 -6 3 6 -3
10 -6 23 -6 28 0 13 -12 11 -32 -5z"/>
            <path d="M1604 2675 c0 -49 1 -71 3 -48 2 23 2 64 0 90 -2 26 -3 8 -3 -42z"/>
            <path d="M4114 2433 c-49 -31 -104 -63 -121 -72 -18 -8 -33 -19 -33 -23 0 -4
-7 -8 -15 -8 -8 0 -15 -9 -15 -20 0 -12 -7 -20 -17 -20 -15 0 -16 -2 -3 -10
13 -8 13 -10 -2 -10 -10 0 -21 -14 -29 -37 -6 -21 -16 -43 -21 -50 -5 -7 -7
-16 -4 -21 3 -6 -3 -12 -14 -15 -13 -3 -18 -10 -14 -21 3 -9 1 -16 -5 -16 -6
0 -11 -11 -11 -24 0 -14 -5 -28 -11 -31 -6 -4 -8 -13 -5 -20 2 -7 -2 -16 -9
-19 -9 -3 -12 -12 -9 -21 5 -11 0 -18 -14 -22 -11 -2 -23 -15 -26 -26 -8 -32
-7 -39 5 -32 12 8 -8 -30 -32 -60 -23 -29 -30 -53 -16 -59 9 -3 9 -5 0 -5 -41
-3 23 -71 66 -71 13 0 48 27 90 68 76 76 148 107 234 101 45 -4 45 -4 76 121
47 193 80 480 54 480 -5 0 -50 -26 -99 -57z"/>
            <path d="M2784 2059 c-5 -8 -3 -10 7 -4 11 7 11 5 -1 -9 -13 -17 -12 -18 5
-15 11 3 20 11 20 18 0 19 -21 25 -31 10z"/>
            <path d="M2069 1682 c-2 -5 -37 -26 -78 -46 -40 -21 -71 -40 -68 -43 6 -5 134
61 151 78 5 5 7 11 4 14 -3 4 -8 2 -9 -3z"/>
            <path d="M1814 1551 c-63 -30 -85 -46 -102 -75 -25 -43 -15 -48 15 -7 12 16
55 45 97 65 42 20 78 41 81 46 10 16 -5 11 -91 -29z"/>
            <path d="M3320 1306 c0 -12 40 -46 55 -46 3 0 -2 11 -10 24 -19 29 -45 41 -45
22z"/>
            <path d="M3395 1250 c-5 -9 -4 -15 3 -18 7 -2 12 -10 12 -18 0 -16 16 -19 25
-5 3 6 -1 13 -8 18 -8 4 -16 15 -19 23 -4 12 -6 12 -13 0z"/>
            <path d="M3450 1165 c0 -15 25 -84 34 -92 6 -6 2 47 -4 55 -2 1 -3 6 -4 12 -1
13 -26 36 -26 25z"/>
            <path d="M2376 645 c-11 -14 -25 -24 -32 -21 -7 3 -20 -4 -30 -15 -9 -10 -24
-19 -33 -19 -9 0 -11 -3 -5 -9 15 -15 226 -1 214 14 -6 7 -19 11 -30 8 -21 -6
-56 23 -61 50 -4 15 -7 14 -23 -8z"/>
            <path d="M1789 644 c25 -8 61 -16 80 -19 20 -3 5 4 -34 15 -79 22 -113 25 -46
4z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#fc6f3b" stroke="none">
            <path d="M1353 1938 c-16 -24 -49 -78 -72 -120 -83 -152 -264 -193 -443 -100
-13 6 -9 -113 6 -161 18 -60 61 -126 107 -162 20 -17 35 -33 33 -37 -2 -3 10
-11 26 -17 42 -14 42 16 0 32 -16 6 -32 17 -34 24 -4 9 4 8 27 -4 51 -29 246
-26 310 4 117 54 217 196 217 307 0 29 3 33 21 29 23 -6 18 43 -7 63 -8 6 -11
14 -8 17 15 15 -110 167 -136 167 -9 0 -30 -19 -47 -42z"/>
            <path d="M1550 1635 c0 -14 4 -25 9 -25 5 0 11 11 13 25 4 17 1 25 -8 25 -8 0
-14 -11 -14 -25z"/>
            <path d="M1447 1423 c-17 -16 -7 -34 13 -23 11 6 20 15 20 20 0 11 -23 13 -33
3z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#1d2c6c" stroke="none">
            <path d="M3030 3029 c0 -3 12 -34 27 -70 51 -122 40 -330 -26 -471 -17 -36
-31 -71 -31 -77 0 -6 -4 -11 -10 -11 -5 0 -16 -15 -25 -34 -40 -85 -199 -221
-333 -286 -63 -31 -65 -35 -142 -485 -17 -93 -37 -201 -45 -238 -18 -79 -55
-291 -55 -314 0 -13 3 -13 15 -3 8 7 15 9 15 5 0 -5 9 0 20 10 11 10 20 14 20
10 0 -5 13 4 30 20 16 15 27 31 25 36 -7 11 6 40 15 34 10 -6 24 31 16 44 -3
5 3 48 15 97 43 192 69 320 69 352 0 18 4 31 9 28 4 -3 6 6 3 21 -3 18 -1 24
6 19 8 -5 10 2 5 25 -4 21 -2 30 4 26 6 -4 8 4 5 20 -3 18 -1 24 7 19 9 -6 10
0 5 20 -5 21 -4 25 6 19 10 -6 11 -2 6 19 -17 68 13 145 66 172 27 14 52 22
57 20 5 -3 17 4 27 17 19 22 19 22 -2 7 -11 -8 -7 -3 9 12 16 14 35 24 42 22
14 -6 29 13 30 36 0 8 8 15 19 15 10 0 26 11 35 25 11 17 12 22 3 16 -11 -7
-12 -4 -3 12 6 12 16 24 23 28 7 4 8 3 4 -4 -5 -8 -1 -10 9 -6 9 4 14 13 11
21 -3 8 -2 12 2 9 5 -3 16 11 26 30 9 20 11 32 5 29 -6 -4 -3 7 5 24 9 17 19
31 22 31 3 0 2 -5 -2 -12 -5 -7 -3 -8 5 -3 7 4 11 16 8 26 -3 11 -2 17 2 14 5
-2 8 8 8 23 0 15 8 62 17 105 20 100 20 196 0 289 -10 40 -17 83 -18 96 -1 12
-5 22 -9 22 -5 0 -5 5 -2 10 3 6 2 10 -4 10 -6 0 -19 17 -30 38 -11 20 -20 34
-21 31z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#c3ab80" stroke="none">
            <path d="M4165 2891 c-6 -5 -122 -67 -260 -136 -285 -145 -342 -178 -350 -207
-4 -11 -9 -17 -12 -14 -3 3 -40 -40 -82 -96 -42 -56 -94 -118 -116 -137 -22
-20 -65 -60 -96 -91 -43 -43 -61 -54 -82 -52 -18 2 -29 -3 -37 -17 -6 -12 -17
-21 -26 -21 -18 0 -34 -39 -31 -76 2 -31 -20 -44 -30 -17 -15 39 -70 60 -60
23 5 -18 0 -19 -54 -17 -48 2 -94 27 -49 27 6 0 10 6 10 13 1 10 41 59 105
128 16 17 20 39 8 38 -5 0 -25 -21 -46 -45 -20 -25 -63 -67 -95 -94 -52 -44
-59 -55 -80 -123 -27 -87 -38 -106 -71 -112 -30 -6 -25 6 -51 -115 -23 -104
-23 -104 1 -120 54 -35 79 -63 95 -108 33 -92 76 -173 101 -190 14 -9 36 -28
49 -41 13 -13 27 -22 30 -19 2 3 -9 17 -26 32 -17 14 -29 26 -25 26 4 0 -7 19
-24 42 -37 50 -37 52 -7 66 29 13 85 94 93 133 7 38 5 38 69 -28 55 -58 55
-58 24 -8 -74 119 -70 252 10 375 104 159 193 265 271 322 73 54 205 198 157
173 -7 -4 -1 3 13 16 15 13 46 50 69 82 23 32 56 67 73 77 18 10 39 25 47 33
24 22 44 32 35 17 -10 -16 95 40 113 60 8 9 11 10 7 2 -5 -9 8 -6 34 7 23 12
41 26 41 32 0 6 5 7 10 4 6 -3 10 -1 10 5 0 6 5 8 10 5 6 -3 10 -1 10 5 0 6 5
8 10 5 6 -3 10 -1 10 5 0 6 4 9 9 5 10 -5 83 33 89 47 2 5 8 6 13 2 5 -3 9 0
9 7 0 7 3 10 6 6 9 -8 64 21 64 34 0 6 4 8 8 5 4 -2 9 4 9 15 2 22 1 23 -12
10z"/>
            <path d="M3246 2553 c-44 -51 -114 -105 -126 -98 -9 5 -23 -25 -16 -32 16 -16
166 105 166 133 0 7 5 14 12 16 6 2 9 9 7 15 -2 6 -22 -9 -43 -34z"/>
            <path d="M3062 2345 c-16 -26 -18 -35 -8 -35 14 0 43 57 34 66 -3 3 -15 -11
-26 -31z"/>
            <path d="M1685 1439 c-4 -6 -13 -8 -21 -5 -8 3 -13 2 -11 -3 1 -5 -42 -31 -95
-60 -54 -28 -98 -48 -98 -43 0 4 -4 2 -8 -3 -10 -15 -132 -78 -132 -68 0 4 -4
3 -8 -3 -9 -15 -132 -76 -132 -67 0 5 -4 3 -8 -3 -13 -19 -101 -66 -112 -59
-5 3 -10 1 -10 -5 0 -6 -12 -15 -26 -20 -25 -10 -26 -30 -1 -26 30 5 57 18 57
27 0 6 5 7 10 4 6 -3 10 -1 10 5 0 6 5 8 10 5 6 -3 10 -1 10 4 0 6 3 10 8 9
14 -5 72 24 66 33 -3 5 1 6 9 3 8 -3 22 2 31 11 9 9 16 14 16 11 0 -4 23 5 50
19 28 14 50 30 50 36 0 5 4 8 9 4 5 -3 16 1 24 9 8 8 17 12 21 9 3 -4 6 -1 6
6 0 7 4 10 9 7 11 -7 71 25 71 37 0 5 5 5 10 2 6 -3 10 -1 10 6 0 7 3 9 7 6 3
-4 12 1 20 11 8 9 12 11 8 5 -4 -9 -1 -11 9 -7 9 3 13 10 10 15 -3 5 1 6 9 4
7 -3 29 5 49 19 20 13 38 22 41 20 2 -3 10 2 17 11 7 8 9 15 5 15 -4 0 -2 7 5
15 7 8 10 15 7 15 -3 0 -9 -5 -12 -11z"/>
            <path d="M1390 1361 c0 -5 -11 -12 -25 -15 -14 -4 -25 -11 -25 -16 0 -15 9
-12 46 11 37 23 41 29 19 29 -8 0 -15 -4 -15 -9z"/>
            <path d="M3353 1265 c0 -8 4 -12 8 -9 12 7 32 -35 25 -52 -4 -10 -2 -12 6 -8
8 5 9 2 5 -10 -4 -9 -3 -15 2 -11 5 3 11 1 13 -4 7 -19 46 -22 52 -4 4 10 4
14 0 10 -8 -7 -40 33 -36 46 1 4 -1 7 -5 7 -5 0 -21 11 -37 25 -34 29 -33 29
-33 10z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#58937b" stroke="none">
            <path d="M3269 3020 c-4 -52 17 -101 35 -82 11 10 6 22 -9 22 -8 0 -12 6 -10
13 3 6 1 32 -3 57 -9 45 -9 45 -13 -10z"/>
            <path d="M3635 2591 c-32 -19 -98 -93 -130 -146 -28 -45 -6 -22 50 53 28 37
68 76 90 89 22 12 36 23 30 23 -5 0 -24 -8 -40 -19z"/>
            <path d="M3445 2365 c-21 -25 -42 -42 -48 -38 -5 3 -7 2 -4 -4 8 -13 -38 -57
-51 -49 -9 5 -37 -20 -37 -33 0 -3 -6 -6 -13 -6 -15 0 -104 -86 -97 -93 4 -5
-32 -59 -93 -139 -13 -18 -22 -33 -18 -33 3 0 37 42 75 93 70 93 97 122 162
169 56 41 115 99 143 141 34 50 25 47 -19 -8z"/>
            <path d="M3507 2343 c-9 -2 -24 -20 -32 -39 -8 -19 -19 -34 -25 -34 -6 0 -8
-4 -5 -10 3 -5 1 -10 -4 -10 -6 0 -11 -9 -11 -19 0 -11 -5 -23 -12 -27 -7 -4
-8 -3 -4 4 4 7 5 12 2 12 -3 0 -15 -17 -26 -37 -30 -55 -87 -137 -135 -196 -8
-9 -15 -24 -15 -32 0 -8 -4 -15 -8 -15 -5 0 -19 -20 -32 -45 -12 -24 -30 -46
-39 -48 -14 -4 -14 -5 1 -6 21 -1 73 49 127 123 79 107 121 120 181 57 34 -35
37 -37 28 -13 -9 23 -5 38 26 98 20 39 36 76 36 82 0 14 33 75 45 84 6 4 10
11 10 15 2 32 28 52 73 56 26 2 0 4 -58 4 -58 0 -113 -1 -123 -4z"/>
            <path d="M3054 1933 c-31 -43 -53 -87 -26 -54 23 28 55 81 49 81 -2 0 -12 -12
-23 -27z"/>
            <path d="M2981 1720 c0 -41 4 -84 8 -95 4 -11 6 18 4 65 -6 110 -11 125 -12
30z"/>
            <path d="M3140 1438 c0 -10 5 -19 10 -19 6 -1 16 -2 23 -3 6 0 12 -7 12 -14 0
-15 26 -33 37 -26 5 3 -4 15 -19 27 -15 12 -29 20 -30 17 -1 -3 -9 4 -17 15
-14 18 -15 18 -16 3z"/>
            <path d="M2539 1185 c-1 -8 -2 -21 -3 -27 -1 -7 -3 -15 -6 -18 -3 -3 -21 -23
-40 -45 -35 -40 -35 -40 7 -1 43 40 43 40 58 3 8 -20 20 -37 25 -37 5 0 15
-19 21 -42 7 -24 16 -47 21 -53 4 -5 8 -16 8 -25 0 -65 84 -107 160 -79 3 1
10 0 15 -1 6 -1 7 1 3 5 -8 8 52 61 65 57 5 -1 6 2 2 8 -3 5 15 30 41 54 46
44 46 44 0 91 -80 79 -372 165 -377 110z"/>
            <path d="M1178 1154 c-27 -13 -48 -27 -48 -29 0 -5 99 42 109 51 13 11 -20 0
-61 -22z"/>
            <path d="M3485 1036 c0 -24 4 -43 10 -43 11 0 8 74 -4 81 -3 2 -6 -15 -6 -38z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#fce7b0" stroke="none">
            <path d="M3788 2984 c-27 -13 -48 -26 -48 -29 0 -6 80 31 93 44 16 16 8 13
-45 -15z"/>
            <path d="M3439 2802 c-18 -16 -28 -31 -23 -34 10 -7 58 32 62 50 3 18 -5 15
-39 -16z"/>
            <path d="M1146 2041 c-3 -5 -15 -7 -26 -4 -12 3 -20 0 -20 -6 0 -7 -4 -10 -9
-6 -5 3 -13 0 -17 -6 -5 -9 0 -10 17 -5 13 4 52 10 86 13 34 3 60 10 57 14 -7
12 -81 11 -88 0z"/>
            <path d="M1276 2032 c-5 -9 42 -32 67 -32 18 0 -6 18 -28 22 -11 1 -23 7 -26
12 -4 4 -9 4 -13 -2z"/>
            <path d="M1875 1830 c-27 -17 -46 -30 -42 -30 13 0 85 39 92 50 8 14 7 13 -50
-20z"/>
            <path d="M530 1784 c0 -33 26 -75 44 -70 13 3 30 -3 45 -17 22 -20 23 -20 13
-2 -15 30 -102 106 -102 89z"/>
            <path d="M3136 1432 c-4 -7 -4 -17 0 -24 4 -6 3 -8 -3 -5 -11 7 -31 -25 -37
-58 -5 -24 -32 -84 -48 -106 -9 -14 -4 -24 33 -61 76 -78 97 -177 54 -258 -11
-19 -19 -37 -20 -40 0 -3 -15 -21 -33 -40 -17 -19 -32 -39 -32 -44 0 -5 -10
-19 -22 -31 -28 -26 -58 -63 -58 -70 0 -3 8 -1 18 4 9 5 75 37 146 70 182 86
289 158 281 189 -4 15 -2 21 5 17 7 -4 10 17 9 64 -1 46 -5 68 -12 64 -7 -4
-8 5 -4 26 4 18 3 30 -1 28 -4 -2 -21 24 -37 59 -17 34 -26 60 -20 57 5 -4 -1
6 -14 20 -14 15 -30 27 -36 27 -6 0 -21 9 -33 20 -12 12 -22 17 -22 13 0 -4
-11 5 -25 20 -14 15 -31 27 -36 27 -6 0 -19 10 -28 22 -12 15 -20 18 -25 10z"/>
            <path d="M970 1367 c0 -7 -32 -29 -71 -47 -39 -19 -67 -36 -62 -37 5 -2 44 15
86 37 43 22 73 40 67 40 -6 0 -8 5 -5 10 3 6 1 10 -4 10 -6 0 -11 -6 -11 -13z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#fd8f7f" stroke="none">
            <path d="M1100 2026 c-106 -31 -186 -95 -232 -187 -64 -129 -48 -155 104 -171
180 -20 249 13 328 154 72 130 93 156 117 138 14 -11 13 -9 -2 8 -48 52 -225
85 -315 58z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#cea264" stroke="none">
            <path d="M3268 2572 c-20 -34 -82 -96 -93 -93 -4 2 -11 -1 -14 -6 -3 -5 -21
-19 -38 -32 -18 -12 -36 -34 -39 -49 -6 -27 -76 -153 -101 -182 -7 -8 -36 -41
-65 -74 -51 -56 -65 -81 -67 -118 -1 -15 2 -15 29 -2 20 10 35 12 47 6 10 -5
34 -8 53 -5 27 3 31 2 20 -6 -11 -8 -11 -11 -2 -11 7 0 16 10 21 22 8 22 8 22
13 0 7 -25 39 -29 57 -8 8 10 9 21 3 33 -12 23 2 63 22 63 9 0 20 9 26 21 8
15 18 19 34 15 17 -4 37 9 94 64 40 39 69 70 64 70 -4 0 -7 51 -5 113 6 178
-17 250 -59 179z"/>
            <path d="M1544 1748 c3 -13 6 -31 6 -41 0 -10 5 -15 10 -12 13 8 13 45 0 45
-5 0 -7 7 -4 15 4 8 1 15 -6 15 -8 0 -10 -8 -6 -22z"/>
            <path d="M1545 1594 c-8 -46 -39 -111 -68 -142 -13 -14 -17 -26 -11 -32 6 -6
18 5 34 28 14 20 30 42 36 49 18 19 38 113 26 125 -6 6 -12 -3 -17 -28z"/>
            <path d="M1371 1359 c-25 -16 -60 -32 -78 -35 -18 -4 -33 -12 -33 -20 0 -16
11 -18 26 -6 5 5 29 16 54 25 49 18 115 67 90 66 -8 0 -35 -14 -59 -30z"/>
            <path d="M1150 1300 c0 -5 23 -10 50 -10 28 0 50 5 50 10 0 6 -22 10 -50 10
-27 0 -50 -4 -50 -10z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#ebbb72" stroke="none">
            <path d="M3429 2773 c-71 -76 -190 -232 -151 -199 12 11 23 17 23 15 15 -51
18 -265 5 -294 -42 -92 48 -22 160 126 84 112 86 120 58 263 -26 131 -41 146
-95 89z"/>
            <path d="M2953 1623 c-6 -16 -14 -39 -18 -51 -12 -41 -65 -112 -96 -127 -16
-9 -29 -18 -29 -21 0 -9 63 -94 69 -94 7 0 31 73 31 95 0 8 4 15 9 15 5 0 15
17 22 38 16 48 49 93 63 85 6 -4 3 3 -7 14 -10 12 -21 33 -25 47 -7 26 -7 26
-19 -1z"/>
            <path d="M1531 1533 c-8 -18 -9 -29 -1 -37 10 -10 11 -8 14 27 1 6 5 15 10 18
4 4 4 9 -1 12 -6 4 -15 -5 -22 -20z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#dd4e1c" stroke="none">
            <path d="M1516 1820 c71 -336 -345 -581 -598 -352 -41 37 -41 37 -25 7 182
-349 757 -120 661 263 -19 74 -51 144 -38 82z"/>
        </g>
        <g transform="translate(0.000000,447.000000) scale(0.100000,-0.100000)" fill="#b5beb8" stroke="none">
            <path d="M3541 3215 c0 -82 3 -156 7 -163 12 -17 14 190 3 258 -7 45 -9 27
-10 -95z"/>
            <path d="M3263 3180 c0 -36 2 -50 4 -32 2 17 2 47 0 65 -2 17 -4 3 -4 -33z"/>
            <path d="M3542 2992 c-2 -34 -7 -44 -24 -52 -16 -6 -18 -9 -7 -9 26 -1 41 25
36 66 -4 38 -4 38 -5 -5z"/>
            <path d="M3378 2923 c23 -2 59 -2 80 0 20 2 1 4 -43 4 -44 0 -61 -2 -37 -4z"/>
            <path d="M3142 2670 c0 -82 9 -112 25 -76 8 17 9 26 2 26 -6 0 -7 5 -4 10 4 6
2 16 -3 23 -6 7 -12 26 -15 42 -2 17 -4 6 -5 -25z"/>
            <path d="M2763 2028 c-31 -17 -53 -36 -53 -46 0 -9 -3 -27 -6 -39 -6 -20 -4
-21 30 -13 20 4 36 6 36 3 0 -14 -32 -63 -42 -63 -6 0 -8 -3 -5 -7 13 -12 45
29 62 80 16 46 35 119 31 116 0 -1 -25 -15 -53 -31z"/>
            <path d="M1816 1553 c-25 -7 -99 -53 -94 -59 5 -4 118 55 118 62 0 2 -1 3 -2
3 -2 -1 -12 -4 -22 -6z"/>
            <path d="M3410 1158 c0 -10 3 -17 8 -15 8 5 6 -100 -2 -171 -4 -33 -3 -41 7
-35 6 5 29 19 50 32 20 12 37 25 37 28 0 2 -4 1 -10 -2 -5 -3 -10 0 -11 7 0 7
-4 4 -9 -7 -8 -19 -9 -19 -9 3 -1 12 -5 22 -10 22 -17 0 -31 82 -16 96 15 15
25 8 25 -17 0 -10 4 -20 9 -24 12 -7 -7 65 -23 85 -17 22 -46 21 -46 -2z"/>
        </g>
    </svg>

}