export class CanvasPlugin {

    initPlugin = false;


    constructor() {
        if (new.target === CanvasPlugin) {
            throw new TypeError("Cannot construct PluginInterface instances directly");
        }
    }


    /**
     * Called when the canvas is mounted,
     * passes a reference to the canvas for use inside the Plugin
     * @param canvas
     */
    init(canvas) {
        this.initPlugin = true;
        this.canvas = canvas;
    }


    /**
     * Called when the canvas is Unmounted
     */
    destroy() {
        // this.canvas = null;
    }


    /**
     * Called when the canvas is redrawn
     */
    onRedraw() {
        // to be implemented by the plugin
    }


    /**
     * can be used to render a custom plugin UI into the canvas
     * return either a React Component or null
     * @returns {null || React.Component}
     */
    renderPlugin() {
        return null;
    }


    /**
     * Sets the full data object
     * @param data
     */
    setData(data) {
        this.data = data;
    }


    /**
     * Sets the currently active element
     * @param active
     */
    setActive(active) {
        this.active = active;
    }


    /**
     * Called when the fist image is loaded and the canvas is shown
     */
    onCanvasLoaded() {
        // to be implemented by the plugin
    }


    /**
     * Called when all images are loaded
     */
    onCanvasFullLoaded() {

    }


    /**
     * Propagates the loading progress of the images rendered to the canvas
     * @param progress
     */
    onImagesPreparing(progress) {

    }
}