export const request = async (url = '', data = {}, method = 'GET') => {
    method = method.toUpperCase();
    const opt = {
        method     : method,
        cache      : 'no-cache',
        mode       : 'cors',
        credentials: 'same-origin',
        referrerPolicy: 'no-referrer',
    };
    if (method !== 'GET') {
        opt.body = data;
    } else {
        opt.headers = {'Content-Type': 'application/json'};
        const base = !/^https?:\/\//.test(url) ? window.document.baseURI : undefined;
        url = new URL(url, base);
        url.search = new URLSearchParams(data).toString();
    }
    const response = await fetch(url, opt);
    return response.json();
};


export const replaceMarkedStringWith = (str = '', replacements = [], callback = () => null, pattern = '##@...##') => {
    pattern = pattern.split('...');
    let idx = -1;
    return str.split(pattern[1]).map(v => {
        if (( new RegExp('^' + pattern[0].replace(pattern[1], '')) ).test(v)) {
            if (idx < replacements.length) {
                idx++;
            }
            return callback(idx, v.substring(1));
        } else {
            return v;
        }
    });
};



/**
 * export logger class
 */
export class Log {

    static enable = true;
    static logs = [];
    tag = 'unset';
    instanceEnabled = true;


    constructor(tag, enable = true) {
        window.BeyLog = Log;
        this.tag = tag;
        this.instanceEnabled = enable;
    }


    e = (mName, message) => {
        this.log('error', mName, message);
    }

    i = (mName, message) => {
        this.log('info', mName, message);
    }

    w = (mName, message) => {
        this.log('warn', mName, message);
    }

    static getLogs = () => {
        Log.logs.forEach(log => {
            if (typeof log.message === 'object') {
                log.message = JSON.stringify(log.message, null, 2);
            }
            if (log.level === 'error') {
                console.error(`[${log.timestamp}] [${log.level}] [${log.clName} -> ${log.mName}] ${log.message}`)
            } else if (log.level === 'warn') {
                console.warn(`[${log.timestamp}] [${log.level} -> ${log.clName}] [${log.mName}] ${log.message}`)
            } else {
                console.log(`[${log.timestamp}] [${log.level}] [${log.clName} -> ${log.mName}] ${log.message}`)
            }
        })
    }


    log(level, mName, message) {
        if (this.instanceEnabled && Log.enable) {
            const timestamp = new Date().toISOString();
            const clName = this.tag;
            if (typeof message === 'object') {
                message = JSON.stringify(message, null, 2);
            }
            Log.logs.push({timestamp, level, clName, mName, message});
            if (level === 'error') {
                console.error(`[${timestamp}] [${level}] [${clName} -> ${mName}] ${message}`)
            } else if (level === 'warn') {
                console.warn(`[${timestamp}] [${level} -> ${clName}] [${mName}] ${message}`)
            } else {
                console.log(`[${timestamp}] [${level}] [${clName} -> ${mName}] ${message}`)
            }
        }
    }


    getLogs = (test, test2) => {
        return this.logs;
    }
}


export const convertRgbToRgba = (rgbColorString, alpha) => {
    let rgbValues = rgbColorString.match(/\d+/g); // Extract the numbers from the RGB string
    return `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${alpha})`;
}