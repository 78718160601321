import {CanvasPlugin} from "../CanvasPlugin/CanvasPlugin";
import {BeyonityUiUtils, RotateButton} from "@beyonityeu/beyonity-ui-buttons";
import {Log} from "../../../../utils";
import "./CanvasRotate.css"




class CanvasRotate extends CanvasPlugin {

    pluginId = 'CanvasRotate';

    rto;
    raf;

    onRotate = () => {
    };


    constructor(onRotate) {
        super();
        this.l = new Log("CanvasRotate", false);
        this.fps = 20;
        this.range = [];
        this.maxRange = 0;
        this.index = 0;
        this.rotateToIndex = 0;
        this.gestureRotationInitial = 0;
        this.gestureRotationStart = 0;
        this.gestureRotation = 0;
        if (onRotate) this.onRotate = onRotate;
    }


    init = (canvas) => {
        super.init(canvas);
        this.rto = window.setTimeout(() => {
            canvas.eventManager.on('panstart panleft panright', this.processPan);
            canvas.eventManager.on('rotatestart rotatemove rotateend rotatecancel', this.processRotate);
        }, 0);
        this.progrees = 0;
        this.range = Object.keys(this.data).sort();
        this.maxRange = this.range.length - 1;
        this.index = BeyonityUiUtils.getKeyByValue(this.range, this.active);
        this.onRotate(this.index * ( -360 / ( this.maxRange + 1 ) ), this.index);
    }


    destroy = () => {
        window.clearTimeout(this.rto);
        this.canvas.eventManager.off('panstart panleft panright', this.processPan);
        this.canvas.eventManager.off('rotatestart rotatemove rotateend rotatecancel', this.processRotate);
    }


    renderPlugin(pluginState) {
        super.renderPlugin()
        const progress = pluginState?.progress ? pluginState.progress : 0;
        return (
            <div key={this.pluginId}
                 className={`beyonity-ui--canvas-plugin beyonity-ui--canvas-rotate ${progress < 100 && `beyonity-ui--canvas-rotate__loading`}`}>
                <div className={"wrapper"}>
                    <RotateButton
                        className={progress < 100 ? `beyonity-ui--canvas-rotate__loading-rb` : `beyonity-ui--canvas-rotate__loading`}
                        onRotateLeft={() => {
                            this.cancel();
                            this.rotateRight();
                        }}
                        onRotateRight={() => {
                            this.cancel();
                            this.rotateLeft();
                        }}
                        onCompassClick={() => {
                            this._rotateToIndex(0, () => {
                            })
                        }}
                        compass={true}
                        compassRotation={this.index * ( -360 / ( this.maxRange + 1 ) )}
                        onRotateEnd={() => {
                            this.cancel();
                        }}
                    />
                    {progress < 100 &&
                        <div className={`beyonity-ui--canvas-rotate__loading-progress color-text--gray-0`}>
                            {progress}%
                        </div>
                    } </div>
            </div>
        );
    }




    setData = (data) => {
        super.setData(data);
    }

    setActive = (active) => {
        super.setActive(active);
        this.l.i("setActive", `active ${active} index`)
    }


    onImagesPreparing(progress) {
        this.progrees = progress;
        this.canvas && this.canvas.setPluginState(this.pluginId, {progress: progress})
    }



    //
    // --------- PLUGIN LOGIC -----------
    //

    rotateToFacade = (facade, callback) => {
        let rotateToIndex = BeyonityUiUtils.getKeyByValue(this.range, facade);
        this._rotateToIndex(rotateToIndex, callback)
    }


    _rotateToIndex(index, callback) {
        if (this.progrees < 100) return;
        if (index === this.index) return;

        let distanceRight = Math.abs(index - this.index);
        let distanceLeft = this.maxRange - distanceRight;
        if (index < this.index) {
            distanceLeft = Math.abs(index - this.index);
            distanceRight = this.maxRange - distanceLeft;
        }

        if (distanceLeft <= distanceRight) {
            this.rotateLeftTowards(index, callback);
        } else {
            this.rotateRightTowards(index, callback);
        }
    }


    rotateLeftTowards = (index, callback) => {
        if (this.progrees < 100) return;
        if (index == this.index) {
            this.cancel();
            return;
        }
        this.cancel();
        this.left();
        this.rotate(() => this.rotateLeftTowards(index, callback));
    }

    rotateRightTowards = (index, callback) => {
        if (this.progrees < 100) return;
        if (index == this.index) {
            this.cancel();
            return;
        }
        this.cancel();
        this.right();
        this.rotate(() => this.rotateRightTowards(index, callback));
    }


    cancelAnimationFrame = (id) => {
        window.clearTimeout(id)
    };

    requestAnimationFrame = (f) => {
        return window.setTimeout(f, 1000 / this.fps)
    };


    rotateRight = () => {
        this.cancel();
        this.right();
        this.rotate(this.rotateRight);
    };

    rotateLeft = () => {
        this.cancel();
        this.left();
        this.rotate(this.rotateLeft);
    };

    right = () => {
        this.index++;
        if (this.index > this.maxRange) {
            this.index = 0;
        }
        this.draw();
    };

    left = () => {
        this.index--;
        if (this.index < 0) {
            this.index = this.maxRange;
        }
        this.draw();
    };

    rotate = (cb) => {
        this.raf = this.requestAnimationFrame(cb);
    };

    draw = () => {
        const {canvas, data, onRotate} = this,
            index = this.range[this.index];
        canvas.setActive(index);
        setTimeout(() => {
            canvas.setPluginState(this.pluginId, {active: index})
            canvas.img = data[this.active].img;
            canvas.redraw();
        }, 0);
        onRotate(this.index * ( -360 / ( this.maxRange + 1 ) ), this.index);
    };

    cancel = () => {
        this.cancelAnimationFrame(this.raf);
        this.raf = undefined;
    };

    processPan = (e) => {
        const {canvas} = this,
            can = canvas.canvasRef.current;
        let step, _step,
            i = 0;
        if (e.srcEvent.target !== can
            || e.pointerType === 'touch'
            || !e.srcEvent.ctrlKey
            || this.progrees < 100
        ) {
            return false;
        }
        step = Math.floor(( e.deltaX / can.width ) * ( this.maxRange + 1 ));
        _step = Math.abs(step - this.last);
        this.last = step;
        switch (e.type) {
            case 'panstart':
                this.last = 0;
                break;
            case 'panleft':
                for (; i < _step; i += 1) {
                    this.left();
                }
                break;
            case 'panright':
                for (; i < _step; i += 1) {
                    this.right();
                }
                break;
            default:
                console.info(e.type);
        }
    };

    gestureRotate = delta => {
        if (delta < 0) {
            this.right();
        } else if (delta > 0) {
            this.left();
        }
    };

    processRotate = (e) => {
        if (this.progrees < 100) return;
        let rotation;
        switch (e.type) {
            case 'rotatestart':
                this.gestureRotationStart = Math.floor(e.rotation);
                this.gestureRotationInitial = Math.floor(e.rotation);
                break;
            case 'rotatemove':
                rotation = Math.floor(e.rotation);
                if (rotation !== this.gestureRotationInitial && rotation !== this.gestureRotation && !( ( rotation - this.gestureRotationStart ) % ( 360 / ( this.maxRange + 1 ) ) )) {
                    this.gestureRotate(( rotation > this.gestureRotation ) ? 1 : -1);
                    this.gestureRotation = rotation;
                    this.gestureRotationInitial = 0;
                }
                break;
            case 'rotateend':
            case 'rotatecancel':
                break;
            default:
                console.info(e.type);
        }
    };

}

export default CanvasRotate;



