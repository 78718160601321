import React, {Component} from 'react';

import styles from './index.module.scss';




class SidebarFooter extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        const {children, className, renderTools} = this.props;

        return (
            <div className={`${styles.footer} ${className}`}>
                {children}
                <div className={styles.toolbar}>
                    {renderTools()}
                </div>
            </div>
        );
    }

}

export default SidebarFooter;
