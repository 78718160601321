import {BeyonityUiUtils} from "@beyonityeu/beyonity-ui-buttons";
import {CanvasPlugin} from "../CanvasPlugin/CanvasPlugin";
import {convertRgbToRgba, Log} from "../../../../utils";
import "./CanvasSvg.css"
import {object} from "prop-types";




class CanvasSVG extends CanvasPlugin {

    pluginId = 'CanvasSVG';

    /**
     * This contains the markers that are to be rendered on the canvas.
     * The markers are objects with the following properties:
     * id, paths, and name.
     * Furthermore, they're required to provide an
     * `onClick`, `onMouseEnter` and `onMouseLeave` callback.
     * The 'paths' property is an object with keys that correspond to the key of the active image i the canvas
     * @type {{}}
     */
    markers = {};
    /**
     * This contains the currently highlighted marker (when selected). This is false if no marker is selected.
     * This will be changed from "the outside" with `setClickedMarker(marker)`
     * @type {boolean || object}
     */
    highlightedMarker = false;
    /**
     * This contains the currently hovered marker (when hovering its svg on the canvas)
     * If nothing is hovered, it is set to false.
     * @type {boolean || object}
     */
    hoveredMarker = false;
    /**
     * Decides whether all markers should be shown or just
     * the ones that are selected or hovered.
     * can be set using the `highlightAllMarkers` method.
     * @type {boolean}
     */
    highlightAllMarkers = false;

    l = new Log("CanvasSVG", false);

    /**
     * Optional canvas Rotate reference for enabling rotation on clicks
     * @type {boolean || CanvasRotate}
     */
    canvasRotate = false;

    pathStyleProvider = () => {
        return false;
    };


    constructor(markers, canvasRotate, pathStyleProvider) {
        super();
        if (pathStyleProvider) this.pathStyleProvider = pathStyleProvider;
        this.markers = markers;
        this.canvasRotate = canvasRotate;
        this.onRedraw = this.onRedraw.bind(this);
    }


    //
    // --------- CANVAS PLUGIN -----------
    //



    init = (canvas) => {
        super.init(canvas);
        this.l.i("init", this.markers)
        this.prepareSVGs(this.markers)
        this.rto = window.setTimeout(() => {
            let canvas = this.canvas.canvasRef.current;
            canvas.addEventListener('mousemove', this.hover, false);
            canvas.addEventListener('mouseleave', this.removeHover, false);
            canvas.addEventListener('touchstart', this.hover, {passive: false});
            canvas.addEventListener('touchend', this.touchend, {passive: false});
            this.canvas.eventManager.on('tap', this.tab);
        }, 0);
        this.canvas.redraw();

    }


    setData(data) {
        super.setData(data);
    }


    setActive(active) {
        super.setActive(active);
    }


    destroy = () => {
        super.destroy()
        window.clearTimeout(this.rto);
        let canvas = this.canvas.canvasRef.current;
        -
        canvas.removeEventListener('mousemove', this.hover, false);
        canvas.removeEventListener('mouseleave', this.hover, false);
        canvas.removeEventListener('touchstart', this.hover, {passive: false});
        canvas.removeEventListener('touchend', this.touchend, {passive: false});
        this.canvas.eventManager.off('tap', this.tab);
    }

    /**
     * called when the canvas redraws
     */
    onRedraw = () => {
        super.onRedraw();
        if (!this.canvas) {
            this.l.w("onRedraw", "called before init")
            return;
        }
        this.drawPaths();
    }

    onImagesPreparing = (progress) => {

    }

    renderPath = (path, fillColor, strokeColor) => {
        let canvas = this.canvas;
        canvas.context = canvas.canvasRef.current.getContext('2d');
        canvas.context.save();
        this.transform();
        canvas.context.beginPath();
        try {
            if (!this.pathStyleProvider(path)) {
            canvas.context.fillStyle = fillColor;
            canvas.context.fill(path);
            canvas.context.strokeStyle = strokeColor;
            canvas.context.lineWidth = 20;
            canvas.context.stroke(path);
            }
        }
        catch (e) {
            console.error("Probably not a path2d object again ", e, "that's the path", path)
        }

        canvas.context.restore();
    };

    drawPaths = () => {
        if (!this.canvas) return;

        for (const marker in this.markers) {
            // get the active path by the kex
            const mMarker = this.markers[marker];
            const paths = mMarker.paths;
            const active = paths[this.active];

            if (this.highlightedMarker && this.highlightedMarker) {
                if (mMarker.id === this.highlightedMarker.id) {
                    this.renderPath(active.d,
                        convertRgbToRgba(mMarker.statusColor, 0.5),
                        convertRgbToRgba(mMarker.statusColor, 1));
                }

            } else {
                if (this.highlightAllMarkers && active && active.d) {
                this.renderPath(active.d,
                    convertRgbToRgba(mMarker.statusColor, 0.5),
                    convertRgbToRgba("255, 255, 255", 0.5));
            }
            if (mMarker.id === this.hoveredMarker.id) {
                this.renderPath(active.d,
                    'rgba(239,239,239,0.5)', 'rgba(239,239,239,0.5)');
            }
            if (this.highlightedMarker && this.highlightedMarker.id === mMarker.id) {
                this.renderPath(active.d,
                    convertRgbToRgba(mMarker.statusColor, 0.5),
                    convertRgbToRgba(mMarker.statusColor, 1));
            }
            }
        }
    }



    /**
     * This accumulates paths by their color to a single path.
     * This should boost performance when redrawing
     * @param ids
     * @param data
     */
    setPathsByColor = (ids, data) => {
        let hoverColor = "#000000";
        let pathsByColor = {};
        ids.forEach(id => {
            if (data.pathsRaw[id] !== undefined) {
                const color = hoverColor;
                if (pathsByColor[color] === undefined) {
                    pathsByColor[color] = '';
                }
                pathsByColor[color] += data.pathsRaw[id].d;
            }
        });
        for (const q in pathsByColor) {
            pathsByColor[q] = new Path2D(pathsByColor[q]);
        }
        data.pathsByColor = pathsByColor;
    };


    //
    // --------- INTERACTIONS -----------
    //


    hover = (e) => {
        let canvas = this.canvas;
        let path = this.isPointInPath(BeyonityUiUtils.getPointerCoordinates(e));
        if (path === this.hoveredMarker) return;

        if (!this.hoveredMarker && path) {
            this.hoveredMarker = path;
            path.onMouseEnter()
        } else if (this.hoveredMarker && path) {
            this.hoveredMarker.onMouseLeave()
            this.hoveredMarker = path
            this.hoveredMarker.onMouseEnter()
        } else if (this.hoveredMarker && !path) {
            this.hoveredMarker.onMouseLeave()
            this.hoveredMarker = false;
        }
        canvas.redraw();
    };

    click = (e) => {
        const coord = BeyonityUiUtils.getPointerCoordinates(e),
            marker = this.isPointInPath(coord);
        if (marker) {
            marker.onClick(marker, false);
        }
    };

    touchend = e => {
        e.preventDefault();
        this._removeHover();
        return false;
    };

    tab = (e) => this.click(e.srcEvent);


    _removeHover = () => {
        this.hoveredMarker && this.hoveredMarker.onMouseLeave()
        this.hoveredMarker = false;
        this.canvas.redraw();
    }

    //
    // --------- UTILS -----------
    //

    /**
     * Checks weather a position is within a SVG path on the canvas.
     * If so, returns the pathlse returns false.
     * @param pos
     * @returns {boolean}
     */
    isPointInPath = (pos) => {
        let canvas = this.canvas;
        this.canvas.context = this.canvas.canvasRef.current.getContext('2d');

        let ret = false;
        canvas.context.save();
        this.transform();
        for (const i in this.markers) {
            const path = this.markers[i].paths[this.active];

            if (path && path.d && canvas.context.isPointInPath(path.d, pos.x, pos.y)) {
                ret = this.markers[i];
                break;
            }
        }
        canvas.context.restore();
        return ret;
    };


    /**
     * Prepare SVGs of the markers for being rendered on canvas.
     * It alwso tries to calculate the size of each svg and stores the facade
     * in which each item has the biggest svg
     *
     * @param markers - object
     * @returns {void}
     */
    prepareSVGs = (markers) => {
        this.l.i("prepareSVGs", "Preparing marker SVGs")

        for (const marker in markers) {
            const mPaths = markers[marker].paths;
            for (const path in mPaths) {
                const mPath = mPaths[path];
                if (mPath) {
                    mPath.d = new Path2D(mPath.d);
                }
            }
        }
    };


    setMarkers = (markers) => {
        this.markers = markers;
        if (this.canvas) {
            this.prepareSVGs(this.markers)
            this.canvas.redraw();
        }
    }


    setClickedMarker = (marker, rotate = true) => {
        if (!marker) {
            this.highlightedMarker = false;
            this.canvas.redraw();
        } else if (this.canvas && marker) {
            let mMarker = this.markers.find(m => m.id === marker.id)
            if (mMarker) {
                this.highlightedMarker = mMarker;
                if (this.canvas) {
                    this.canvas.redraw();
                    if (rotate) this.canvasRotate && this.canvasRotate.rotateToFacade(mMarker.primaryFacade);
                }
            }
        }
    }

    setHoveredMarker = (marker) => {
        if (this.canvas && marker) {
            let mMarker = this.markers.find((m) => {
                return m.id === marker.id;
            });
            this.hoveredMarker = mMarker;
        }
        if (this.canvas) {
            this.canvas.redraw();
        }
    }



    setHighlightAllMarkers = (show) => {
        this.highlightAllMarkers = show;
        if (this.canvas) {
            this.canvas.redraw();
        }
    }

    /**
     * This responsible for adjusting the position and scale of the canvas context.
     * It uses the `translate` and `scale` values from the `canvas.svg` object to calculate the new position and scale.
     * The `translate` and `scale` values are adjusted by the `canvas.canFactor` and `canvas.zoom` values.
     * The new position and scale are then applied to the `canvas.context` using the `translate` and `scale` methods.
     *
     * @returns {void} This method does not return anything.
     */
    transform = () => {
        if (!this.initPlugin) return;
        const canvas = this.canvas;
        const face = this.data[this.active].svg

        const translate = face.translate.map((item) => {
            return +item * canvas.canFactor * ( 1 + canvas.zoom )
        });

        const scale = face.scale.map((item) => {
            return +item * canvas.canFactor * ( 1 + canvas.zoom )
        });

        canvas.context.translate(
            translate[0] + canvas.params.dx - ( ( canvas.maxOffset.x + canvas.offset.x ) * ( 1 + canvas.zoom ) ),
            translate[1] - ( ( canvas.maxOffset.y + canvas.offset.y ) * ( 1 + canvas.zoom ) ));

        canvas.context.scale(scale[0], scale[1]);
    };

}

export default CanvasSVG;
