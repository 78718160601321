import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Oval} from 'react-loader-spinner';
import {BeyonityUiUtils} from '@beyonityeu/beyonity-ui-buttons';
import CanvasBase from './CanvasBase/CanvasBase';

import styles from './index.module.scss';
import {Log} from "../../../utils";




class Canvas extends Component {

    rto = [];

    l = new Log("Canvas");


    constructor(props) {
        super(props);
        this.state = {
            isLoaded        : false,
            rotationIsLoaded: false
        };
        this.data = {};
    }


    componentDidMount() {
        const {active, data} = this.props;
        this.data = BeyonityUiUtils.copyObject(data);
        this.data = data;
        this.props.plugins && this.props.plugins.forEach(plugin => {
            plugin.setActive(active)
            plugin.setData(this.data)
        });
        //  this.prepareSVGs(this.data);
        this.prepareImages(this.data, active);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const {active, data} = this.props;

        if (prevProps.active !== active) {
            this.data = data;
            this.prepareImages(this.data, active);
            this.props.plugins && this.props.plugins.forEach(plugin => {
                plugin.setActive(this.props.active)
                plugin.setData(this.data)
            });

        }
    }


    componentWillUnmount() {
        this.rto.forEach(to => window.clearTimeout(to));
    }



    prepareImages = (data, active) => {
        const preloader1 = new this.imagePreloader(),
            preloader2 = new this.imagePreloader(),
            images = {};

        // loads the fist "active" image
        preloader1.load({[active]: data[active].img}, {
            complete: (images) => {
                data[active].img = images[active];
                this.rto.push(window.setTimeout(() => this.setState({
                    isLoaded: true
                }), 0));
            }
        });

        // preloads all the other, currently not shown images
        for (const p in data) {
            if (p === active) {
                continue;
            }
            images[p] = data[p].img;
        }

        preloader2.load(images, {
            progress: (progress) => {
                this.props.plugins && this.props.plugins.forEach(plugin => {
                    plugin.onImagesPreparing(progress)
                })
            },
            complete: (images) => {
                for (const p in images) {
                    data[p].img = images[p];
                }
                this.rto.push(window.setTimeout(() => this.setState({
                    rotationIsLoaded: true
                }), 0));
            }
        });
    };



    render() {
        const {isLoaded, rotationIsLoaded} = this.state,
            {active, plugins, onLoad} = this.props;


        return (
            <>
                {!isLoaded ?
                    <div className={styles.loader}>
                        <Oval
                            height="4vmin"
                            width="4vmin"
                            color="rgb(var(--ci-primary))"
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="rgba(var(--ci-primary), .5)"
                            strokeWidth={4}
                            strokeWidthSecondary={2}
                        />
                        Loading images...
                    </div>
                    :
                    <>
                        <CanvasBase
                            img={this.data[active].img}
                            rotation={rotationIsLoaded}
                            plugins={plugins}
                            onLoad={onLoad}
                        />
                    </>
                }
            </>
        );
    }


    imagePreloader = function () {

        const chunkSize = 6
        const mImages = {};

        let imgs = [],
            callback = function () {
            },
            count,
            counter;


        function preload(key, image) {
            const img = new Image();
            let rto;
            img.crossOrigin = 'Anonymous';
            img.addEventListener('load', function () {
                counter++;
                mImages[key] = this;
                if (!( counter % chunkSize )) {
                    rto = window.setTimeout(loadChunk, 1);
                }
                if (typeof callback.progress === 'function') {
                    let percentProgress = Math.round(( counter / count ) * 100);
                    callback.progress(percentProgress);
                }
                if (typeof callback.complete === 'function' && counter >= count) {
                    callback.complete(mImages);
                    window.clearTimeout(rto);
                }
            }, false);
            img.src = image;
        }


        function loadChunk() {
            const l = Math.min(counter + chunkSize, count);
            let i = counter;
            for (; i < l; i += 1) {
                preload(imgs[i][0], imgs[i][1]);
            }
        }


        this.load = (images, _cb) => {
            imgs = Object.entries(images);
            count = imgs.length;
            if (!count) {
                _cb.complete(images);
                return;
            }
            counter = 0;
            callback = _cb;
            loadChunk();
        };

    };


}

export default Canvas;


Canvas.defaultProps = {
    onLoad: () => {
    }
};

Canvas.propTypes = {
    active : PropTypes.string.isRequired,
    data   : PropTypes.object.isRequired,
    plugins: PropTypes.arrayOf(PropTypes.object),
    onLoad : PropTypes.func
};
